import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from '../../../store/actions/actionTypes';

const AdmissionList_SubFilter_DropdownItem = ({variable, title, customLogic=false, noTitle=false}) => {
    const subfilters = useSelector(state => state.admissionList.subfilters);
    const {selectedHospital} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    if (customLogic && !customLogic.includes(selectedHospital.hospitalConfig.customLogic)) {
        return null;
    }

    const dispatchSet = (status) => dispatch({type: actionTypes.SUBFILTER_SET, subfilter: variable, value: status});
    const enableFalse = () => dispatchSet(hasSubfilter(false) ? undefined : false);
    const enableTrue = () => dispatchSet(hasSubfilter(true) ? undefined : true);

    const hasSubfilter = (status) => (subfilters[variable] !== undefined) && (subfilters[variable] === status);
    const btnClassName = (status) => hasSubfilter(status) ? "btn-teal" : "btn-dark-gray";

    return (
        <ul className="table-buttons-holder dropdown flex-break">
            <li className="table-buttons-item"><a href="#" onClick={enableTrue} className={`btn btn-sm ${btnClassName(true)}`}>{title}</a></li>
            <li className="table-buttons-item"><a href="#" onClick={enableFalse} className={`btn btn-sm ${btnClassName(false)}`}>{noTitle ? noTitle : `no ${title}`}</a></li>
        </ul>
    )
}

export default AdmissionList_SubFilter_DropdownItem;