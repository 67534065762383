import React from "react";
import Admission_LastSms from "./Admission_LastSms";
import Admission_TargetPhone from "./Admission_TargetPhone";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";

const Admission_ModalInfo = ({admission, showMobileInfo = true}) => {
    return (
        <>
            <dl className="person-list mb-0">
                <UrDt admission={admission}/>
                <AdmDt admission={admission}/>
                <dt>Adm. date</dt>
                <dd>{admission.admissionDate}</dd>
                <dt>Adm. Time</dt>
                <dd>{admission.admissionTime}</dd>
            </dl>
            {showMobileInfo &&
            <dl className="person-list mb-3">
                <dd><Admission_TargetPhone admission={admission}/></dd>
                <dt>Last sms</dt>
                <dd><Admission_LastSms admission={admission}/></dd>
            </dl>
            }
        </>
    )
}

export default Admission_ModalInfo;