import React, {useState} from "react";
import {Form} from 'react-final-form';
import {getProperty} from "dot-prop";
import {useDispatch, useSelector} from "react-redux";
import {push} from "react-router-redux";

import RfaForm_Form from "./RfaForm_Form";
import FlashMessages from "../../FlashMessages/FlashMessages";

const RfaForm = ({initialValues = [], title, handleSubmitAction = '-'}) => {
    const dispatch = useDispatch();
    const hospitalPatient = useSelector(state => state.hospitalPatient.hospitalPatient);
    const [error, setError] = useState('');

    console.log('[RfaForm] render', initialValues)
    const onSubmit = (data) => {
        dispatch(handleSubmitAction(data, setError));
    }

    const validate = (data) => {
        // console.log('[RfaForm] validate()', data);
    }

    const renderError = () => {
        if (error) {
            return <div className="alert alert-danger mt-2">{error}</div>;
        }

        return null;
    }

    const goToHospitalPatient = () => {
        dispatch(push(`/hp/${hospitalPatient.id}`))
    }

    return (
        <main className="main">
            <div className="container">
                <FlashMessages/>
                <div className="col-3">
                    <div className="heading">
                        <h1 className="h2"><i className="icon ico-arrow-left pointer" onClick={goToHospitalPatient}/>{title}</h1>
                    </div>
                </div>

                <div className="row justify-content-center bg-white rounded shadow-sm border">
                    <div className="col-12">
                        {renderError()}

                        <Form onSubmit={onSubmit}
                              validate={validate}
                              initialValues={initialValues}
                              render={RfaForm_Form}
                              subscription={{ submitting: true, pristine: true }}
                              files={getProperty(initialValues, 'files', [])}
                        />
                    </div>
                </div>
            </div>
        </main>
);
}

export default RfaForm;