import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import AdmissionList_Item_Checkbox from "../../AdmissionsList/Item/AdmissionList_Item_Checkbox";
import AdmissionList_Item_Name from "../../CustomLogic/AdmissionList_Item_Name";
import AdmissionList_Item_MoreDrop from "../../AdmissionsList/Item/AdmissionList_Item_MoreDrop";
import {useCustomLogic} from "../../../utils/customLogic";
import AdvancedList_Item_CustomColumn from "../../AdvancedList/AdvancedList_Item_CustomColumn";
import * as constants from "../../../utils/constants";
import * as actionTypes from "../../../store/actions/actionTypes";

const DischargeList_Item = ({admission}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {keyword} = useParams();
    const auth = useSelector(state => state.auth);
    const {groupId} = useSelector(state => state.admissionList);
    const {isCustomLogicMaryvale, isCustomLogicHealtheCare, isCustomLogicLifehouse, isCustomLogicESPH, isCustomLogicDemo} = useCustomLogic();
    const reloadingProgress = useSelector(state => state.main.reloadingProgress);

    const goToAdmission = (event) => {
        console.log('[AdmissionList_Item] goToAdmission()')
        if (event.ctrlKey) {
            window.open(`${process.env.REACT_APP_AUTH0_REDIRECT}admission/${admission.id}`, '_blank');
        } else {
            navigate(`/admission/${admission.id}`);
        }
    }

    const renderTime = () => {
        return <>{admission.discharge_date} {admission.discharge_time}</>
    }

    const renderManagerCustomUI = () => {
        // TODO: ACA-295  - REFACTOR
        //       if (auth.selectedHospital.managerCustomUI.length) {
        //          to
        //       useManagerCustomUI() + if(hasAdmissionListColumnSend())
        //     something similar to utiles/subfilteredData.js useFilteredData()
        if (auth.selectedHospital.managerCustomUI_dischargeList.length) {
            return auth.selectedHospital.managerCustomUI_dischargeList.map((item, key) => <td key={key} onClick={goToAdmission} className="pointer"><AdvancedList_Item_CustomColumn admission={admission} managerCustomUi={item}/></td>);
        }
        return null;
    }

    const showCommentsModal = () => dispatch({type: actionTypes.MODAL_COMMENTS_SHOW, admission: admission, commentsType: constants.TYPE_DISCHARGE_COMMENTS});

    const renderManagerDischargeListComments = () => {
        if (admission.discharge_comments_count) {
            switch (admission.discharge_comments_status) {
                case constants.COMMENTS_STATUS.STATUS_PENDING:
                    return (
                        <button type="button" className="btn btn-sm btn-secondary lifehouse-button-orange" onClick={showCommentsModal}>{admission.discharge_comments_count}</button>
                    );
                case constants.COMMENTS_STATUS.STATUS_CLOSED:
                    return (
                        <button type="button" className="btn btn-sm btn-secondary lifehouse-button-green" onClick={showCommentsModal}>{admission.discharge_comments_count}</button>
                    )
            }
        }
        return <button type="button" className="btn btn-sm btn-secondary" onClick={showCommentsModal}>-</button>;
    }

    return (
        <tr>
            <td className="pointer"><AdmissionList_Item_Checkbox admission={admission}/></td>
            <td onClick={goToAdmission} style={{cursor: 'pointer', width: '50%'}}>
                <AdmissionList_Item_Name admission={admission}/>
            </td>
            {(isCustomLogicMaryvale() || isCustomLogicHealtheCare() || isCustomLogicESPH() || isCustomLogicDemo()) &&
                <td onClick={goToAdmission} className="pointer">{admission.admittingDoctor}</td>}
            <td onClick={goToAdmission} className="pointer">{renderTime()}</td>
            {renderManagerCustomUI()}
            {auth.selectedHospital.manager_dischargeList_commentsEnable &&
                <td>
                    <div className="d-inline-flex align-items-center">
                        {renderManagerDischargeListComments()}
                    </div>
                </td>
            }
            <td>
                <div className="d-inline-flex align-items-center">
                    <AdmissionList_Item_MoreDrop admission={admission}/>
                </div>
            </td>
        </tr>
    )
}


const areEqual = (prevProps, nextProps) => {
    // console.log('[Are Equal?]', JSON.stringify(prevProps) === JSON.stringify(nextProps));
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default DischargeList_Item;
export const DischargeList_Item_Memo = React.memo(DischargeList_Item, areEqual);