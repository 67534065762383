import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {push} from "react-router-redux";
import {format} from "date-fns";

const useAdmissionListNavigation = (items, config = null) => {
    const dispatch = useDispatch();
    const {date, keyword, sortColumn, sortMode, groupType, groupId, page, filter} = useSelector(state => state.admissionList);
    const {selectedHospital} = useSelector(state => state.auth);

    const pushAdmissionsListUrl = (newHospitalId = null, newDate = null, newKeyword = null, newSortColumn = null, newSortMode = null, newGroupType = null, newGroupId = null, newFilter = null, newPage = null) => {
        newHospitalId = newHospitalId !== null ? newHospitalId : selectedHospital.id;
        newDate = newDate !== null ? newDate : date;
        newKeyword = newKeyword !== null ? newKeyword : keyword;
        newSortColumn = newSortColumn !== null ? newSortColumn : sortColumn;
        newSortMode = newSortMode !== null ? newSortMode : sortMode;
        newGroupType = newGroupType !== null ? newGroupType : groupType;
        newGroupId = newGroupId !== null ? newGroupId : groupId;
        newFilter = newFilter !== null ? newFilter : filter;
        newPage = newPage !== null ? newPage : page;

        if (newGroupType && newGroupId) {
            dispatch(push(`/admissions/${newHospitalId}/g/${newGroupType}/${newGroupId}/${newSortColumn}/${newSortMode}/${newPage}/${newFilter}`))
        } else if (newKeyword) {
            dispatch(push(`/admissions/${newHospitalId}/${format(newDate, 'yyyy-MM-dd')}/${newSortColumn}/${newSortMode}/search/${newKeyword}`))
        } else {
            dispatch(push(`/admissions/${newHospitalId}/${format(newDate, 'yyyy-MM-dd')}/${newSortColumn}/${newSortMode}`))
        }
    }

    const isFilterPersisted = () => groupType !== null;
    return { pushAdmissionsListUrl, isFilterPersisted }
}

export default useAdmissionListNavigation;