import React from 'react';
import AdmissionList_SubFilter_Item from "./AdmissionList_SubFilter_Item";

const AdmissionList_SubFilter_Array = ({items, handle}) => {
    if (!items) {
        return null;
    }
    return (
        <ul className="table-buttons-holder dropdown flex-break">
            {items.map(item => <AdmissionList_SubFilter_Item label={item} value={item} handle={handle}/>)}
        </ul>
    )
}

export default AdmissionList_SubFilter_Array;