import React from 'react';
import {useSelector} from "react-redux";

import AdmissionList_Item_SelectDocumentSet from "./Item/AdmissionList_Item_SelectDocumentSet";
import AdmissionPayment from "./AdmissionPayment";
import {useDispatch} from "react-redux";
import {showDocumentStatusForAdmission} from "../../store/actions/modal";

const AdmissionProgress = ({admission}) => {
    const dispatch = useDispatch();

    let step = 0;
    let step1_msg = '';

    switch (admission.statusPPStr) {
        case "STATUS_PP_NOT_STARTED":
        default:
            step = 0;
            break;
        case "STATUS_PP_INVITE_PENDING":
        case "STATUS_PP_INVITE_IN_PROGRESS":
            step = 1;
            step1_msg = 'Pending SMS'
            break;
        case "STATUS_PP_INVITE_DONE":
        case "STATUS_PP_LOGIN_DONE":
            step = 2;
            step1_msg = admission.pp_invitedAt;
            break;
        case "STATUS_PP_SIGNATURE_DONE":
            step = 3;
            step1_msg = admission.pp_invitedAt;
            break;
        case "STATUS_PP_PAYMENT_DONE":
        case "STATUS_PP_DOCUMENTS_MERGING":
        case "STATUS_PP_DOCUMENTS_MERGED":
            step = 4;
            step1_msg = admission.pp_invitedAt;
            break;
    }

    const showDocumentStatus = event => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(showDocumentStatusForAdmission(admission));
    }

    const stepClass = targetStep => {
        if ((targetStep === 2) && admission.cutoff) {
            return null;
        }
        return `${step > targetStep ? ' status-done' :''}${step === targetStep ? ' status-pending' :''}`
    }

    const step2Status = () => {
        if (admission.cutoff) {
            return (<span className="d-block">Expired</span>)
        } else if (step === 2) {
            return (<span className="d-block">Pending Patient</span>)
        }
        return null
    }

    const renderSteps = () => {
        return (
            <div className="status-info-holder bg-secondary rounded d-flex align-items-stretch">
                <div className={`status-info-step status-info-step1${stepClass(1)}`}>
                    <div className="content">
                        <strong className="sub-heading d-block">Patient Portal</strong>
                        {step1_msg && <span className="d-block">{step1_msg}</span>}
                        {/*{step1_msg && <span className="d-block">5/5/20 at 5:15pm</span>}*/}
                        <span className="d-block">{admission.insuranceShortString}</span>
                    </div>
                    <svg className="arrow-shape" viewBox="0 0 24 64" version="1.1"
                         xmlns="http://www.w3.org/2000/svg">
                        <polyline points="0,0 3,0 22,32 3,64 0,64 0,0" fill="none" fillRule="evenodd"
                                  stroke="#ffffff" strokeWidth="4px" strokeLinejoin="round"/>
                        <polyline className="cover-line" points="0,0 19,32 0,64 0,0" fill="none"
                                  fillRule="evenodd" stroke="none" strokeWidth="4px"
                                  strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className={`status-info-step status-info-step2${stepClass(2)}`}>
                    <div className="content">
                        <strong className="sub-heading d-block">Signature</strong>
                        {(step > 1) &&
                            <>
                                {step2Status()}
                                <span className="d-block"><a href="#" onClick={showDocumentStatus}>{admission.admissionDocument_completed}/{admission.admissionDocument_total}</a></span>
                            </>
                        }
                    </div>
                    <svg className="arrow-shape" viewBox="0 0 24 64" version="1.1"
                         xmlns="http://www.w3.org/2000/svg">
                        <polyline points="0,0 3,0 22,32 3,64 0,64 0,0" fill="none" fillRule="evenodd"
                                  stroke="#ffffff" strokeWidth="4px" strokeLinejoin="round"/>
                        <polyline className="cover-line" points="0,0 19,32 0,64 0,0" fill="none"
                                  fillRule="evenodd" stroke="none" strokeWidth="4px"
                                  strokeLinejoin="round"/>
                    </svg>
                </div>
                <div className={`status-info-step status-info-step3${stepClass(3)}`}>
                    <AdmissionPayment admission={admission} />
                </div>
            </div>
        );
    }

    return (step === 0) ? <AdmissionList_Item_SelectDocumentSet admission={admission} /> : renderSteps();
}

export default AdmissionProgress;