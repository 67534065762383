import * as actionTypes from '../actions/actionTypes';

const initialState = {
    admissionListProfilerUrl: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DEV_SET_ADMISSIONS_LIST_PROFILER_URL:
            return {
                ...state,
                admissionListProfilerUrl: action.url
            };
    }
    return state
}

export default reducer;