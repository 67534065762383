import React from 'react';
import {useSelector} from "react-redux";
import AdmissionList_Item_ManagerCustomUI_UploadPdf from "./AdmissionList_Item_ManagerCustomUI_UploadPdf";
import AdmissionList_Item_ManagerCustomUI_PreAdmission from "./AdmissionList_Item_ManagerCustomUI_PreAdmission";
import AdmissionList_Item_ManagerCustomUI_SendRegMed from "./AdmissionList_Item_ManagerCustomUI_SendRegMed";
import AdmissionList_Item_ManagerCustomUI_Comments from "./AdmissionList_Item_ManagerCustomUI_Comments";

// TODO: Refactor code - unify copy pasted code
const AdmissionList_Item_ManagerCustomUI = ({admission, position}) => {
    const {managerCustomUI_admissionListUploadPdf, managerCustomUI_admissionListPreAdmission, managerCustomUI_sendRegMed, managerCustomUI_comments} = useSelector(state => state.auth.selectedHospital);

    const renderUploadPdf = () => {
        if (!managerCustomUI_admissionListUploadPdf || !managerCustomUI_admissionListUploadPdf.length) {
            return null;
        }
        return managerCustomUI_admissionListUploadPdf.map((customUI, key) => {
            if (position === customUI.position) {
                return <td><AdmissionList_Item_ManagerCustomUI_UploadPdf key={key} admission={admission} customUI={customUI} /></td>
            }
        })
    }
    const renderPreAdmission = () => {
        if (!managerCustomUI_admissionListPreAdmission || !managerCustomUI_admissionListPreAdmission.length) {
            return null;
        }
        return managerCustomUI_admissionListPreAdmission.map((customUI, key) => {
            if (position === customUI.position) {
                return <td><AdmissionList_Item_ManagerCustomUI_PreAdmission key={key} admission={admission} customUI={customUI} /></td>
            }
        })
    }
    const renderSendMedReg = () => {
        if (!managerCustomUI_sendRegMed || !managerCustomUI_sendRegMed.length) {
            return null;
        }
        return managerCustomUI_sendRegMed.map((customUI, key) => {
            if (position === customUI.position) {
                return <AdmissionList_Item_ManagerCustomUI_SendRegMed key={key} admission={admission} customUI={customUI} />
            }
        })
    }
    const renderComments = () => {
        if (!managerCustomUI_comments || !managerCustomUI_comments.length) {
            return null;
        }
        return managerCustomUI_comments.map((customUI, key) => {
            if (position === customUI.position) {
                return <AdmissionList_Item_ManagerCustomUI_Comments key={key} admission={admission} customUI={customUI} />
            }
        })
    }

    return <>
        {renderUploadPdf()}
        {renderPreAdmission()}
        {renderSendMedReg()}
        {renderComments()}
    </>
}

export default AdmissionList_Item_ManagerCustomUI;