import React from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import {sendManagerCustomUi} from "../../store/actions/admission";
import {useToasts} from "react-toast-notifications";
import Admission_ModalInfo from "../Block/Admission_ModalInfo";

const ModalSendDocumentSetColumn = () => {
    const {addToast} = useToasts();
    const {show, admission, managerCustomUi} = useSelector(state => state.modal.sendDocumentSetColumn);
    const {selectedHospital} = useSelector(state => state.auth);

    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_SEND_DOCUMENT_SET_COLUMN_HIDE});

    const doSendMessage = (event) => {
        dispatch(sendManagerCustomUi(admission, addToast, managerCustomUi));
        event.preventDefault();
        dispatch({type: actionTypes.MODAL_SEND_DOCUMENT_SET_COLUMN_HIDE});
    }

    const documentTitle = managerCustomUi.managerTitle ? managerCustomUi.managerTitle : 'Covid Screening';

    const renderModal = () => {
        if (show) {
            return (
                <div className="modal-dialog modal-content-large" role="document" ref={ref} autoFocus={false}>
                    <div className="modal-content modal-content-fit">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            {/* TODO: ACA-295 refactor hardcoded implementation */}
                            <h3>Send {documentTitle} for {admission.title} {admission.surname}, {admission.name}</h3>
                            <Admission_ModalInfo admission={admission} />
                            <form onSubmit={doSendMessage} className="person-info-form">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex">
                                            <input type="button" value="Cancel" className="btn" onClick={hideModal}/>
                                            <input type="submit" value="Send via Portal" className="btn btn-primary" onClick={doSendMessage}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {renderModal()}
            </div>
        </>
    );
}

export default ModalSendDocumentSetColumn;