import * as actionTypes from './actionTypes';
import axios from 'axios';

export const devUIState = (state, stopLoading) => {
    return (dispatch, getState) => {
        const idToken = getState().auth.idToken;
        console.log('[actions/dev] devUIState');

        axios.post(
            `/dev/dev-ui-state`,
            {state},
            {headers: {'Authorization': idToken}}
        )
            .catch((error) => {
                // TODO: How to handle it??
            })
            .then((response) => {
                dispatch({type: actionTypes.SET_DEV_UI_STATE, value: response.data.state_showDevUI});
                stopLoading();
            });
    }
}