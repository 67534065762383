import React from "react";
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from "../../../store/actions/actionTypes";

const AdmissionList_Item_Checkbox = ({admission}) => {
    const {checkedAdmissions} = useSelector(state => state.bulk.admissionList);
    const hospital = useSelector(state => state.auth.selectedHospital);
    const dispatch = useDispatch();

    const handleChange = () => dispatch({type: actionTypes.ADMISSIONS_LIST_BULK_TOGGLE, id: admission.id});
    const isChecked = checkedAdmissions.includes(admission.id);
    const isDisabled = !isChecked && (checkedAdmissions.length >= hospital.manager_massAction_limit);

    return (
        <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" name="checkbox1" id={`customCheck${admission.id}`} value={admission.id}
                   checked={isChecked}
                   onChange={handleChange}
                   disabled={isDisabled}
                   />
            <label className="custom-control-label" htmlFor={`customCheck${admission.id}`} />
        </div>
    )
}

export default AdmissionList_Item_Checkbox;