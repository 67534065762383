import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getProperty} from "dot-prop";

import RfaForm from "../../components/FinalForm/Rfa/RfaForm";
import useDoctorHospitalData from "../../utils/doctorHospitalData";
import * as rfaActions from "../../store/actions/rfaActions";

const RfaEditView = () => {
    const dispatch = useDispatch();
    const {id, rfaId} = useParams();
    const user = useSelector(state => state.auth);
    const {buildDoctorOptionById, buildHospitalOptionById} = useDoctorHospitalData(user);

    const [rfaValues, setRfaValues] = useState(null);

    const handleRfaData = (rfaData) => {
        if (getProperty(rfaData, 'hospital.id')) {
            rfaData.selectedHospital = buildHospitalOptionById(getProperty(rfaData, 'hospital.id'));
            rfaData.hospital = null;
        }
        if (getProperty(rfaData, 'doctor.id')) {
            rfaData.selectedDoctor = buildDoctorOptionById(getProperty(rfaData, 'doctor.id'));
            rfaData.doctor = null;
        }
        setRfaValues(rfaData);
    }

    useEffect(() => {
        dispatch(rfaActions.load(rfaId, handleRfaData))
    }, [id]);

    if (!rfaValues) {
        return null;
    }

    return <RfaForm title="Edit RFA"
                    handleSubmitAction={rfaActions.edit}
                    initialValues={rfaValues}/>;
}

export default RfaEditView;