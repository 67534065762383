import React from 'react';
import DevUiData from "../../Dev/DevUiData";

const ClinicalFlags = ({admission, hideModal, jmphMedForm}) => {
    const renderClinicalFlag = (item) => {
        if (item.statusRating !== false) {
            return (<li className="alerts-list-item">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <defs></defs>
                    <path className={`${item.rating > 1 ? "alert-bg-danger" : "alert-bg-warning"} `}
                          d="M0,12c0,-6.62742 5.37258,-12 12,-12h0c6.62742,0 12,5.37258 12,12v0c0,6.62742 -5.37258,12 -12,12h0c-6.62742,0 -12,-5.37258 -12,-12z"></path>
                    <g transform="matrix(1,0,0,1,9,1)">
                        <text className={`symbol ${item.rating > 1 ? "alert-symbol-danger" : "alert-symbol-warning"} `}
                              dominant-baseline="text-before-edge">!
                        </text>
                    </g>
                </svg>
                <span className="alert-text">{item.title}</span>
            </li>)
        }
    }

    return (
        <div className="modal-sidebar">
            <div className="modal-header">
                <h5 className="modal-title h1">Alerts {jmphMedForm ? `` : `(Risk score: ${admission.clinicalFlags_summaryRating})`}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <ul className="alerts-list">
                {admission.clinicalFlags && admission.clinicalFlags.map(renderClinicalFlag)}
            </ul>
        </div>
    );
}

export default ClinicalFlags;