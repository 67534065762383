import React from "react";

const LifehouseAdmissionPanel_ButtonWithStatus = ({flag, children}) => {
    // TODO: add date
    if (!flag) {
        // ACA-428 - when admission is cancelled, all admissionDocuments are removed and we do not have this flag
        return <button type="button" className="btn-group-table-button btn btn-gray10">!</button>
    }
    switch (flag.status) {
        case "STATUS_COMPLETED":
            return children;
        case "STATUS_VIEWED":
            return (
                <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-blue" data-tip={`Time viewed: ${flag.lastViewedAt}`}>
                    <span className="icon ico-eye"></span>
                </button>
            );
        case "STATUS_NEW":
            return (
                <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange" data-tip={`Time sent: ${flag.createdAt}`}>
                    <span className="icon ico-letter"></span>
                </button>
            );
        default:
            return <button type="button" className="btn-group-table-button btn btn-gray10">-</button>
    }
}

export default LifehouseAdmissionPanel_ButtonWithStatus;