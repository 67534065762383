import React, {useState} from "react";
import useOnClickOutside from "use-onclickoutside";
import {useSelector} from "react-redux";
import AdmissionList_Tab_TestHospital_TabItem from "./AdmissionList_Tab_TestHospital_TabItem";

const AdmissionList_Tab_TestHospital = () => {
    const [popupVisible, setPopupVisible] = useState(false);
    const {selectedHospital} = useSelector(state => state.auth);
    const admissionList = useSelector(state => state.admissionList);

    const ref = React.useRef(null);
    useOnClickOutside(ref, () => setPopupVisible(false));

    const togglePopup = (event) => {
        setPopupVisible(!popupVisible);
        event.preventDefault();
    }

    const renderDropdown = () => {
        return (
            // TODO: add in Jira refactoring task for filters in PEP
            <li className={`nav-item dropdown filter`} ref={ref}>
                <a className={`nav-link ${admissionList.groupId ? 'active' : ''}`} href="#" onClick={togglePopup}><span>Test</span> <i className="icon ico-small-down" /></a>
                <div className={`tabs-dropdown-menu dropdown-menu dropdown-menu-left${popupVisible ? ' show' : ''}`} aria-labelledby="dropdownTestMenuButton">
                    {selectedHospital.dev_test_admissionTestGroups.map((group, key) => <AdmissionList_Tab_TestHospital_TabItem key={key} admissionTestGroup={group}/>)}
                </div>
            </li>
        );
    }

    return selectedHospital.dev_isTestHospital ? renderDropdown() : null;
}

export default AdmissionList_Tab_TestHospital;
