import React from 'react';
import {useSelector} from "react-redux";
import JSONPretty from "react-json-pretty";

const DevUiData = ({data, mark=true}) => {
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    if (!state_showDevUI) return null;

    if (mark) {
        return <div className="alert alert-warning"><JSONPretty data={data} /></div>;
    }
    return <JSONPretty data={data} />;
}

export default DevUiData;