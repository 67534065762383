import React from "react";
import {useDispatch} from "react-redux";

import * as modalActions from "../../store/actions/modal";

const ModalSelectDocumentSet_Actions = ({admission, documentSet}) => {
    const dispatch = useDispatch();

    const addDocumentSet = (event) => {
        dispatch(modalActions.selectDocumentSet(admission, documentSet))
        event.preventDefault();
    }

    if (documentSet.available) {
        return (
            <button className="btn btn-sm btn-success" onClick={addDocumentSet}>Add</button>
        );
    } else {
        return (
            <button className="btn btn-sm btn-warning">Awaiting data</button>
        );
    }

}

export default ModalSelectDocumentSet_Actions;