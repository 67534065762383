// https://www.smashingmagazine.com/2020/03/sortable-tables-react/

import React from "react";
import {useDispatch} from "react-redux";
import * as actionTypes from "../store/actions/actionTypes";
import {lastWordInString} from "./utils";

const useSortableData = (pushAdmissionsListUrl, items, config = null) => {
    const dispatch = useDispatch();
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
        return doSorting(items, sortConfig);
    }, [items, sortConfig]);

    const requestSort = (key, defaultAsc = true) => {
        let direction = defaultAsc ? 'sorting_asc' : 'sorting_desc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === direction) {
            direction = defaultAsc ? 'sorting_desc' : 'sorting_asc';
        }
        setSortConfig({ key, direction });
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_SORT, sortColumn: key, sortMode: direction})
        pushAdmissionsListUrl(null, null, null, key, direction);
    }

    const getClassNamesFor = name => {
        if (!sortConfig) {
            return 'sorting';
        }
        return sortConfig.key === name ? sortConfig.direction : 'sorting';
    };

    return { items: sortedItems, requestSort, getClassNamesFor };
}

export const doSorting = (items, sortConfig) => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
        // if (sortConfig.key === 'admittingDoctor') {
        //     sortableItems.sort((a, b) => {
        //         const aSurname = lastWordInString(a['admittingDoctor']);
        //         const bSurname = lastWordInString(b['admittingDoctor']);
        //         if (aSurname < bSurname) {
        //             return sortConfig.direction === 'sorting_asc' ? -1 : 1;
        //         }
        //         if (aSurname > bSurname) {
        //             return sortConfig.direction === 'sorting_asc' ? 1 : -1;
        //         }
        //         return 0;
        //     });
        //     return sortableItems;
        // }

        sortableItems.sort((a, b) => {
            let a_value = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : ''; //
            let b_value = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : ''; //
            if (a_value < b_value) {
                return sortConfig.direction === 'sorting_asc' ? -1 : 1;
            }
            if (a_value > b_value) {
                return sortConfig.direction === 'sorting_asc' ? 1 : -1;
            }
            return 0;
        });
        if (sortConfig.key === 'admissionTime') {
            sortableItems.sort((a, b) => {
                if (a['admissionDate_sort'] < b['admissionDate_sort']) {
                    return sortConfig.direction === 'sorting_asc' ? -1 : 1;
                }
                if (a['admissionDate_sort'] > b['admissionDate_sort']) {
                    return sortConfig.direction === 'sorting_asc' ? 1 : -1;
                }
                return 0;
            });
        }
        if (sortConfig.key === 'dischargeTime') {
            sortableItems.sort((a, b) => {
                if (a['dischargeDate_sort'] < b['dischargeDate_sort']) {
                    return sortConfig.direction === 'sorting_asc' ? -1 : 1;
                }
                if (a['dischargeDate_sort'] > b['dischargeDate_sort']) {
                    return sortConfig.direction === 'sorting_asc' ? 1 : -1;
                }
                return 0;
            });
        }
    }
    return sortableItems;
}

export default useSortableData;