import React, {useState} from 'react';
import {useDispatch} from "react-redux";

import Spinner from "../../Spinner";
import FileUploader2 from "../../FileUploader2";
import DevUiData from "../../Dev/DevUiData";
import {approvePreAdmissionPdf} from "../../../store/actions/customUI";


const PreAdmissionsSet_UploadPdf = ({admission, customUI}) => {
    const [approving, setApproving] = useState(false);
    const [fileId, setFileId] = useState(null);

    const dispatch = useDispatch();

    const doApprove = () => {
        setApproving(true);
        dispatch(approvePreAdmissionPdf(admission, customUI, fileId, 13));
    }
    return <>
        <h4>Upload pre-admission PDF</h4>
        <DevUiData data={{fileId}}/>
        <form className="person-info-form">
            {approving ? <Spinner /> : <FileUploader2 api={`${process.env.REACT_APP_API_PATH}/admission/${admission.id}/upload-pdf2`} onSetFile={setFileId}/>}
            <div className="form-group">
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <input type="button" value="Approve & Upload" className="btn btn-primary" disabled={!fileId || approving} onClick={doApprove}/>
                    </div>
                </div>
            </div>
        </form>
    </>
}

export default PreAdmissionsSet_UploadPdf;