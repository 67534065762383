import * as actionTypes from '../actions/actionTypes';

const initialState = {
    oec_claim: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OEC_CLAIM_SET:
            return {
                ...state,
                oec_claim: action.oec_claim,
            };
        default:
            return state;
    }
}

export default reducer;