import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import AdmissionList from "../../components/AdmissionsList/AdmissionList";
import * as actionTypes from "../../store/actions/actionTypes";
import {dischargeListSet} from "../../store/actions/dischargeList";
import DischargeList from "../../components/DischargeList/DischargeList";

const DischargeListView = () => {
    const dispatch = useDispatch();
    const listVars = useSelector(state => state.dischargeList);
    const selectedHospital = useSelector(state => state.auth.selectedHospital);
    const params = useParams();
    const {hospitalId, date, keyword, sortColumn, sortMode, groupType, groupId, filter, page} = params;
    // console.log('[AdmissionsListView] params', params, admissionListVars);

    useEffect(() => {
        // console.log('[AdmissionsListView] useEffect()');
        dispatch({type: actionTypes.MENU_DISCHARGE_LIST});
        dispatch(dischargeListSet(hospitalId, date, keyword, groupType, groupId,filter, page, sortColumn, sortMode));
    }, [
        listVars.date, selectedHospital.id, listVars.keyword, listVars.groupType, listVars.groupId, listVars.filter, listVars.page, listVars.sortColumn, listVars.sortMode
    ]);

    return (
        <>
            <DischargeList />
        </>
    );
}

export default DischargeListView;