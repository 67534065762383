import React from "react";
import {useSelector} from "react-redux";
import GenerateApiIfcData from "./GenerateApiIfcData";
import GenerateApiOecData from "./GenerateApiOecData";
import {CUSTOM_LOGIC_DEMO} from "../../utils/customLogic";
import DemoResetAdmission from "./DemoResetAdmission";

const CustomLogicItemMoreDrop = ({admission}) => {
    const hospital = useSelector(state => state.auth.selectedHospital);

    switch (hospital.hospitalConfig.customLogic) {
        case CUSTOM_LOGIC_DEMO:
            return <>
                <DemoResetAdmission admission={admission} />
            </>
        case "CUSTOM_LOGIC_LIFEHOUSE":
            return (
                <>
                    {/*<GenerateApiIfcData admission={admission} />*/}
                    {/*<GenerateApiOecData admission={admission} />*/}
                </>
            );
        case "CUSTOM_LOGIC_ESPH":
        case "CUSTOM_LOGIC_HEALTHECARE":
        case "CUSTOM_LOGIC_MARYVALE":
        default:
            return <>
                    {/*<GenerateApiIfcData admission={admission} />*/}
                </>;
    }
}

export default CustomLogicItemMoreDrop;