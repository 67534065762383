import React from 'react';
import {useSelector} from "react-redux";

import {useCustomLogic} from "../../utils/customLogic";
import Pager_Page from "./Pager_Page";

const Pager = (params) => {

    const totalPages = parseInt(params.totalPages);
    const currentPage = totalPages < parseInt(params.currentPage) ? totalPages : parseInt(params.currentPage);

    const previousEnabled = currentPage > 1;
    const previousClassname = previousEnabled ? "page-item pointer" : "page-item disabled";
    const nextEnabled = currentPage !== totalPages;
    const nextClassname = nextEnabled ? "page-item pointer" : "page-item disabled";
    const nextPage = () => {
        if (currentPage < totalPages) {
            params.changePage(currentPage + 1);
        }
    }
    const previousPage = () => {
        if (currentPage > 1) {
            params.changePage(currentPage - 1);
        }
    }

    const renderDots = () => <li className="page-item page-dots">....</li>;
    const renderPage = (_page) => <Pager_Page currentPage={currentPage} page={_page} changePage={params.changePage} />;
    const renderPages = () => {
        var result = [];
        const showDots = totalPages > 10;
        const showStartingDots = showDots && (currentPage > 4);
        const showEndingDots = showDots && (currentPage < (totalPages - 3));
        result.push(renderPage(1));
        if (showStartingDots) {
            result.push(renderDots());
        } else {
            for(let i = 2; i<currentPage-1; i++) {
                result.push(renderPage(i));
            }
        }
        if (currentPage > 2) result.push(renderPage(currentPage-1));
        if (currentPage > 1) result.push(renderPage(currentPage));
        if (currentPage < totalPages-1) result.push(renderPage(currentPage+1));
        if (showEndingDots) {
            result.push(renderDots());
        } else {
            for (let i = currentPage + 2; i<totalPages; i++) {
                result.push(renderPage(i));
            }
        }
        if (currentPage < totalPages)result.push(renderPage(totalPages));

        return result;
    }

    return <nav>
        <ul className="pagination">
            <li className={previousClassname}>
                <a className="page-link" onClick={previousPage}>Previous</a>
            </li>
            {renderPages()}
            <li className={nextClassname}>
                <a className="page-link" onClick={nextPage}>Next</a>
            </li>
        </ul>
    </nav>
}

export default Pager;