import React from "react";
import {useSelector} from "react-redux";

const AdmissionPayment = ({admission}) => {
    const idToken = useSelector(state => state.auth.idToken);
    const formRef = React.useRef(null);

    const viewReceipt = (event) => {
        formRef.current.submit();
        event.preventDefault();
    }

    const renderStatus = () => {
        if (!admission.completed_payments_count && !admission.payment_completedOutOfPortal && (admission.cutoff || admission.discharged)) {
            return (
                <span className="d-block">Expired</span>
            )
        }else if (admission.payment_pending) {
            return (
                <span className="d-block">Pending</span>
            )
        } else if (admission.payment_completedOutOfPortal) {
            return (
                <>
                    <span className="d-block">Paid out of portal</span>
                    <span className="d-block">&nbsp;</span>
                </>
            )
        } else {
            return (
                <>
                    <span className="d-block">Paid - {admission.payment_completedAt}</span>
                    <span className="d-block"><a href="#" onClick={viewReceipt}>View Receipt</a></span>
                    <div style={{display: 'none'}}>
                        <form action={`${process.env.REACT_APP_API_PATH}/admission/${admission.id}/receipt`} method="post"
                              ref={formRef}>
                            <input type="hidden" name="token" value={idToken}/>
                        </form>
                    </div>
                </>
            )
        }
    }

    if (admission.payment_enabled) {
        return (
            <div className="content">
                <strong className="sub-heading d-block">Payment $ {admission.payment_amount}</strong>
                {renderStatus()}
            </div>
        );
    } else {
        return (
            <div className="content">
                <strong className="sub-heading d-block">No payment</strong>
            </div>
        );
    }

}

export default AdmissionPayment;