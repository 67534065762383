import React, {useRef} from "react";
import ReactToPrint from 'react-to-print';
import XMLViewer from 'react-xml-viewer'
import JSONPretty from 'react-json-pretty';

import CollapsableString from "../CollapsableString";
import FundAssessmentCode from "../FundAssessmentCode";
import ModalOecStatus_ClaimDevInfo from "./ModalOecStatus_ClaimDevInfo";
import {presentingIllnessCodeDescription} from "../../utils/presentingIllnessCodes";
import {useCustomLogic} from "../../utils/customLogic";

class OecData extends React.Component {
    render() {
        const {claim, admission} = this.props;
        return (
            <div className="row">
                <div className="col-12 oec-description">
                    <div className="bg-white rounded shadow-sm mb-4">
                        <table className="table table-hover table-sm table-oec">
                            <tbody>
                            <tr>
                                <td>Patient name<br />
                                    Admission No<br />
                                    Health Fund
                                </td>
                                <td>
                                    {admission.title} {admission.surname}, {admission.name}<br />
                                    {admission.ADMNumber}<br />
                                    {admission.fundCompleteTitle}
                                </td>
                                <td>UR no<br />
                                    DOB<br />
                                    Gender
                                </td>
                                <td>
                                    {admission.URNumber}<br />
                                    {admission.DOB}<br />
                                    {admission.adm2_gender}
                                </td>
                            </tr>
                            <tr>
                                <td className="white-space-no-wrap">Health Fund Table<br />
                                    Membership No<br/>
                                    Trans ID
                                </td>
                                <td colSpan={3}>{claim.oec_response_vars.tableName}<br />
                                    {admission.adm2_membershipNumber || admission.fundMemberNo}<br/>
                                    {claim.oec_request_transaction_id}</td>
                            </tr>
                            <tr>
                                <td>Financial status<br />
                                    Fund ref ID<br />
                                    Intended stay
                                </td>
                                <td>{claim.oec_response_vars.financialStatus}<br />
                                    {claim.oec_response_vars.fundReferenceId}<br />
                                    {admission.adm2_intendedStay}
                                </td>
                                <td>Subject to PEA<br />
                                    Table scale<br />
                                    Planned LOS
                                </td>
                                <td>{claim.oec_response_vars.peaPotentialInd}<br />
                                    {claim.oec_response_vars.tableScale}<br />
                                    {admission.adm2_lengthOfStay}
                                </td>
                            </tr>
                            <tr>
                                <td className="white-space-no-wrap">Presenting Illness Code<br />
                                    Diagnosis
                                </td>
                                <td colSpan={3}>{claim.illnessCode ? presentingIllnessCodeDescription(claim.illnessCode) : claim.mbsCode} <CollapsableString str={claim.mbsCode_description}
                                                                                   length={250}/><br />
                                    TODO: not exists in csv
                                </td>
                            </tr>
                            <tr>
                                <td>Request Date<br />
                                    Response Date</td>
                                <td>{claim.oec_request_date}<br />
                                    {claim.oec_response_date}</td>
                                <td>Assess Code<br />
                                    </td>
                                <td><FundAssessmentCode fundAssessmentCode={claim.statusStr}/><br />

                                </td>
                            </tr>
                            <tr>
                                <td>CoPayment Amount<br />
                                    Excess Amount
                                </td>
                                <td>{claim.oec_response_vars.patientCoPayment_amountStr}<br />
                                    {claim.oec_response_vars.patientExcessPayment_amountStr}
                                </td>
                                <td>CoPayment Days Remaining<br />
                                    Excess Bonus Amount
                                </td>
                                <td>
                                    {claim.oec_response_vars.patientCoPayment_daysRemaining}<br />
                                    {claim.oec_response_vars.patientExcessPayment_bonusAmountStr}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <h6 className="h6">Table Description</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.tableDescription}</p>
                    <h6 className="h6">Benefit Limitations</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.benefitLimitations}</p>
                    <h6 className="h6">Co-payment Description</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.patientCoPayment_amountDescription}</p>
                    <h6 className="h6">Excess Amount Description</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.patientExcessPayment_amountDescription}</p>
                    <h6 className="h6">Exclusion Description</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.exclusionDescription}</p>
                </div>
            </div>
        );
    }
}

const ModalOecStatus_Claim = ({claim, admission}) => {
    const OecDataRef = useRef();
    const {isCustomLogicLifehouse} = useCustomLogic();
    const claimClassname = () => isCustomLogicLifehouse() ? "col-5" : "col-9";

    const renderDone = () => {
        return (
            <div className={claimClassname()}>
                <div className="main-content">

                    <div className="row">
                        <div className="col-2">
                            <ReactToPrint
                                bodyClass="oec-print"
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return <button className="btn btn-white btn-time-opener">
                                            <span className="icon-calendar"><svg width="1em" height="1em"
                                                                                 viewBox="0 0 16 16"
                                                                                 className="bi bi-printer"
                                                                                 fill="currentColor"
                                                                                 xmlns="http://www.w3.org/2000/svg">
  <path
      d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"/>
  <path fillRule="evenodd"
        d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"/>
  <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                            </svg></span>
                                        <span>Print</span>
                                    </button>;
                                }}
                                content={() => OecDataRef.current}
                            />

                        </div>
                        <div className="col-10">
                            <h2 className="h4">OEC Response Report</h2>
                        </div>

                    </div>
                    <OecData admission={admission} claim={claim} ref={OecDataRef}/>
                    {renderDevInfo()}
                </div>
            </div>
        )
    }

    const renderDevInfo = () => <ModalOecStatus_ClaimDevInfo claim={claim}/>

    const renderMessage = (msg) => {
        return (
            <div className={claimClassname()}>
                <div className="main-content">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="h4">OEC Response Report</h2>
                            <p>{msg}</p>
                        </div>
                    </div>
                    {renderDevInfo()}
                </div>
            </div>
        )
    }

    switch (claim.statusStr) {
        case "STATUS_NEW":
            return renderMessage('Eligibility check in progress - awaiting response from Services Australia');
        case "STATUS_PROCESSING":
            return renderMessage('Eligibility check in progress - awaiting response from Services Australia');
        case "STATUS_ERROR":
            return renderMessage(claim.error_description);
        case "STATUS_DONE":
        case "STATUS_DONE_ACCEPTED":
        case "STATUS_DONE_REJECTED":
        case "STATUS_DONE_WARNING":
            return renderDone();
    }

}

export default ModalOecStatus_Claim;