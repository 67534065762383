import React from "react";
import InputMask from "react-input-mask";

const DateMaskField = ({input,meta}) => {
    return <InputMask
                className={`date-mask-field form-control ${meta.error && meta.touched && 'form-error'}`}
                mask='99/99/9999'
                value={input.value}
                onChange={input.onChange}
                onBlur={input.onBlur}
            />
}

export default DateMaskField;