import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import * as hospitalPatientActions from '../../store/actions/hospitalPatient';
import * as actionTypes from "../../store/actions/actionTypes";
import HospitalPatientForm from "../../components/FinalForm/HospitalPatient/HospitalPatientForm";

const HospitalPatientEditView = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const hospitalPatient = useSelector(state => state.hospitalPatient.hospitalPatient);
    const patientValues = {
        id: hospitalPatient.id,
        dob: hospitalPatient.DOB,
        firstName: hospitalPatient.name,
        lastName: hospitalPatient.surname,
        mobile: hospitalPatient.mobilePhone,
    };

    useEffect(() => {
        dispatch({type: actionTypes.MENU_ADMISSIONS_LIST, subAction: true});
        dispatch(hospitalPatientActions.load(id));
    }, [id]);
    console.log('[HospitalPatient]', id, hospitalPatient);

    if (hospitalPatient && hospitalPatient.id) {
        return <HospitalPatientForm title="Edit Patient" handleSubmitAction={hospitalPatientActions.editHospitalPatient} initialValues={patientValues}/>;
    }
    return null;
}

export default HospitalPatientEditView;