import * as actionTypes from './actionTypes';
import axios from 'axios';
import {push} from "react-router-redux";

export const add = (data, errorCallback) => {
    return (dispatch, getState) => {
        console.log('[action/rfaActions] addRfa', data);
        const idToken = getState().auth.idToken;
        const hospitalPatient = getState().hospitalPatient.hospitalPatient;
        dispatch({type: actionTypes.LOADING_START});

        axios.post(
            `/rfa/add/${hospitalPatient.id}`,
            data,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/rfaActions] addRfa response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    dispatch(push(`/hp/${hospitalPatient.id}`))
                } else {
                    errorCallback(response.data.customStatus);
                    dispatch({type: actionTypes.LOADING_DONE});
                }
            });
    }
}

export const edit = (data, errorCallback) => {
    return (dispatch, getState) => {
        console.log('[action/rfaActions] edit', data);
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;
        const hospitalPatient = getState().hospitalPatient.hospitalPatient;

        axios.post(
            `/rfa/${data.id}/edit`,
            data,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/rfaActions] edit response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    dispatch(push(`/hp/${hospitalPatient.id}`))
                } else {
                    errorCallback(response.data.customStatus);
                    dispatch({type: actionTypes.LOADING_DONE});
                }
            });
    }
}

export const load = (id, handleDataCallback) => {
    return (dispatch, getState) => {
        // console.log('[action/rfaActions] loadRfa ...')
        const idToken = getState().auth.idToken;

        dispatch({type: actionTypes.MENU_ADMISSIONS_LIST, subAction: true});
        dispatch({type: actionTypes.LOADING_START});
        axios.get(
            `/rfa/${id}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/rfaActions] loadRfa response', response.data);
                handleDataCallback(response.data.rfa);

                dispatch({type: actionTypes.HOSPITAL_PATIENT_SET, hospitalPatient: response.data.hospitalPatient});
                dispatch({type: actionTypes.LOADING_DONE});
                dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, ...response.data.hospitalPatient.hospital});
            });
    }
}