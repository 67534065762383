import React, {useState, useRef} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {push} from "react-router-redux";
import useOnClickOutside from "use-onclickoutside";
import {getProperty} from "dot-prop";

const NavBar_Add = () => {
    const auth = useSelector(state => state.auth);
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();
    const ref = useRef(null);

    const redirectToNewAdmission = () => dispatch(push(`/admission/add`));
    const redirectToNewHospitalPatient = () => dispatch(push(`/hp/add`));
    const toggleVisible = () => setIsVisible(!isVisible);
    useOnClickOutside(ref, () => {setIsVisible(false)})

    if (getProperty(auth, 'role.canAddPatients', false)) {
        return (
            <div className="dropdown" ref={ref}>
                <button className="btn btn-primary dropdown-toggle" type="button" onClick={toggleVisible}>
                    Add
                </button>
                <div className={`dropdown-menu ${isVisible ? 'show' : ''} dropdown-menu-right`} aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#" onClick={redirectToNewHospitalPatient}>Patient</a>
                    <a className="dropdown-item" href="#" onClick={redirectToNewAdmission}>Consult</a>
                </div>
            </div>
        )
    }

    return null;
}

export default NavBar_Add;