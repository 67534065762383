import React, {useState} from "react";

const CollapsableString = ({str, length}) => {
    const [open, setOpen] = useState(false);

    const moreClick = event => {
        setOpen(true);
        event.preventDefault();
    }
    const lessClick = event => {
        setOpen(false);
        event.preventDefault();
    }

    if (!str) {
        return null;
    }
    if (str.length > length) {
        if (open) {
            return <>
                    {str} <a href='#' onClick={lessClick}>less</a>
                </>;
        } else {
            return <>
                {str.substr(0,length)} ...<a href='#' onClick={moreClick}>more</a>
            </>
        }
    } else {
        return <>{str}</>;
    }
}

export default CollapsableString;