import * as actionTypes from './actionTypes';
import axios from 'axios';

import {admissionListReload} from "./admissionList";
import {useSelector} from "react-redux";

export const showDocumentFiles = (admissionDocumentId) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] showDocumentFiles', admissionDocumentId);
        axios.get(
            `/admission/document/${admissionDocumentId}/files`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] showDocumentFiles response ', response.data);
                dispatch({type: actionTypes.MODAL_ADMISSION_DOCUMENT_FILES_SHOW, ...response.data});
                dispatch({type: actionTypes.RELOADING_DONE});
            });
    }
}

export const pdfUploadApprove = (admission, addToast) => {
    return (dispatch, getState) => {
        // dispatch({type: actionTypes.MODAL_ADMISSION_UPLOAD_APPROVING});
        dispatch({type: actionTypes.MODAL_ADMISSION_UPLOAD_PDF_HIDE});
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});

        const idToken = getState().auth.idToken;

        console.log('[actions/modal] pdfUploadApprove', admission.id);

        axios.get(
            `${process.env.REACT_APP_API_PATH}/admission/${admission.id}/approve-pdf`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                addToast(response.data.message, {
                    appearance: response.data.done ? 'success' : 'error',
                    autoDismiss: false
                });
                console.log('[actions/modal] pdfUploadApprove reload admissions list', response);
                // dispatch({type: actionTypes.MODAL_ADMISSION_UPLOAD_PDF_HIDE});
                // dispatch(admissionListReload());

                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                const currentAdmission = getState().admission.admission;
                if (currentAdmission && (currentAdmission.id === admission.id)) {
                    dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                }
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});

                // window.location.reload(); - cant be used, because dynamic sorting and filters will be lost
            });
    }
};

export const enablePatientPortal = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.MODAL_ENABLE_PATIENT_PORTAL_LOADING});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] enablePatientPortal', admission.id);

        axios.get(
            `/admission/${admission.id}/enable-pp`,
            {headers: {'Authorization': idToken}}
        )
        .then((response) => {
            console.log('[actions/modal] enablePatientPortal reload admissions list');
            dispatch({type: actionTypes.MODAL_ENABLE_PATIENT_PORTAL_HIDE});
            dispatch(admissionListReload());
            // window.location.reload(); - cant be used, because dynamic sorting and filters will be lost
        });
    }
};

export const showDocumentSetsForAdmission = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] showDocumentSetsForAdmission', admission.id);
        axios.get(
            `/admission/${admission.id}/document-sets`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] showDocumentSetsForAdmission response ', response.data);
                dispatch({type: actionTypes.MODAL_SELECT_DOCUMENT_SET_SHOW, documentSets: response.data.documentSets, admission});
                dispatch({type: actionTypes.RELOADING_DONE});
            });
    }
}

export const showAdmissionMessagesForAdmission = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] showAdmissionMessagesForAdmission', admission.id);
        axios.get(
            `/admission/${admission.id}/admission-messages`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] showAdmissionMessagesForAdmission response ', response.data);
                dispatch({type: actionTypes.MODAL_ADMISSION_MESSAGES_SHOW, admissionMessages: response.data.admissionMessages, admission});
                dispatch({type: actionTypes.LOADING_DONE});
            });
    }
}
export const showAdmissionDocumentsForAdmission = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] showAdmissionDocumentsForAdmission', admission.id);
        axios.get(
            `/admission/${admission.id}/admission-documents`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] showAdmissionDocumentsForAdmission response ', response.data);
                dispatch({type: actionTypes.MODAL_ADMISSION_DOCUMENTS_SHOW, admissionDocuments: response.data.admissionDocuments, admission});
                dispatch({type: actionTypes.LOADING_DONE});
            });
    }
}
export const showRfaForAdmission = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] showRfaForAdmission', admission.id);
        axios.get(
            `/admission/${admission.id}/rfa`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] showRfaForAdmission response ', response.data);
                dispatch({type: actionTypes.MODAL_ADMISSION_RFA_SHOW, admissionRfa: response.data.admissionRfa, admission});
                dispatch({type: actionTypes.LOADING_DONE});
            });
    }
}

export const showOecForAdmission = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] showOecForAdmission', admission.id);
        axios.get(
            `/admission/${admission.id}/oec-claims`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                if (admission.eclipseModalBc) {
                    console.log('[actions/modal] showOecForAdmission response ', response.data);
                    dispatch({type: actionTypes.MODAL_OEC_STATUS_SHOW, oecClaims: response.data.oecClaims, admission});
                    dispatch({type: actionTypes.LOADING_DONE});
                } else {
                    console.log('[actions/modal] showOecForAdmission response ', response.data);
                    dispatch({type: actionTypes.MODAL_OEC_STATUS_PRODA_SHOW, prodaRequests: response.data.prodaRequests, admission});
                    dispatch({type: actionTypes.LOADING_DONE});
                }
            });
    }
}

export const selectDocumentSet = (admission, documentSet) => {
    return (dispatch, getState) => {
        const removePaymentOption = getState().modal.selectDocumentSet.removePaymentOption;
        const idToken = getState().auth.idToken;

        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_SELECT_DOCUMENT_SET_HIDE});

        console.log('[actions/modal] selectDocumentSet', admission.id, removePaymentOption);
        axios.get(
            `/admission/${admission.id}/document-set/${documentSet.id}/enable/${removePaymentOption ? 1 : 0}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] selectDocumentSet', response.data);
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
            });
    }
}

export const showDocumentStatusForAdmission = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] selectDocumentSet', admission.id);
        axios.get(
            `/admission/${admission.id}/document-status`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] selectDocumentSet', response.data);
                dispatch({type: actionTypes.MODAL_DOCUMENT_STATUS_SHOW, admission: admission, documents: response.data.documents, payments: response.data.payments});
                dispatch({type: actionTypes.LOADING_DONE});
            });
    }
}

export const requestOecCheck = (admission, addToast = false) => {
    return (dispatch, getState) => {
        // dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] requestOecCheck', admission.id);
        axios.get(
            `/admission/${admission.id}/send-to-oec-queue`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                if (response.data.done) {
                    if (addToast) {
                        addToast(`OEC check scheduled for admission ${admission.label}`, {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    }
                } else {
                    if (addToast) {
                        addToast(`Failed to schedule OEC check for admission ${admission.label} - ${response.data.error}`, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    }
                }
            });
    }
}

export const sendToQueue = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] sendToQueue', admission.id);
        axios.get(
            `/admission/${admission.id}/send-to-oec-queue`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] sendToQueue', response.data);
                dispatch(showOecForAdmission(admission));
            });
    }
}
export const reRunOec = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] reRunOec', admission.id);
        axios.get(
            `/admission/${admission.id}/re-run-oec`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] reRunOec', response.data);
                dispatch(showOecForAdmission(admission));
            });
    }
}

export const setPresentingIllnessCodes = (admission, selectedOptions, addToast) => {
    return (dispatch, getState) => {
        console.log('[action/admissions] setPresentingIllnessCodes', admission.id);
        const idToken = getState().auth.idToken;
        // TODO: refactor
        // dispatch({type: actionTypes.RESEND_SMS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_PRESENTING_ILLNESS_CODES_HIDE});

        axios.post(
            `/admission/${admission.id}/set-presenting-illness-codes`,
            {selectedOptions: selectedOptions.map(item => item.value)},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissions] setPresentingIllnessCodes response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`Presenting Illness codes were set for admission ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: false
                    });
                    const currentAdmission = getState().admission.admission;
                    if (currentAdmission && (currentAdmission.id === admission.id)) {
                        console.log('[action/admissions] resendSms fast reload');
                        dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                    }
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    addToast(`Failed to set Presenting Illness Codes for admission ${admission.label}`, {
                        appearance: 'error',
                        autoDismiss: false
                    });
                    dispatch({type: actionTypes.RESEND_SMS_DONE, id: admission.id});
                }
            });
    }
}

export const showAdmRegDiff = (admission, api = '/eRFA-diff') => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] showAdmRegDiff', admission.id);
        axios.get(
            `/admission/${admission.id}${api}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] showAdmRegDiff response', response.data);
                dispatch({type: actionTypes.MODAL_ADM_REG_DIFF_SHOW, data: response.data, admission});
                dispatch({type: actionTypes.LOADING_DONE});
            });
    }
}

export const showAdmEmrView = (admission) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        dispatch({type: actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_HIDE});
        const idToken = getState().auth.idToken;

        console.log('[actions/modal] showAdmEmrView', admission.id);
        axios.get(
            `/admission/${admission.id}/emd-reg-diff`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] showAdmEmrView response', response.data);
                dispatch({type: actionTypes.MODAL_ADM_REG_DIFF_SHOW, data: response.data, admission, emrView: true});
                dispatch({type: actionTypes.LOADING_DONE});
            });
    }
}