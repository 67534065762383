import React from "react";
import {Field} from "react-final-form";
import {useSelector} from "react-redux";
import {parse, isValid} from "date-fns";

import PhoneField from "../Field/PhoneField";
import HospitalPatientForm_Submit from "./HospitalPatientForm_Submit";
import DateMaskField from "../Field/DateMaskField";
import Hospital from "../FormGroup/Hospital";

const validPhoneNumber = value => (value && ((value.length !== 10) || (value.substr(0,2) !== '04'))) ? 'Must be a number' : undefined;
const validDate = value => {
    const date = parse(value, 'dd/MM/yyyy', new Date())
    return isValid(date) && (date.getFullYear() < 2030) && (date.getFullYear() > 1900) ? undefined : 'Wrong date';
}

const HospitalPatient_Form = ({handleSubmit, title, hospitalSelection}) => {
    const user = useSelector(state => state.auth);

    return (
        <form className="person-info-form mt-6" onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="row">
                    <div className="col-2 form-title2">
                        Patient Name
                    </div>
                    <div className="col-3">
                        <Field component="input" name="firstName" className="form-control"
                               autoFocus={true} placeholder="First"/>
                    </div>
                    <div className="col-3">
                        <Field component="input" name="lastName" className="form-control"
                               placeholder="Last"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-2 form-title2">
                        Patient Mobile
                    </div>
                    <div className="col-5">
                        <Field component={PhoneField}
                               validate={validPhoneNumber}
                               name="mobile"/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-2 form-title2">
                        DOB
                    </div>
                    <div className="col-5">
                        <Field component={DateMaskField}
                               validate={validDate}
                               name="dob"/>
                    </div>
                </div>
            </div>
            <Hospital hospitalSelection={hospitalSelection} />
            <HospitalPatientForm_Submit title={title}/>
        </form>
    );
}

export default HospitalPatient_Form;