import React, {useEffect, useState} from 'react';
import * as actionTypes from "../../store/actions/actionTypes";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import OecClaim from "../../components/OecClaim/OecClaim";
import {useParams} from "react-router-dom";

const ProdaWebserviceRequestView = () => {
    const idToken = useSelector(state => state.auth.idToken);
    const [proda, setProda] = useState(false);
    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        axios.get(
            `/proda/${id}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                setProda(response.data);
                dispatch({type: actionTypes.LOADING_DONE});
            });
    }, [id]);
    if (proda) {
        return <OecClaim claim={proda} />
    }

    return null;
}

export default ProdaWebserviceRequestView;