import React from 'react';
import ReactQuill from "react-quill";
import {useForm, useFormState, Field} from "react-final-form";
import {useSelector} from "react-redux";

const modules = {
    toolbar: [
        // [{ 'header': [4, false] }],
        ['bold', 'italic', 'underline', 'strike'], // , 'blockquote'
        // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
};
const AdmissionMessageTemplate_TitleBody = () => {
    const form = useFormState();
    const user = useSelector(state => state.auth);

    if (form.values.amt) {
        // console.log('[AdmissionMessageTemplate_TitleBody]', form.values.amt);
        const hospitalId = form.values.hospital ? form.values.hospital.id : form.values.selectedHospital.value;
        const template = user.doctorHospitals.find(hospital => hospital.id === hospitalId).rfa_admissionMessageTemplates.find(template => template.id === form.values.amt.value);

        const title = template.title;
        const handleTitle = () => {
        };

        const text = template.message;
        const handleText = () => {
        };

        return (
            <div className="form-group">
                <div className="row">
                    <div className="col-2 form-title2 margin0">
                        &nbsp;
                    </div>
                    <div className="col-5">
                        <div className="form-group mb-4">
                            Title <br/>
                            <input name="title" className="form-control" onChange={handleTitle} value={title}/>
                        </div>
                        <div className="form-group mb-4">
                            Message <br/>
                            <ReactQuill theme="snow" value={text} onChange={handleText} modules={modules}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}
export default AdmissionMessageTemplate_TitleBody;