import React from "react";
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from "../../../store/actions/actionTypes";
import useAdmissionListNavigation from "../../../utils/admissionListNavigation";

const LH_SELECTOR_WL = 'wl';

const AdmissionList_Tab_Lifehouse = () => {
    const admissionListVars = useSelector(state => state.admissionList);
    const {pushAdmissionsListUrl} = useAdmissionListNavigation();
    const dispatch = useDispatch();

    const isTabWL = () => (admissionListVars.groupType === 'lh') && (admissionListVars.groupId === LH_SELECTOR_WL);
    const selectTabWl = () => selectTab(LH_SELECTOR_WL);

    const selectTab = selector => {
        pushAdmissionsListUrl(null, null, '', null,null, 'lh', selector);
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_KEYWORD})
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_GROUP, groupType: 'lh', groupId: selector})
    }

    return (
        <li className="nav-item">
            <a className={`nav-link${isTabWL() ? ' active' : ''}`}
               href="#" onClick={selectTabWl}><span>WL</span> {admissionListVars.count_WL}</a>
        </li>
    );
}

export default AdmissionList_Tab_Lifehouse;
