import React from 'react';

const Pager_Page = ({currentPage, page, changePage}) => {
    const selectPage = () => changePage(page);

    if (page === currentPage) {
        return (
            <li className="page-item active pointer">
                    <span className="page-link">{page}
                        <span className="sr-only">(current)</span>
                    </span>
            </li>
        );
    } else {
        return <li className="page-item pointer"><a className="page-link" onClick={selectPage}>{page}</a></li>
    }
}

export default Pager_Page;