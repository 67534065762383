import {useSelector} from "react-redux";

const usePermissions = () => {
    const selectedHospital = useSelector(state => state.auth.selectedHospital);

    const permissions = {
        PERMISSION_LIFEHOUSE_DELETE_PATIENT: 'PERMISSION_LIFEHOUSE_DELETE_PATIENT',
        PERMISSION_LIFEHOUSE_ARCHIVE_PRE_AD: 'PERMISSION_LIFEHOUSE_ARCHIVE_PRE_AD',
    };

    const hasPermission = (permission) => !!selectedHospital.permissions.find(item => item.permissionConst === permission);

    return {permissions, hasPermission};
}

export default usePermissions;