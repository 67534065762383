import React from "react";
import * as modalActions from "../../../store/actions/modal";
import {useDispatch} from "react-redux";

const AdmissionList_Item_Fund = ({admission}) => {
    const dispatch = useDispatch();
    const showModal = event => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(modalActions.showOecForAdmission(admission));
    }
    switch (admission.statusFundingStr) {
        default:
        case "STATUS_FUND_NOT_CHECKED":
            return (
                <button className="btn btn-sm btn-secondary">-</button>
            );
        case "STATUS_FUND_WARNING":
            return (
                <button className="btn btn-sm btn-warning" onClick={showModal}>{admission.oec_statusVars.acceptedCount}/{admission.oec_statusVars.totalCount}</button>
            );
        case "STATUS_FUND_REJECTED":
            return (
                <button className="btn btn-sm btn-danger" onClick={showModal}>{admission.oec_statusVars.acceptedCount}/{admission.oec_statusVars.totalCount}</button>
            );
        case "STATUS_FUND_ACCEPTED":
            return (
                <button className="btn btn-sm btn-success" onClick={showModal}>{admission.oec_statusVars.acceptedCount}/{admission.oec_statusVars.totalCount}</button>
            );
    }
}

export default AdmissionList_Item_Fund;