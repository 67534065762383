import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import * as hospitalPatientActions from '../../store/actions/hospitalPatient';
import * as actionTypes from "../../store/actions/actionTypes";
import HospitalPatient from "../../components/HospitalPatient/HospitalPatient";

const HospitalPatientView = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const hospitalPatient = useSelector(state => state.hospitalPatient.hospitalPatient);

    useEffect(() => {
        dispatch({type: actionTypes.MENU_ADMISSIONS_LIST, subAction: true});
        dispatch(hospitalPatientActions.load(id));
    }, [id]);
    console.log('[HospitalPatient]', id, hospitalPatient);

    if (hospitalPatient && hospitalPatient.id) {
        return <>
                <HospitalPatient/>
            </>;
    }
    return null;
}

export default HospitalPatientView;