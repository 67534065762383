import React from 'react';
import * as _ from "lodash";
import {useDispatch, useSelector} from "react-redux";

import {subfilterVariables} from '../../../utils/subfilters';
import * as actionTypes from "../../../store/actions/actionTypes";

const dynamicFilters = {'WARD': 'Ward: ', 'CLAIM_CODE': 'Claim code: ', 'FUNDING_GROUP': 'Funding group: '}

const AdmissionList_SubFilter_CurrentSubfilter = () => {
    const subfilters = useSelector(state => state.admissionList.subfilters);
    const dispatch = useDispatch();

    const dispatchReset = () => dispatch({type: actionTypes.SUBFILTER_RESET});
    const dispatchUnset = (key) => dispatch({type: actionTypes.SUBFILTER_SET, subfilter: key, value: undefined});

    const variableTitle = (variable, value) => {
        if (dynamicFilters[variable]) {
            return dynamicFilters[variable] + value.join(', ');
        }
        const filterInfo = subfilterVariables.find(val => val.variable === variable);

        if (value === false) {
            return filterInfo.noTitle ? filterInfo.noTitle : `no ${filterInfo.title}`;
        } else {
            return filterInfo.title;
        }
    }

    let currentSubfilters = [];
    _.forIn(subfilters, (value, key) => {
        if (value !== undefined) {
            if (dynamicFilters[key] && (!value || !value.length)) {
                return null;
            }

            currentSubfilters.push(<li key={key} className="table-buttons-item"><a href="#" onClick={() => dispatchUnset(key)}
                                                                         className="btn btn-sm btn-teal">{variableTitle(key, value)}</a>
            </li>);
        }
    });
    if (!currentSubfilters.length) {
        return null;
    }

    return (
        <>
            {currentSubfilters}
            <li className="table-buttons-item"><a onClick={dispatchReset} href="#" className="btn btn-sm btn-dark-gray">Reset</a></li>
        </>
    );
}

export default AdmissionList_SubFilter_CurrentSubfilter;