import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import AdmissionProgress from "../AdmissionProgress";
import AdmissionList_Item_MoreDrop from "./AdmissionList_Item_MoreDrop";
import AdmissionList_Item_Pdf from "./AdmissionList_Item_Pdf";
import AdmissionList_Item_OEC from "./AdmissionList_Item_OEC";
import AdmissionList_Item_Fund from "./AdmissionList_Item_Fund";
import AdmissionList_Item_AdmTime from "./AdmissionList_Item_AdmTime";
import AdmissionList_Item_Checkbox from "./AdmissionList_Item_Checkbox";
import LifehouseAdmissionPanel from "./LifehouseAdmissionPanel";
import AdmissionList_Item_Name from "../../CustomLogic/AdmissionList_Item_Name";
import {CUSTOM_LOGIC_DEMO, CUSTOM_LOGIC_MARYVALE, useCustomLogic} from "../../../utils/customLogic";
import AdmissionList_Item_CustomColumn from "./AdmissionList_Item_CustomColumn";
import {showAdmissionDocumentsForAdmission, showAdmissionMessagesForAdmission, showRfaForAdmission} from "../../../store/actions/modal";
import Spinner from "../../Spinner";
import AdmissionList_Item_ManagerCustomUI from "./ManagerCustomUi/AdmissionList_Item_ManagerCustomUI";

const AdmissionList_Item = ({admission}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {keyword} = useParams();
    const auth = useSelector(state => state.auth);
    const {groupId} = useSelector(state => state.admissionList);
    const {isCustomLogicMaryvale, isCustomLogicHealtheCare, isCustomLogicLifehouse, isCustomLogicESPH, isCustomLogicDemo, isCustomLogicFSPH, isCustomLogicJMPH} = useCustomLogic();
    const reloadingProgress = useSelector(state => state.main.reloadingProgress);

    const goToAdmission = (event) => {
        console.log('[AdmissionList_Item] goToAdmission()')
        if (event.ctrlKey) {
            window.open(`${process.env.REACT_APP_AUTH0_REDIRECT}admission/${admission.id}`, '_blank');
        } else {
            navigate(`/admission/${admission.id}`);
        }
    }


    const renderTime = () => {
        if (isCustomLogicLifehouse()) {
            if (admission.cancelled) {
                return (
                    <div className="lifehouseDateHolder">
                        <div className="badge badge-danger">Cancelled</div>
                    </div>
                )
            }
            return (
                <div className="lifehouseDateHolder">
                    <div>{admission.admissionDate} {admission.admissionTime}</div>
                    {admission.lifehouse_admission_exclamationPoint && <div className="lifehouseDateFlag">!</div>}
                </div>
            );
        }
        else if (isCustomLogicJMPH()) {
                return (
                    <div className="lifehouseDateHolder">
                        <div>{admission.admissionDate} {admission.admissionTime}</div>
                        {(admission.adm2_high_risk === 'Y') && <div className="lifehouseDateFlag">!</div>}
                    </div>
                );
        } else {
            return <>{admission.admissionDate} {admission.admissionTime}</>
        }
    }

    const renderOec = () => {
        if (admission.hospital.oec_enableManagerUI) {
            return (
                <td onClick={goToAdmission} className="pointer"><AdmissionList_Item_OEC admission={admission}/></td>
            );
        }
        return null;
    }

    const renderAdmTime = () => {
        if (auth.selectedHospital.atm_enable) {
            return (
                <td onClick={goToAdmission} className="pointer"><AdmissionList_Item_AdmTime admission={admission} /></td>
            );
        }
        return null;
    }

    const renderManagerCustomUI = () => {
        // TODO: ACA-295  - REFACTOR
        //       if (auth.selectedHospital.managerCustomUI.length) {
        //          to
        //       useManagerCustomUI() + if(hasAdmissionListColumnSend())
        //     something similar to utiles/subfilteredData.js useFilteredData()
        if (auth.selectedHospital.managerCustomUI.length) {
            return auth.selectedHospital.managerCustomUI.map((item, key) =>
                <td key={key} onClick={goToAdmission} className="pointer"><AdmissionList_Item_CustomColumn admission={admission} managerCustomUi={item}/></td>
            );
        }
        return null;
    }

    const renderAdmDocs = () => {
        if (auth.selectedHospital.admDocs_enable) {
            return (
                <td onClick={goToAdmission} className="pointer"><AdmissionList_Item_Fund admission={admission} /></td>
            );
        }
        return null;
    }

    const renderDevAdmissionId = () => {
        if (auth.manager_devShowAdmissionIdInList) {
            return (<>
                    #{admission.id}<br/>
                </>
            )
        }
        return null;
    }

    const privatePracticeAdmissionType = () => {
        switch (admission.privatePractice_admission_type) {
            case "TYPE_CONSULT":
                return "Consult";
            case "TYPE_SURGERY":
                return "Surgery";
        }
    }

    const showAdmissionMessagesModal = () => dispatch(showAdmissionMessagesForAdmission(admission))
    const showAdmissionDocumentsModal = () => dispatch(showAdmissionDocumentsForAdmission(admission))
    const showAdmissionRfaModal = () => dispatch(showRfaForAdmission(admission))

    if (reloadingProgress.includes(admission.id)) {
        return (
            <tr><td colSpan="5"><Spinner /></td></tr>
        );
    }

    switch (auth.selectedHospital.hospitalConfig.customLogic) {
        case "CUSTOM_LOGIC_PRIVATE_PRACTICE":
            return (
                <tr>
                    <td className="pointer"><AdmissionList_Item_Checkbox admission={admission}/></td>
                    <td onClick={goToAdmission} style={{cursor: 'pointer'}}>
                        <AdmissionList_Item_Name admission={admission}/>
                    </td>
                    <td onClick={goToAdmission} className="pointer">{admission.admittingDoctor}</td>
                    <td onClick={goToAdmission} className="pointer">{admission.admissionTime}</td>
                    <td onClick={goToAdmission} className="pointer">{privatePracticeAdmissionType()}</td>
                    <td onClick={showAdmissionMessagesModal} className="pointer">{admission.admissionMessages_count_read}/{admission.admissionMessages_count}</td>
                    <td onClick={showAdmissionDocumentsModal} className="pointer">{admission.admissionDocuments_count_read}/{admission.admissionDocuments_count}</td>
                    <td onClick={showAdmissionRfaModal} className="pointer">{admission.rfa_count_read}/{admission.rfa_count}</td>
                    <td>
                        <div className="d-inline-flex align-items-center">
                            <AdmissionList_Item_MoreDrop admission={admission}/>
                        </div>
                    </td>
                </tr>
            )
        case "CUSTOM_LOGIC_LIFEHOUSE":
        case CUSTOM_LOGIC_DEMO:
        case "CUSTOM_LOGIC_JMPH":
        case "CUSTOM_LOGIC_ESPH":
        case "CUSTOM_LOGIC_HEALTHECARE":
        case "CUSTOM_LOGIC_MARYVALE":
        default:
            return (
                <tr>
                    <td className="pointer"><AdmissionList_Item_Checkbox admission={admission}/></td>
                    <AdmissionList_Item_ManagerCustomUI admission={admission} position={1} />
                    <td onClick={goToAdmission} style={{cursor: 'pointer', width: '100%'}}>
                        <AdmissionList_Item_Name admission={admission}/>
                    </td>
                    <AdmissionList_Item_ManagerCustomUI admission={admission} position={2} />
                    {(isCustomLogicMaryvale() || isCustomLogicHealtheCare() || isCustomLogicESPH() || isCustomLogicDemo() || isCustomLogicFSPH() || isCustomLogicJMPH()) &&
                        <td onClick={goToAdmission} className="pointer">{admission.admittingDoctor}</td>}
                    <AdmissionList_Item_ManagerCustomUI admission={admission} position={3} />
                    <td onClick={goToAdmission} className="pointer">{renderTime()}</td>
                    <AdmissionList_Item_ManagerCustomUI admission={admission} position={4} />
                    {(isCustomLogicMaryvale() || isCustomLogicHealtheCare() || isCustomLogicESPH() || isCustomLogicDemo() || isCustomLogicFSPH() || isCustomLogicJMPH()) &&
                        <td onClick={goToAdmission} className="pointer">{admission.insuranceString}</td>}
                    <AdmissionList_Item_ManagerCustomUI admission={admission} position={5} />
                    <LifehouseAdmissionPanel admission={admission}/>
                    {renderOec()}
                    <AdmissionList_Item_ManagerCustomUI admission={admission} position={6} />
                    <td>
                        <div className="d-inline-flex align-items-center">
                            <AdmissionProgress admission={admission}/>
                        </div>
                    </td>
                    <AdmissionList_Item_ManagerCustomUI admission={admission} position={7} />
                    {renderManagerCustomUI()}
                    {renderAdmTime()}
                    {renderAdmDocs()}
                    <td>
                        <div className="d-inline-flex align-items-center">
                            <AdmissionList_Item_Pdf admission={admission}/>
                            <AdmissionList_Item_MoreDrop admission={admission}/>
                        </div>
                    </td>
                </tr>
            )
    }
};

const areEqual = (prevProps, nextProps) => {
    // console.log('[Are Equal?]', JSON.stringify(prevProps) === JSON.stringify(nextProps));
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default AdmissionList_Item;
export const AdmissionList_Item_Memo = React.memo(AdmissionList_Item, areEqual);