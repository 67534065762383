import React from 'react';
import {useSelector} from "react-redux";

const ManagerBanner = () => {
    const auth = useSelector(state => state.auth);

    if (auth && auth.config && auth.config.manager_banner) {
        return <div dangerouslySetInnerHTML={{__html: auth.config.manager_banner }} />
    }
    return null;
}

export default ManagerBanner;