import React from "react";
import {useDispatch} from "react-redux";

import * as actionTypes from "../../../../store/actions/actionTypes";
import usePermissions from "../../../../utils/permissions";

const DeletePatientLink = ({admission}) => {
    const dispatch = useDispatch();
    const {permissions, hasPermission} = usePermissions();

    const showArchiveModal = () => dispatch({type: actionTypes.MODAL_ARCHIVE_PATIENT_SHOW, admission});

    if (!hasPermission(permissions.PERMISSION_LIFEHOUSE_DELETE_PATIENT)) {
        return null;
    }
    return (
        <a className="dropdown-item pointer" onClick={showArchiveModal}>Delete Patient</a>
    );
}

export default DeletePatientLink;