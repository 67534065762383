import React from 'react';
import DischargeList_Tabs from "./Tabs/DischargeList_Tabs";
import AdvancedList_Search from "../AdvancedList/AdvancedList_Search";
import useSortableData from "../../utils/sortableData";
import useAdmissionListNavigation from "../../utils/admissionListNavigation";
import {useSelector} from "react-redux";
import useFilteredData from "../../utils/subfilteredData";
import {DischargeList_Item_Memo} from "./Item/DischargeList_Item";
import DischargeList_SortHeaders from "./DischargeList_SortHeaders";

const DischargeList = () => {
    const {pushAdmissionsListUrl} = useAdmissionListNavigation();
    const {admissions, sortColumn, sortMode, totalPages} = useSelector(state => state.dischargeList);
    const {filteredItems} = useFilteredData(admissions);
    const { items, requestSort, getClassNamesFor } = useSortableData(pushAdmissionsListUrl, filteredItems, {key: sortColumn, direction: sortMode});

    const renderRow = (admission, key) => <DischargeList_Item_Memo key={key} admission={admission}/>

    return (
        <main className="main">
            <div className="container container1072">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h1 className="h2">Discharge List</h1>
                        <div className="table-holder bg-white rounded shadow-sm border">
                            <DischargeList_Tabs />
                            {/*{devIsPagerDisabled && <AdmissionList_SubFilter />}*/}
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel"
                                     aria-labelledby="tab1">
                                    <AdvancedList_Search />
                                    <table className="table procedure-list-table" cellSpacing="0" width="100%">
                                        <DischargeList_SortHeaders _requestSort={requestSort} _getClassNamesFor={getClassNamesFor}/>
                                        <tbody>
                                        {items.map(renderRow)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default DischargeList;