import React from 'react';
import {Field} from "react-final-form";
import AsyncSelectField from "../Field/AsyncSelectField";

const Patient = () => {
    return (
        <div className="form-group">
            <div className="row">
                <div className="col-2 form-title2">
                    Patient
                </div>
                <div className="col-5 zindex100">
                    <Field component={AsyncSelectField}
                           name="patient"
                           placeholder="Select Patient..."
                           isClearable={false}
                           apiPath="/hospital-patient/search/{inputValue}"
                           // options={hospitalOptions()}
                    />
                </div>
            </div>
        </div>
    )
}

export default Patient;