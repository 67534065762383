import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {unsetPdf} from "../../../../store/actions/customUI";

const ManagerCustomUI_UploadPdf = ({admission, managerCustomUi, onHide}) => {
    const dispatch = useDispatch();

    const handleUnset = () => {
        dispatch(unsetPdf(admission, managerCustomUi))
        onHide();
    }
    if (!admission.manager_customUI[managerCustomUi.id] || !admission.manager_customUI[managerCustomUi.id].status) {
        return (
            <a className="dropdown-item disabled pointer">{managerCustomUi.unsetTitle}</a>
        );
    }

    return (
        <>
            <a className="dropdown-item pointer" onClick={handleUnset}>{managerCustomUi.unsetTitle}</a>
        </>
    );
}

export default ManagerCustomUI_UploadPdf;