import React from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../../store/actions/actionTypes";
import Admission_ModalInfo from "../../Block/Admission_ModalInfo";
import PreAdmissionsSet_SelectPreAdmission from "./PreAdmissionsSet_SelectPreAdmission";
import PreAdmissionsSet_UploadPdf from "./PreAdmissionsSet_UploadPdf";


const PreAdmissionsSet = () => {
    const {show, admission, customUI} = useSelector(state => state.modal.customUI_preAdmissionSet);
    const ref = React.useRef(null);
    const dispatch = useDispatch();

    const hideModal = () => dispatch({type: actionTypes.MODAL_CUSTOM_UI_PRE_ADMISSION_SET_HIDE});
    useOnClickOutside(ref, () => hideModal());

    const renderModal = () => {
        if (show) {
            return (
                <div className="modal-dialog modal-content-large" role="document" ref={ref} autoFocus={false}>
                    <div className="modal-content modal-content-fit">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <h3>{admission.title} {admission.surname}, {admission.name}</h3>
                            <Admission_ModalInfo admission={admission} showMobileInfo={false} />
                            <PreAdmissionsSet_SelectPreAdmission admission={admission} customUI={customUI}/>
                            <PreAdmissionsSet_UploadPdf admission={admission} customUI={customUI}/>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {renderModal()}
            </div>
        </>
    );
}

export default PreAdmissionsSet;