import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import * as actionTypes from "../../store/actions/actionTypes";
import {newHospitalPatient} from "../../store/actions/hospitalPatient";
import HospitalPatientForm from "../../components/FinalForm/HospitalPatient/HospitalPatientForm";

const AddHospitalPatientView = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: actionTypes.LOADING_DONE});
    }, []);

    return <HospitalPatientForm title="Add Patient" handleSubmitAction={newHospitalPatient} hospitalSelection={true}/>;
}

export default AddHospitalPatientView;