import React from 'react';
import {useSelector} from "react-redux";
import {Field} from "react-final-form";
import {FilePond, registerPlugin} from "react-filepond";
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePongPluginGetFile from "filepond-plugin-get-file";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePongPluginGetFile);

// TODO: move settings to the separate config, so Uploader component will be reusable

// DO NOT REFACTOR notice
// apiHash, uploaderHandler and files must be provided by parent component.
// when I try to useFormState -  FileUploader is re-rendered each time when I change some other field
// and uploaded files are lost

const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);

const FileUploader = React.memo(({files, uploaderHandle, apiHash}) => {
    console.log('FileUploader render()', files, uploaderHandle, apiHash);
    const auth0Token = useSelector(state => state.auth.idToken);
    const filesConfig = files.map(file => ({
        source: `${process.env.REACT_APP_API_PATH}${file.source}`,
        options: {
            type: "local"
        }
    }));
    // console.log('FileUploader filesConfig', filesConfig);

    const handleRevertFile = async (file) => {
        if (file.serverId) {
            console.log('handleRevertFile', file);
            const request = new XMLHttpRequest();
            request.open('DELETE', `${process.env.REACT_APP_API_PATH}/rfa/file/handle/${file.serverId}/${uploaderHandle}`);
            request.setRequestHeader('Authorization', auth0Token);
            request.send();
        }
    }

    const handleRemoveFile = async (error, file) => {
        console.log('handleRemoveFile', file);
        if (typeof file.source === 'string') {
            const request = new XMLHttpRequest();
            request.open('DELETE', file.source); // ???
            request.setRequestHeader('Authorization', auth0Token);
            request.send();
        }
    };

    const serverConfig = {
        revert: (uniqueFileId, load, error) => {
            console.log('FilePond revert ', uniqueFileId);
            const request = new XMLHttpRequest();
            request.open('DELETE', `${process.env.REACT_APP_API_PATH}/rfa/file/handle/${uniqueFileId}/${uploaderHandle}`);
            request.setRequestHeader('Authorization', auth0Token);
            request.send();

            // Can call the error method if something is wrong, should exit after
            // error('oh my goodness');

            // Should call the load method when done, no parameters required
            load();
        },
        load: async (source, load, error, progress, abort, headers) => {
            const request = new XMLHttpRequest();
            request.open('GET', source)
            request.setRequestHeader('Authorization', auth0Token);
            request.responseType = 'blob';

            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    load(request.response);
                } else {
                    // Can call the error method if something is wrong, should exit after
                    error('oh no');
                }
            };

            request.send();
        },
        process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            // custom headers not working with async function, so I override default process function
            // code is from the link above, Authorization header added
            // https://pqina.nl/filepond/docs/patterns/api/server/#configuration

            // console.log('Process', fieldName, file, metadata, load, error, progress, abort, transfer, options);
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);

            const request = new XMLHttpRequest();
            const apiUrl = apiHash
                ? `${process.env.REACT_APP_API_PATH}/rfa/${apiHash}/file/upload`
                : `${process.env.REACT_APP_API_PATH}/rfa/${uploaderHandle}/file/upload`;
            request.open('POST', apiUrl);
            request.setRequestHeader('Authorization', auth0Token);

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function (response) {
                console.log('process request.onload', response);
                if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load(request.responseText);
                    // files.push(
                    //     {
                    //         "id": request.responseText,
                    //         "source": `/rfa/file/handle/${request.responseText}/${uploaderHandle}`,
                    //         "name": "-"
                    //     }
                    // )
                    // change('files', files);
                } else {
                    // Can call the error method if something is wrong, should exit after
                    error('oh no');
                }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                }
            };
        },
    };

    // const serverConfig = {};

    return (
        <>
            <Field name="uploaderHandle" component="input" type="hidden"/>
            <FilePond
                credits={{}}
                files={filesConfig}
                server={serverConfig}
                stylePanelLayout="compact"
                allowMultiple={true}
                name="fileUpload1"
                onprocessfilerevert={handleRevertFile}
                onremovefile={handleRemoveFile}
                labelIdle='<span className="main-text">Drop your files or <span className="text-primary">browse</span></span>
              '/>
        </>
    );
}, areEqual);

export default FileUploader;