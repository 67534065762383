import React, {useEffect, useState} from 'react';

import * as admissionActions from '../../store/actions/admission';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Admission from "../../components/Admission/Admission";
import * as actionTypes from "../../store/actions/actionTypes";

const AdmissionView = props => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const admission = useSelector(state => state.admission.admission);

    useEffect(() => {
        dispatch({type: actionTypes.MENU_ADMISSIONS_LIST, subAction: true});
        dispatch(admissionActions.load(id));
    }, [id]);
    console.log('[Admission]', id, admission);

    if (admission.id) {
        return <>
                <Admission/>
            </>;
    } else {
        console.error('LOGICAL ERROR - Empty URNumber');
    }
    return null;
}

export default AdmissionView;