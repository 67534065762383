import React from "react";
import {useSelector} from "react-redux";
import NavBar from "./Page/NavBar/NavBar";

const Loading = ({force, showNavBar = false}) => {
    const loading = useSelector(state => state.main.loading);

    if (loading || force) {
        return (
            <>
                {showNavBar && <NavBar />}
                <div className="loader-holder bg-light-gray">
                    <div className="loader">
                        <div className="btn btn-primary btn-loader">
                            <span className="spinner-border spinner-border" role="status" aria-hidden="true"/>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return null;
}

export default Loading;