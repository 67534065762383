import * as actionTypes from './actionTypes';
import axios from 'axios';
import {format, parse} from "date-fns";
import dotProp from 'dot-prop-immutable';

export const admissionListSet = (urlHospitalId, urlDate, urlKeyword, urlGroupType, urlGroupId, urlFilter, urlPage, urlSortColumn, urlSortMode) => {
    return (dispatch, getState) => {
        console.log('[action/admissionsList] admissionListSet', urlHospitalId, urlDate, urlKeyword, urlGroupType, urlGroupId, urlFilter, urlPage, urlSortColumn, urlSortMode);
        dispatch({type: actionTypes.LOADING_START});
        dispatch({type: actionTypes.DEV_SET_ADMISSIONS_LIST_PROFILER_URL, url: false});
        const admissionListDate = getState().admissionList.date ? format(
            getState().admissionList.date,
            'yyyy-MM-dd'
        ) : false;

        const hospitalId = getState().auth.selectedHospital.id;
        const {keyword, groupType, groupId, filter, page, sortColumn, sortMode } = getState().admissionList;

        if (urlHospitalId && urlKeyword &&  ((hospitalId !== parseInt(urlHospitalId)) || (keyword !== urlKeyword))) {
            dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, id: urlHospitalId,});
            dispatch({type: actionTypes.ADMISSIONS_LIST_SET_KEYWORD, keyword: urlKeyword })
        } else if (urlHospitalId && urlDate &&  ((hospitalId !== parseInt(urlHospitalId)) || (admissionListDate !== urlDate))) {
            console.log('[action/admissionsList] dispatching hospitalId / date from url', hospitalId, urlHospitalId, admissionListDate, urlDate);
            dispatch({type: actionTypes.ADMISSIONS_LIST_SET_DATE, date: parse(urlDate, 'yyyy-MM-dd', new Date())})
            dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, id: urlHospitalId,})
        } else if (urlHospitalId && urlGroupType && urlGroupId && ((hospitalId !== parseInt(urlHospitalId)) || (groupId !== urlGroupId) || (groupType !== urlGroupType) || (filter !== urlFilter) || (page !== urlPage) || (sortColumn !== urlSortColumn) || (sortMode !== urlSortMode))) {
            console.log('[action/admissionsList] dispatching groupId / groupType / filter / page from url', hospitalId, groupId, urlGroupId, groupType, urlGroupType, (hospitalId !== parseInt(urlHospitalId)), (groupId !== urlGroupId), (groupType !== urlGroupType), (filter !== urlFilter), (page !== urlPage), (sortColumn !== urlSortColumn), (sortMode !== urlSortMode));
            dispatch({type: actionTypes.ADMISSIONS_LIST_SET_GROUP, groupType: urlGroupType, groupId: urlGroupId, filter: urlFilter, page: urlPage, sortMode: urlSortMode, sortColumn: urlSortColumn});
            dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, id: urlHospitalId,})
        } else {
            dispatch(admissionListReload());
        }
    }
};

export const admissionListReload = () => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});
        dispatch({type: actionTypes.DEV_SET_ADMISSIONS_LIST_PROFILER_URL, url: false});
        const admissionListDate = getState().admissionList.date ? format(
            getState().admissionList.date,
            'yyyy-MM-dd'
        ) : false;

        const idToken = getState().auth.idToken;
        const hospitalId = getState().auth.selectedHospital.id;
        const {keyword, groupType, groupId, filter, page, sortColumn, sortMode } = getState().admissionList;

        console.log('[action/admissionsList] admissionListReload', hospitalId, admissionListDate);

        let apiEndpoint = '';
        if (groupType && groupId) {
            apiEndpoint = `/admissions/${hospitalId}/group/${groupType}/${groupId}/${sortColumn}/${sortMode}/${page}/${filter}`;
        } else if (keyword) {
            apiEndpoint = `/admissions/${hospitalId}/search/${keyword}`;
        } else {
            apiEndpoint = `/admissions/${hospitalId}/${admissionListDate}`;
        }
        const startedIn = Date.now();
        axios.get(
            apiEndpoint,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/admissionsList] done in (ms)',Date.now()-startedIn, response.data);
                if (dotProp.get(response.headers, 'x-execution-time', false)) {
                    console.log('[action/admissionsList] x-execution-time', dotProp.get(response.headers, 'x-execution-time'));
                }
                if (dotProp.get(response.headers, 'x-debug-token-link', false)) {
                    dispatch({type: actionTypes.DEV_SET_ADMISSIONS_LIST_PROFILER_URL, url: dotProp.get(response.headers, 'x-debug-token-link')});
                }
                dispatch({type: actionTypes.ADMISSIONS_LIST_SET, ...response.data})
                dispatch({type: actionTypes.LOADING_DONE});
            })
        ;
    }
};