import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";

const ModalAdmissionMessages = () => {
    const {show, admission, admissionMessages} = useSelector(state => state.modal.admissionMessages);
    const showViewMessageModal = useSelector(state => state.modal.viewAdmissionMessage.show);
    const showSendMessageModal = useSelector(state => state.modal.sendMessage.show);
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => {
        if (!showViewMessageModal && !showSendMessageModal) {
            hideModal()
        }
    });

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADMISSION_MESSAGES_HIDE});
    const sendNewMessage = () => {
        dispatch({type: actionTypes.MODAL_SEND_MESSAGE_SHOW, admission});
    }

    const viewAdmissionMessage = (message) => dispatch({type: actionTypes.MODAL_VIEW_ADMISSION_MESSAGE_SHOW, message});

    const renderItems = (item, key) => {
        return (
            <tr key={key}>
                <td>{item.title}</td>
                <td>{item.send_at}</td>
                <td>-</td>
                <td>{renderStatus(item)}</td>
                <td>
                    <a href="#" onClick={() => viewAdmissionMessage(item)} className="btn btn-sm2 btn-primary">View</a>
                </td>
            </tr>
        );
    }

    const renderStatus = (admissionMessage) => {
        switch (admissionMessage.status) {
            default:
                return 'Unknown status '+admissionMessage.status;
            case "STATUS_SEND":
                return 'Not viewed';
            case "STATUS_DONE":
                return 'Seen on ' + admissionMessage.opened_at;
            case "STATUS_DELETED":
                return 'Deleted';
        }
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content modal-content-large">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>Messages for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                    </dl>
                    <table className="table" cellSpacing="0" width="100%">
                        <thead>
                        <tr>
                            <th><strong className="title">Title</strong></th>
                            <th><strong className="title">Sent Date</strong></th>
                            <th><strong className="title">Expires</strong></th>
                            <th><strong className="title">Status</strong></th>
                            <th><strong className="title">&nbsp;</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {admissionMessages.map(renderItems)}

                        </tbody>
                    </table>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <input className="btn btn-primary" type="button" value="Send new message" onClick={sendNewMessage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="documentStatusModal" tabIndex="-1">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalAdmissionMessages;