import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useToasts} from "react-toast-notifications";

import loadingGif from '../../../../assets/images/loader.gif';
import * as actionTypes from "../../../../store/actions/main";
import {webPasTerm} from "../../../CustomLogic/Terms";

const WebPasSyncLink = ({admission}) => {
    const {addToast} = useToasts();
    const dispatch = useDispatch();
    const {webPasSyncIds} = useSelector(state => state.main);
    const selectedHospital = useSelector(state => state.auth.selectedHospital);

    const doSync = () => dispatch(actionTypes.webPasSync(admission, addToast));

    if(webPasSyncIds.indexOf(admission.id) > -1) {
        return (
            <a className="dropdown-item disabled pointer"><img src={loadingGif} alt="loading"/>{webPasTerm(selectedHospital)}  Sync</a>
        );
    } else {
        return (
            <a className="dropdown-item pointer" onClick={doSync}>{webPasTerm(selectedHospital)}  Sync</a>
        );
    }
}

export default WebPasSyncLink;