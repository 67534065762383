import React from "react";
import {useSelector, useDispatch} from "react-redux";

import * as actionTypes from "../../../../store/actions/actionTypes";
import {useCustomLogic} from "../../../../utils/customLogic";
import usePermissions from "../../../../utils/permissions";

const ManagerCustomUI_SendRegMed = ({admission, managerCustomUi, onHide}) => {
    const dispatch = useDispatch();
    const {permissions, hasPermission} = usePermissions();

    const status = admission.manager_customUI[managerCustomUi.id];

    // const canUpload = status.sent && ((status.reg.status !== 'STATUS_COMPLETED') || (status.med.status !== 'STATUS_COMPLETED'));
    //
    // if (!canUpload) {
    //     console.log('cant upload ', status);
    //     return null;
    // }

    const uploadPdf = () => {
        onHide();
        dispatch({type: actionTypes.MODAL_ADMISSION_UPLOAD_PDF_SHOW, admission: admission});
    }

    return (
        <a className="dropdown-item pointer" onClick={uploadPdf}>Upload Patient Reg & Med History</a>
    );
}

export default ManagerCustomUI_SendRegMed;