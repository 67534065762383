import React from "react";
import {useSelector} from "react-redux";
import {CUSTOM_LOGIC_DEMO} from "../../utils/customLogic";

const UrDt = ({admission}) => {
    const hospital = useSelector(state => state.auth.selectedHospital);

    switch (hospital.hospitalConfig.customLogic) {
        case CUSTOM_LOGIC_DEMO:
        case "CUSTOM_LOGIC_LIFEHOUSE":
            return (
                <>
                    <dt>MRN</dt>
                    <dd>L{admission.URNumber}</dd>
                </>
            );
        case "CUSTOM_LOGIC_ESPH":
            return (
                <>
                    <dt>MRN.</dt>
                    <dd>{admission.URNumber}</dd>
                </>
            );
        case "CUSTOM_LOGIC_HEALTHECARE":
        case "CUSTOM_LOGIC_MARYVALE":
        default:
            return (
                    <>
                        <dt>UR.</dt>
                        <dd>{admission.URNumber}</dd>
                    </>
                );
    }
}

export default UrDt;