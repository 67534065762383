import React from 'react';

import logo from '../../../assets/images/logo.svg';
import NavBar_MainMenu from "./NavBar_MainMenu";
import NavBar_UserMenu from "./NavBar_UserMenu";
import NavBar_HospitalMenu from "./NavBar_HospitalMenu";
import NavBar_Add from "./NavBar_Add";

const NavBar = () => {
    return (
        <header className="header bg-white shadow-sm border-bottom">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-7 d-flex align-items-center">
                        <div className="logo">
                            <a href="#">
                                <img src={logo} alt="admission" />
                                <span className="txt">Admission.com.au</span>
                            </a>
                        </div>
                        <NavBar_MainMenu />
                    </div>
                    <div className="col-5 d-flex justify-content-end">
                        <NavBar_Add/>
                        {/*<form className="search-form" onSubmit={event => event.preventDefault()}>*/}
                        {/*    <div className="input-row">*/}
                        {/*        <i className="icon ico-zoom" />*/}
                        {/*        <input type="search" className="form-control" placeholder="Search..." />*/}
                        {/*    </div>*/}
                        {/*</form>*/}

                        <NavBar_HospitalMenu />
                        <NavBar_UserMenu />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default NavBar;