import React from "react";
import DatePicker from "react-datepicker";
import {format,parse} from "date-fns";

const DateField = (props) => {
    const {
        input: { value, onChange }
    } = props;

    const handleChange = (value) => {
        console.log('[DateField]', value)
        onChange(format(value, 'dd/MM/yyyy'));
    }

    const convertValue = () => {
        if (value) {
            return parse(value, 'dd/MM/yyyy', new Date());
        }
        return value;
    }

    return <DatePicker
        dateFormat="dd/MM/yyyy"
        // showYearDropdown
        // showMonthDropdown
        selected={convertValue()}
        onChange={handleChange}
        className="form-control"
    />;
}

export default DateField;