import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useToasts} from "react-toast-notifications";

import * as actionTypes from "../../store/actions/actionTypes";
import * as lifehouseActions from "../../store/actions/lifehouse";

const DevApiView = () => {
    const {addToast} = useToasts();
    const dispatch = useDispatch();

    const doApiCall = () => dispatch(lifehouseActions.selectClinicalTeam({id: 76}, 'PSN', addToast));

    useEffect(() => {
        dispatch({type: actionTypes.LOADING_DONE});
    }, [])
    return (
        <>
            <h2>Dev API</h2>
            <button onClick={doApiCall}>API call</button>
        </>
    )
}

export default DevApiView;