import React from "react";
import {useSelector} from "react-redux";

const ModalBackground = () => {
    const show = useSelector(state => state.modal.modalShowed);

    if (show) {
        return (
            <div className="modal-backdrop fade show" />
        );
    }
    return null;
}

export default ModalBackground;