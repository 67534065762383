import React from "react";
import {useSelector} from "react-redux";

const Admission_Pdf = ({admission}) => {
    const idToken = useSelector(state => state.auth.idToken);
    const ref = React.useRef(null);

    const downloadPdf = () => {
        ref.current.submit();
    }

    if (admission.haveDocuments) {
        return (
            <button className="btn btn-sm btn-white-primary" onClick={downloadPdf}>
                <i className="icon ico-document-with-conner"/>
                <span>Patient Ad Docs</span>
                {admission.isStatusPP_documentsMerged &&
                <div className="icon-check text-success" style={{marginLeft: '5px'}}>
                    <i className="icon ico-c-check"/>
                </div>
                }
                <div style={{display: 'none'}}>
                    <form action={`${process.env.REACT_APP_API_PATH}/admission/${admission.id}/pdf`} method="post"
                          ref={ref}>
                        <input type="hidden" name="token" value={idToken}/>
                    </form>
                </div>
            </button>
        );
    } else {
        return (
            <button className="btn btn-sm btn-white-primary disabled" disabled={true}>
                <i className="icon ico-document-with-conner"/>
                <span>Patient Ad Docs</span>
            </button>
        );
    }
}

export default Admission_Pdf;