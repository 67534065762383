import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../../store/actions/actionTypes";
import {pdfUploadApprove} from "../../../store/actions/modal";
import Admission_ModalInfo from "../../Block/Admission_ModalInfo";
import FileUploader from "./FileUploader";
import Spinner from "../../Spinner";
import {useToasts} from "react-toast-notifications";


const ModalAdmissionUploadPdf = () => {
    const {show, admission, file, approving} = useSelector(state => state.modal.admissionUploadPdf);
    const ref = React.useRef(null);
    const dispatch = useDispatch();
    const {addToast} = useToasts();

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADMISSION_UPLOAD_PDF_HIDE});
    useOnClickOutside(ref, () => hideModal());

    const doApprove = () => dispatch(pdfUploadApprove(admission, addToast));

    const renderModal = () => {
        if (show) {
            return (
                <div className="modal-dialog modal-content-large" role="document" ref={ref} autoFocus={false}>
                    <div className="modal-content modal-content-fit">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <h3>Upload paper docs for {admission.title} {admission.surname}, {admission.name}</h3>
                            <Admission_ModalInfo admission={admission} showMobileInfo={false} />
                            <form className="person-info-form">
                                {approving ? <Spinner /> : <FileUploader api={`${process.env.REACT_APP_API_PATH}/admission/${admission.id}/upload-pdf`}/>}
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <input type="button" value="Cancel" className="btn" onClick={hideModal}/>
                                            <input type="button" value="Approve & Upload" className="btn btn-primary" disabled={!file || approving} onClick={doApprove}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {renderModal()}
            </div>
        </>
    );
}

export default ModalAdmissionUploadPdf;