import React, {useRef} from "react";
import ReactToPrint from 'react-to-print';
import XMLViewer from 'react-xml-viewer'
import JSONPretty from 'react-json-pretty';

import CollapsableString from "../CollapsableString";
import FundAssessmentCode from "../FundAssessmentCode";
import ModalOecStatus_ClaimDevInfo from "./ModalOecStatus_ClaimDevInfo";
import {presentingIllnessCodeDescription} from "../../utils/presentingIllnessCodes";

class OecData extends React.Component {
    render() {
        const {claim, admission} = this.props;
        return (
            <div className="row">
                <div className="col-12 oec-description">
                    <div className="bg-white rounded shadow-sm mb-4">
                        <h1>ONLINE ELIGIBILITY CHECK (OEC) REPORT</h1>
                        <h2>Quote only not for claiming</h2>
                        <table className="table table-hover table-sm table-oec">
                            <tbody>
                            <tr>
                                <td>
                                    Location Id<br />
                                    Account Reference Id:<br />
                                </td>
                                <td>
                                    {claim.oec_response_vars.request.claim.facilityId}<br />
                                    {claim.oec_response_vars.request.claim.accountReferenceId}<br />
                                </td>
                                <td>
                                    Fund Explanation Code:<br />
                                    Fund Explanation Text:<br />
                                </td>
                                <td>
                                    - <br/>
                                    -
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Patient name<br />
                                    Admission No<br />
                                    Health Fund<br />
                                    Member Number <br/>
                                </td>
                                <td>
                                    {claim.oec_response_vars.request.claim.patient.identity.familyName},
                                    {claim.oec_response_vars.request.claim.patient.identity.givenName}<br />
                                    {claim.oec_response_vars.request.claim.accountReferenceId}<br />
                                    {claim.oec_response_vars.request.claim.patient.healthFund.organisation}<br />
                                    {claim.oec_response_vars.request.claim.patient.healthFund.memberNumber}
                                </td>
                                <td>UR no<br />
                                    DOB<br />
                                    Gender<br/>
                                    Member Ref Number
                                </td>
                                <td>
                                    {admission.URNumber}<br />
                                    {claim.oec_response_vars.request.claim.patient.identity.dateOfBirth}<br />
                                    Male<br/>
                                    {claim.oec_response_vars.request.claim.patient.healthFund.memberRefNumber}
                                </td>
                            </tr>
                            <tr>
                                <td className="white-space-no-wrap">Health Fund Table</td>
                                <td colSpan={3}>{claim.oec_response_vars.tableName}</td>
                            </tr>
                            <tr>
                                <td>Financial status<br />
                                    Fund ref ID<br />
                                    Intended stay
                                </td>
                                <td>{claim.oec_response_vars.financialStatus}<br />
                                    {claim.oec_response_vars.fundReferenceId}<br />
                                    &nbsp;
                                </td>
                                <td>Subject to PEA<br />
                                    Table scale<br />
                                    Planned LOS
                                </td>
                                <td>{claim.oec_response_vars.peaPotentialInd}<br />
                                    {claim.oec_response_vars.tableScale}<br />
                                    0
                                </td>
                            </tr>
                            <tr>
                                <td className="white-space-no-wrap">Presenting Illness Code<br />
                                    Diagnosis
                                </td>
                                <td colSpan={3}>
                                    {claim.oec_response_vars.request.claim.presentingIllnessCode}
                                    <br />
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Request Date<br />
                                    Trans ID
                                </td>
                                <td>
                                    {claim.oec_response_vars.requestDate}<br/>
                                    {claim.oec_response_vars.transactionId}
                                </td>
                                <td>Assess Code</td>
                                <td><FundAssessmentCode fundAssessmentCode={claim.statusStr}/><br />

                                </td>
                            </tr>
                            <tr>
                                <td>CoPayment Amount<br />
                                    Excess Amount
                                </td>
                                <td>{claim.oec_response_vars.patientCoPayment_amountStr || '-'}<br />
                                    {claim.oec_response_vars.patientExcessPayment_amountStr || '-'}
                                </td>
                                <td>CoPayment Days Remaining<br />
                                    Excess Bonus Amount
                                </td>
                                <td>
                                    {claim.oec_response_vars.patientCoPayment_daysRemaining || '-'}<br />
                                    {claim.oec_response_vars.patientExcessPayment_bonusAmountStr || '-'}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <h6 className="h6">Table Description</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.tableDescription || '-'}</p>
                    <h6 className="h6">Benefit Limitations</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.benefitLimitations || '-'}</p>
                    <h6 className="h6">Co-payment Description</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.patientCoPayment_amountDescription || '-'}</p>
                    <h6 className="h6">Excess Amount Description</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.patientExcessPayment_amountDescription || '-'}</p>
                    <h6 className="h6">Exclusion Description</h6>
                    <p>{claim.oec_response_vars && claim.oec_response_vars.exclusionDescription || '-'}</p>
                    <h6 className="h6">Disclaimer and Privacy Notice</h6>
                    This Online Eligibility Check (OEC) information is provided under the following conditions.

                    <h6 className="h6">Disclaimer</h6>
                    <ul>
                    <li>This response is based on information supplied by you and on the
                    conditions applicable to you and your membership of your health fund at
                    the proposed admission date, as known at the date this OEC was
                        performed.</li>
                    <li>This response is not an undertaking by Services Australia or the health
                        fund to pay any claim.</li>
                    <li>Services Australia have the right to decline a future claim based on your
                        eligibility at the time that future claim is made.</li>
                    <li>The health fund may decline a future claim based on the conditions
                    applicable at the time that future claim is made, including: pre-existing
                    ailments; waiting periods not being served; product exclusions; accident
                    or where compensation and/or damages may be claimed from another
                        source; and cancelled, suspended or non-financial memberships.</li>
                    </ul>
                    <h6 className="h6">Privacy Notice</h6>
                    <p>Your personal information is protected by law, including the <i>Privacy Act 1988</i>, and is collected by Services Australia for the assessment and
                    administration of payments and services. This information is required to process
                    your application or claim.</p>

                    <p>Your information may be used by the agency or given to other parties for the
                    purposes of research, investigation or where you have agreed or it is required or
                        authorised by law.</p>
                    <p>You can get more information about the way in which Services Australia will
                    manage your personal information, including our privacy policy at
                        <b>servicesaustralia.gov.au/privacy</b> or by requesting a copy from the agency.</p>
                    <p>The information provided or received will be used to determine your
                    Medicare eligibility and may be accessed by Services Australia, your
                    provider and health fund. Your information will not be provided to any other
                    third parties unless required or authorised by law. This information may be
                        used to facilitate patient Informed Financial Consent (IFC).</p>

                </div>
            </div>
        );
    }
}

const ModalOecStatus_Claim = ({claim, admission}) => {
    const OecDataRef = useRef();

    const renderDone = () => {
        return (
            <div className="col-9">
                <div className="main-content">

                    <div className="row">
                        <div className="col-2">
                            <ReactToPrint
                                bodyClass="oec-print"
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return <button className="btn btn-white btn-time-opener">
                                            <span className="icon-calendar"><svg width="1em" height="1em"
                                                                                 viewBox="0 0 16 16"
                                                                                 className="bi bi-printer"
                                                                                 fill="currentColor"
                                                                                 xmlns="http://www.w3.org/2000/svg">
  <path
      d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"/>
  <path fillRule="evenodd"
        d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"/>
  <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                            </svg></span>
                                        <span>Print</span>
                                    </button>;
                                }}
                                content={() => OecDataRef.current}
                            />

                        </div>
                        <div className="col-10">
                            <h2 className="h4">OEC Response Report</h2>
                        </div>

                    </div>
                    <OecData admission={admission} claim={claim} ref={OecDataRef}/>
                    {renderDevInfo()}
                </div>
            </div>
        )
    }

    const renderDevInfo = () => <ModalOecStatus_ClaimDevInfo claim={claim}/>

    const renderMessage = (msg) => {
        return (
            <div className="col-9">
                <div className="main-content">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="h4">OEC Response Report</h2>
                            <p>{msg}</p>
                        </div>
                    </div>
                    {renderDevInfo()}
                </div>
            </div>
        )
    }

    switch (claim.statusStr) {
        case "STATUS_NEW":
            return renderMessage('Eligibility check in progress - awaiting response from Services Australia');
        case "STATUS_PROCESSING":
            return renderMessage('Eligibility check in progress - awaiting response from Services Australia');
        case "STATUS_ERROR":
            return renderMessage(claim.error_description);
        case "STATUS_DONE":
        case "STATUS_DONE_ACCEPTED":
        case "STATUS_DONE_REJECTED":
        case "STATUS_DONE_WARNING":
            return renderDone();
    }

}

export default ModalOecStatus_Claim;