import React, {useRef, useState} from "react";
import useOnClickOutside from "use-onclickoutside";
import {useSelector} from "react-redux";

import ResendSmsLink from "../AdmissionsList/Item/MoreDrop/ResendSmsLink";
import CloneToDemoLink from "../AdmissionsList/Item/MoreDrop/CloneToDemoLink";
import CloneToTestLink from "../AdmissionsList/Item/MoreDrop/CloneToTestLink";
import ArchiveAdmissionDocumentsLink from "../AdmissionsList/Item/MoreDrop/ArchiveAdmissionDocumentsLink";
import CopyAdmissionData from "../AdmissionsList/Item/MoreDrop/CopyAdmissionData";
import ManagerCustomUI from "./../AdmissionsList/Item/MoreDrop/ManagerCustomUI";

const Admission_MoreDrop = ({admission}) => {
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    const popupRef = useRef(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const hidePopup = () => setPopupOpen(false);
    useOnClickOutside(popupRef, hidePopup);

    const togglePopup = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setPopupOpen(!popupOpen);
    }

    const renderDevUI = () => (
                <>
                    <div className="dropdown-divider" />
                    <CopyAdmissionData admission={admission} />
                    <div className="dropdown-divider" />
                    <CloneToDemoLink admission={admission}/>
                    <CloneToTestLink admission={admission}/>
                </>
            );

    return (
        <div className="more-options-drop btn" ref={popupRef}>
            <button type="button" className="dropdown-toggle dropdown-toggle-split" onClick={togglePopup}
                    id={`adm_dropdownMenuReference_${admission.id}`} data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" data-reference="parent" >
                <span><i className="icon ico-more" /></span>
            </button>
            {popupOpen && <div className="dropdown-menu dropdown-menu-right show"
                 aria-labelledby={`adm_dropdownMenuReference_${admission.id}`}>
                <ManagerCustomUI admission={admission} onHide={hidePopup}/>
                <ResendSmsLink admission={admission}/>
                <ArchiveAdmissionDocumentsLink admission={admission} onHide={hidePopup}/>
                {state_showDevUI && renderDevUI()}
            </div>}
        </div>
    );
}

export default Admission_MoreDrop;