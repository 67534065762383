import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useToasts} from "react-toast-notifications";

import loadingGif from '../../assets/images/loader.gif';
import * as actionTypes from "../../store/actions/main";
import {demoResetAdmission} from "../../store/actions/main";

const DemoResetAdmission = ({admission}) => {
    const {addToast} = useToasts();
    const dispatch = useDispatch();
    const {generateApiIfcDataIds} = useSelector(state => state.main);
    const {demo, selectedHospital} = useSelector(state => state.auth);

    const doResetAdmission = () => dispatch(actionTypes.demoResetAdmission(admission, addToast));

    if(!demo.demoHospital || (demo.demoHospital.id !== selectedHospital.id)) {
        return null;
    }

    if(generateApiIfcDataIds.indexOf(admission.id) > -1) {
        return (
            <a className="dropdown-item disabled pointer"><img src={loadingGif} alt="loading"/>[DEMO] Reset admission</a>
        );
    } else {
        return (
            <a className="dropdown-item pointer" onClick={doResetAdmission}>[DEMO] Reset admission</a>
        );
    }
}

export default DemoResetAdmission;