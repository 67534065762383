import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import AdmissionList from "../../components/AdmissionsList/AdmissionList";
import * as actionTypes from "../../store/actions/actionTypes";
import {admissionListSet} from "../../store/actions/admissionList";

const AdmissionsListView = () => {
    const dispatch = useDispatch();
    const admissionListVars = useSelector(state => state.admissionList);
    const selectedHospital = useSelector(state => state.auth.selectedHospital);
    const params = useParams();
    const {hospitalId, date, keyword, sortColumn, sortMode, groupType, groupId, filter, page} = params;
    // console.log('[AdmissionsListView] params', params, admissionListVars);

    useEffect(() => {
        // console.log('[AdmissionsListView] useEffect()');
        dispatch({type: actionTypes.MENU_ADMISSIONS_LIST});
        dispatch(admissionListSet(hospitalId, date, keyword, groupType, groupId,filter, page, sortColumn, sortMode));
    }, [admissionListVars.date, selectedHospital.id, admissionListVars.keyword, admissionListVars.groupType, admissionListVars.groupId, admissionListVars.filter, admissionListVars.page, admissionListVars.sortColumn, admissionListVars.sortMode]);

    return (
        <>
            <AdmissionList/>
        </>
    );
}

export default AdmissionsListView;