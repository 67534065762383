import React from "react";
import {useSelector, useDispatch} from "react-redux";

import * as actionTypes from "../../../../store/actions/actionTypes";
import {useCustomLogic} from "../../../../utils/customLogic";
import usePermissions from "../../../../utils/permissions";

const LifehouseUploadPdfLink = ({admission}) => {
    const {manager_uploadPdf_enable} = useSelector(state => state.auth.selectedHospital);
    const dispatch = useDispatch();
    const {isCustomLogicLifehouse} = useCustomLogic();
    const {permissions, hasPermission} = usePermissions();

    const modalArchive = () => dispatch({type: actionTypes.MODAL_ARCHIVE_CUSTOM_PDF_SHOW, admission: admission});

    if (!isCustomLogicLifehouse()) {
        return null;
    }
    if (admission.lifehouse_canArchiveMedAdmPdf && admission.lifehouse_admPdfUpload && hasPermission(permissions.PERMISSION_LIFEHOUSE_ARCHIVE_PRE_AD)) {
        return null;
        // deprecated, it can be done during default archive
        // return (
        //     <a className="dropdown-item pointer" onClick={modalArchive}>Archive Patient Reg & Med History</a>
        // );
    }
    if (!admission.lifehouse_canUploadMedAdmPdf) {
        return (
            <a className="dropdown-item pointer disabled">Upload Patient Reg & Med History</a>
        );
    }


    const uploadPdf = () => {
        dispatch({type: actionTypes.MODAL_ADMISSION_UPLOAD_PDF_SHOW, admission: admission});
    }

    if(manager_uploadPdf_enable) {
        return (
            <a className="dropdown-item pointer" onClick={uploadPdf}>Upload Patient Reg & Med History</a>
        );
    }

    return null;
}

export default LifehouseUploadPdfLink;