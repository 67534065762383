import React from "react";

const Loading = ({apiError, apiUrl, email}) => {
    const date = new Date();
    if (apiError) {
        return (
            <div className="row">
                <div className="col-4">
                    <div className="alert alert-danger m-4">API Error - {apiUrl} <br />{email}<br/><br />{apiError}<br />{date.toUTCString()}</div>
                </div>
            </div>
        );
    }
    return null;
}

export default Loading;