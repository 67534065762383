import React from 'react';
import {useSelector} from "react-redux";

const ManagerCustomUI_DownloadPdf = ({admission, managerCustomUi, onHide}) => {
    const idToken = useSelector(state => state.auth.idToken);
    const ref = React.useRef(null);

    const downloadLabel = managerCustomUi.downloadLabel ? managerCustomUi.downloadLabel : `Download ${managerCustomUi.documentTypes.join(',')}`;

    const handleDownload = (event) => {
        event.preventDefault();
        event.stopPropagation();
        ref.current.submit();
        onHide();
    }

    if (!admission.manager_customUI[managerCustomUi.id] || !admission.manager_customUI[managerCustomUi.id].status) {
        return (
            <a className="dropdown-item disabled pointer">{downloadLabel}</a>
        );
    }

    return (
        <>
            <a className="dropdown-item pointer" onClick={handleDownload}>{downloadLabel}</a>
            <div className="display-none">
                <form action={`${process.env.REACT_APP_API_PATH}/admission/${admission.id}/pdf/customUIDownloadPDF/${managerCustomUi.id}`} method="post"
                      ref={ref}>
                    <input type="hidden" name="token" value={idToken}/>
                </form>
            </div>
        </>
    );
}

export default ManagerCustomUI_DownloadPdf;