import {useSelector} from "react-redux";

export const CUSTOM_LOGIC_LIFEHOUSE = "CUSTOM_LOGIC_LIFEHOUSE";
export const CUSTOM_LOGIC_DEMO = "CUSTOM_LOGIC_DEMO";
export const CUSTOM_LOGIC_HEALTHECARE = "CUSTOM_LOGIC_HEALTHECARE";
export const CUSTOM_LOGIC_ESPH = "CUSTOM_LOGIC_ESPH";
export const CUSTOM_LOGIC_FSPH = "CUSTOM_LOGIC_FSPH";
export const CUSTOM_LOGIC_PRIVATE_PRACTICE = "CUSTOM_LOGIC_PRIVATE_PRACTICE";
export const CUSTOM_LOGIC_MARYVALE = "CUSTOM_LOGIC_MARYVALE";
export const CUSTOM_LOGIC_JMPH = "CUSTOM_LOGIC_JMPH";

export const useCustomLogic = () => {
    const {selectedHospital} = useSelector(state => state.auth);

    const selectedHospitalCustomLogic = () => selectedHospital.hospitalConfig.customLogic;
    const isCustomLogicLifehouse = () => selectedHospitalCustomLogic() === CUSTOM_LOGIC_LIFEHOUSE;
    const isCustomLogicHealtheCare = () => selectedHospitalCustomLogic() === CUSTOM_LOGIC_HEALTHECARE;
    const isCustomLogicESPH = () => selectedHospitalCustomLogic() === CUSTOM_LOGIC_ESPH;
    const isCustomLogicFSPH = () => selectedHospitalCustomLogic() === CUSTOM_LOGIC_FSPH;
    const isCustomLogicDemo = () => selectedHospitalCustomLogic() === CUSTOM_LOGIC_DEMO;
    const isCustomLogicPrivatePractice = () => selectedHospitalCustomLogic() === CUSTOM_LOGIC_PRIVATE_PRACTICE;
    const isCustomLogicMaryvale = () => selectedHospitalCustomLogic() === CUSTOM_LOGIC_MARYVALE;
    const isCustomLogicJMPH = () => selectedHospitalCustomLogic() === CUSTOM_LOGIC_JMPH;
    return {isCustomLogicLifehouse, isCustomLogicHealtheCare, isCustomLogicESPH, isCustomLogicPrivatePractice, isCustomLogicDemo, isCustomLogicMaryvale, isCustomLogicFSPH, isCustomLogicJMPH}
}