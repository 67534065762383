import React from "react";
import {useSelector} from "react-redux";
import {CUSTOM_LOGIC_DEMO} from "../../utils/customLogic";

const AdmDt = ({admission}) => {
    const hospital = useSelector(state => state.auth.selectedHospital);

    switch (hospital.hospitalConfig.customLogic) {
        case CUSTOM_LOGIC_DEMO:
        case "CUSTOM_LOGIC_LIFEHOUSE":
            if ((admission.ADMNumber === "0") || (admission.ADMNumber === 0)) {
                return null;
            }

            return (
                <>
                    <dt>Account Number</dt>
                    <dd>A{admission.ADMNumber}</dd>
                </>
            );
        case "CUSTOM_LOGIC_ESPH":
            return (
                <>
                    <dt>Episode No</dt>
                    <dd>{admission.ADMNumber}</dd>
                </>
            );
        case "CUSTOM_LOGIC_HEALTHECARE":
        case "CUSTOM_LOGIC_MARYVALE":
        default:
            return (
                    <>
                        <dt>Adm.</dt>
                        <dd>{admission.ADMNumber}</dd>
                    </>
                );
    }
}

export default AdmDt;