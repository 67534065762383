import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import loadingGif from "../../assets/images/loader.gif";

const AdvancedList_Item_CustomColumn = ({admission, managerCustomUi}) => {
    const dispatch = useDispatch();

    const reloadingCustomColumn = useSelector(state => state.main.reloadingCustomColumn);
    if (reloadingCustomColumn.includes(admission.id)) {
        return <div className="d-flex flex-nowrap align-items-center">
            <button className={`btn btn-sm`}><img src={loadingGif} alt="Loading"/></button>
        </div>;
    }

    // TODO: ACA-295 -  REFACTOR useManagerCustomUI() + send customUi as property?
    const customUIStatus = admission.manager_customUI[managerCustomUi.id];
    // console.log('AdvancedList_Item_CustomColumn', admission, managerCustomUi, customUIStatus);
    // ==========================================================================

    const showModalSend = (event) => {
        dispatch({
            type: actionTypes.MODAL_SEND_DOCUMENT_SET_COLUMN_SHOW,
            admission: admission,
            managerCustomUi: managerCustomUi
        });
        event.preventDefault();
        event.stopPropagation();
    }
    const showModalViewDocument = (event) => {
        dispatch({
            type: actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_SHOW,
            admissionDocument: actionTypes.ADMISSION_DOCUMENT_MANAGER_CUSTOM_UI,
            admission: admission,
            customUIStatus: customUIStatus,
            managerCustomUi: managerCustomUi
        });

        event.preventDefault();
        event.stopPropagation();
    }
    if (!customUIStatus) {
        return null;
    }

    if (customUIStatus.status === 'NONE') {
        return (
            <div className="d-flex flex-nowrap align-items-center" onClick={showModalSend}>
                {/*// TODO: ACA-295 - pass manager customUI as property instead*/}
                <button className="btn btn-sm btn-secondary" onClick={showModalSend}>{managerCustomUi.sendBtnTitle}</button>
                {/*// ====================================================================================*/}
            </div>
        );
    }
    console.log('[AdmissionList_Item_CustomColumn]', customUIStatus);

    return (
        <div className="d-flex flex-nowrap align-items-center" onClick={showModalViewDocument}>
            <button className={`btn btn-sm ${customUIStatus.buttonStyle}`} onClick={showModalViewDocument}>{customUIStatus.buttonText}</button>
        </div>
    );
}

export default AdvancedList_Item_CustomColumn;