import React from "react";
import JSONPretty from 'react-json-pretty';
import {useFormState} from "react-final-form";

const RfaForm_Values = () => {
    const form = useFormState();
    return (
        <div className="form-group">
            <div className="row">
                <div className="col-12">
                    <JSONPretty data={form.values} />
                </div>
            </div>
        </div>
    );
}

export default RfaForm_Values;