import React, {useEffect, useState} from "react";
import useOnClickOutside from "use-onclickoutside";
import {useSelector} from "react-redux";

import NavBar_HospitalSelector from "./NavBar_HospitalSelector";

const NavBar_HospitalMenu = () => {
    const {hospitals, selectedHospital} = useSelector(state => state.auth);
    const ref = React.useRef(null);
    const [popupVisible, setPopupVisible] = useState(false);
    useOnClickOutside(ref, () => setPopupVisible(false));

    const togglePopupMenu = () => setPopupVisible(!popupVisible);

    useEffect(() => {
        setPopupVisible(false);
    }, [selectedHospital])

    return (
        <div className="dropdown hospital-info-drop" ref={ref}>
            <button className="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={togglePopupMenu}>
                {selectedHospital.title}
            </button>
            <div className={`dropdown-menu dropdown-menu-right${popupVisible ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                {hospitals.map((hospital, key) => <NavBar_HospitalSelector hospital={hospital} key={key}/> )}
            </div>
        </div>
    );
}
export default NavBar_HospitalMenu;