import React from 'react';

const Spinner = ({height = null, width = null, className = ''}) => {
    const style = {};
    if (height) {
        style.height = height + 'px';
    }
    if (width) {
        style.width = width + 'px';
    }
    return <span className={`spinner-border spinner-border ${className}`} role="status" aria-hidden="true" style={style}/>;
}

export default Spinner;