import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {linkPreAdmission, loadPreAdmissionHistory} from "../../../store/actions/customUI";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: '1000',
    }),
}

const animatedComponents = makeAnimated();

const PreAdmissionsSet_SelectPreAdmission = ({admission, customUI}) => {
    const dispatch = useDispatch();
    const idToken = useSelector(state => state.auth.idToken);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([])
    const [error, setError] = useState('');
    const [preAdmissionId, setPreAdmissionId] = useState(false);
    const isOptionsLoaded = options.length;

    const apiUrl = `${process.env.REACT_APP_API_PATH}/admission/${admission.id}/custom-ui/${customUI.id}/load-pre-admission-history`;

    const doLoadApiData = () => {
        setLoading(true);
        setError('')
        axios.get(
            apiUrl,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                if (response.data.error) {
                    setError(response.data.error);
                } else {
                    // console.log('[PreAdmissionsSet_SelectPreAdmission] got API response', response.data.results);
                    setLoading(false);

                    const _options = response.data.results.map(item => {return {value: item.id, label: `${item.firstName} ${item.lastName} - ${item.admissionDate} (DOB ${item.dob})`}});
                    const _value = response.data.results.find(item => item.lastName === admission.surname);
                    console.log('[PreAdmissionsSet_SelectPreAdmission] got API response', _options, _value);

                    if (_value) {
                        setPreAdmissionId(_value.id);
                    }
                    setOptions(_options);
                }
            });
    }

    const handleSelectChange = option => setPreAdmissionId(option.value);

    const doLinkPreAdmission = () => {
        setLoading(true);
        dispatch(linkPreAdmission(admission, customUI, preAdmissionId));
    }

    const renderForm = () => {
        if (isOptionsLoaded) {
            const _value = preAdmissionId ? options.find(item => item.value === preAdmissionId) : null;
            return <>
                <Select
                id="inputGroupSelect01"
                options={options}
                styles={customStyles}
                isMulti={false}
                components={animatedComponents}
                value={_value}
                onChange={handleSelectChange}
                />
                <br />
                <div className={`form-group ${error ? 'form-error' : ''}`}>
                    <input type="button" value="Link Admission" className="btn btn-primary" disabled={loading || !preAdmissionId} onClick={doLinkPreAdmission}/>
                </div>
            </>;
        }
        return (
            <div className={`form-group ${error ? 'form-error' : ''}`}>
                <input type="button" value="Load pre-admissions history" className="btn btn-primary" disabled={loading} onClick={doLoadApiData}/>
            </div>
        );
    }

    return <>
        <h4>Select pre-admission</h4>
        {error && <p className="error">{error}</p>}
        {renderForm()}
        <hr />
    </>
}

export default PreAdmissionsSet_SelectPreAdmission;