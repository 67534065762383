import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from '../../../../store/actions/actionTypes';
import pdfSvg from '../../../../assets/images/pdf.svg';

const AdmissionList_Item_ManagerCustomUI_UploadPdf = ({admission, customUI}) => {
    const idToken = useSelector(state => state.auth.idToken);
    const dispatch = useDispatch();
    const showUploadModal = () => dispatch({type: actionTypes.MODAL_CUSTOM_UI_UPLOAD_PDF_SHOW, admission, customUI})
    const showPreviewModal = () => dispatch({type: actionTypes.MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_SHOW, admission, customUI})
    const downloadFile = event => {
        event.preventDefault();
        event.stopPropagation();
        ref.current.submit();
    }
    const ref = React.useRef();

    if(admission.manager_customUI[customUI.id].status) {
        return <>
            <button className="btn-pink btn-sm btn btn-success10 text-success" onClick={customUI.showPdfInPopup ? showPreviewModal : downloadFile}><img src={pdfSvg} /></button>
            <div className="display-none">
                <form action={`${process.env.REACT_APP_API_PATH}/admission/${admission.id}/custom-ui/${customUI.id}/download-pdf`} method="post"
                      ref={ref}>
                    <input type="hidden" name="token" value={idToken}/>
                </form>
            </div>
        </>
    }

    return <button className="btn btn-sm btn-secondary" onClick={showUploadModal}>-</button>
}

export default AdmissionList_Item_ManagerCustomUI_UploadPdf;