import { combineReducers } from 'redux'
import { createRouterReducer } from '@lagunovsky/redux-react-router'

import admission from './reducers/admission';
import admissionList from "./reducers/admissionList";
import auth from './reducers/auth';
import bulk from './reducers/bulk';
import dev from './reducers/dev';
import dischargeList from './reducers/dischargeList';
import hospitalPatient from './reducers/hospitalPatient';
import main from './reducers/main';
import modal from './reducers/modal';
import oec_claim from './reducers/oec_claim';

import { reducer as formReducer } from 'redux-form'

const createRootReducer = (history) => combineReducers({
    router: createRouterReducer(history),
    form: formReducer,
    admission,
    admissionList,
    auth,
    bulk,
    dev,
    dischargeList,
    hospitalPatient,
    main,
    modal,
    oec_claim,
});

export default createRootReducer;