import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from "../../store/actions/actionTypes";
import useAdvancedListNavigation from "../../utils/advancedListNavigation";

const AdvancedList_Search = () => {
    // Refactor - useAdvancedListNavigation
    const {pushAdvancedListUrl} = useAdvancedListNavigation();
    // ----------------------

    const dispatch = useDispatch();
    const {keyword} = useSelector(state => state.dischargeList); // REFACTOR - use abstract advancedList storage + type param. Reset in view on load.
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (keyword) {
            setSearchValue(keyword);
        } else {
            setSearchValue('');
        }
    }, [keyword]);
    // console.log('[AdmissionList_Search]', keyword);

    const searchEnabled = () => (searchValue.length > 1) || ((searchValue === '') && (keyword !== '') && (keyword !== null) && (keyword !== false));

    const search = event => {
        if (searchEnabled) {
            // Refactor - move to params ADMISSIONS_LIST_SET_KEYWORD?
            pushAdvancedListUrl(null, null, searchValue, null, null, false, false);
            // Refactor - why is ADMISSIONS_LIST_SET_KEYWORD dispatched after pushAdmissionsListUrl ?
            dispatch({type: actionTypes.DISCHARGE_LIST_SET_KEYWORD, keyword: searchValue})
        }
        event.preventDefault();
    }

    return (
        <div className={`search-holder${keyword ? ' search-active' : ''}`}>
            <form className="search-form" onSubmit={search}>
                <div className="input-row">
                    <input type="search" className="form-control" placeholder="Search..." onChange={(event) => setSearchValue(event.target.value)} value={searchValue} />
                </div>
                <button className="btn btn-sm btn-search" disabled={!searchEnabled()} onClick={search}><i className="icon ico-zoom" /></button>
            </form>
        </div>
    );
}

export default AdvancedList_Search;