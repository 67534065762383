import React from "react";
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from "../../../store/actions/actionTypes";
import {useCustomLogic} from "../../../utils/customLogic";
import LifehouseAdmissionPanel_PatMedTd from "./LifehouseAdmissionPanel_PatMedTd";
import * as constants from "../../../utils/constants";

const LifehouseAdmissionPanel = ({admission}) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const {isCustomLogicLifehouse} = useCustomLogic();

    const showLifeHouseERFAModal = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_ERFA_SHOW, admission: admission});
    const showLifehostPacRequiredModal = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_PAC_REQUIRED_SHOW, admission: admission});
    const showLifehostCommentsModal = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_COMMENTS_SHOW, admission: admission, commentsType: 1});
    const showLifehostComments2Modal = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_COMMENTS_SHOW, admission: admission, commentsType: 3});

    const renderBook = () => {
        if (admission.lifehouseFlags_book) {
            return (
                <button type="button" className="btn-group-table-button btn btn-success10 text-success"><span
                    className="icon ico-checkmark"></span></button>
            );
        } else {
            return <button type="button" className="btn-group-table-button btn btn-gray10">-</button>;
        }
    }
    const renderPac = () => {
        if (admission.lifehouseFlags_pac) {
            return (
                <button type="button" className="btn-group-table-button btn btn-success10 flex-column">
                    <span className="btn-group-text-sm">{admission.lifehouseFlags_pac_date[0]}</span>
                    <span className="btn-group-text-sm">{admission.lifehouseFlags_pac_date[1]}</span>
                </button>
            );
        } else if (!auth.selectedHospital.lifehouse_pacRequired_enable) {
            return <button type="button" className="btn-group-table-button btn btn-gray10">-</button>;
        } else {
            switch (admission.lifehouse_pac_status) {
                case constants.STATUS_PAC_REQUIRED:
                default:
                    return (
                        <button type="button" className="btn-group-table-button btn btn-gray10"
                                onClick={showLifehostPacRequiredModal}>-</button>
                    );
                case constants.STATUS_PAC_NOT_REQUIRED:
                    return (
                        <button type="button" className="btn-group-table-button btn btn-gray10 btn-label-red"
                                onClick={showLifehostPacRequiredModal}>X</button>
                    );
                case constants.STATUS_PAC_IN_PROGRESS:
                    return (
                        <button type="button" className="btn-group-table-button btn btn-gray10"
                                onClick={showLifehostPacRequiredModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-hourglass-split" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                            </svg>
                        </button>
                    );
            }
        }
    }
    const renderPreg = () => {
        if (admission.lifehouseFlags_preg) {
            return (
                <button type="button" className="btn-group-table-button btn btn-success10 text-success"><span
                    className="icon ico-checkmark"></span>
                </button>
            );
        } else {
            return <button type="button" className="btn-group-table-button btn btn-gray10">-</button>;
        }
    }
    const renderAdm = () => {
        if (admission.lifehouseFlags_adm) {
            return (
                <button type="button" className="btn-group-table-button btn btn-success10 text-success"><span
                    className="icon ico-checkmark"></span></button>
            );
        } else {
            return <button type="button" className="btn-group-table-button btn btn-gray10">-</button>;
        }
    }

    const renderErfaDate = () => {
        if (admission.lifehouseFlags_erfa_date) {
            return <>
                <span className="btn-group-text-sm">{admission.lifehouseFlags_erfa_date[0]}</span>
                <span className="btn-group-text-sm">{admission.lifehouseFlags_erfa_date[1]}</span>
            </>;
        } else {
            return <>
                <span className="btn-group-text-sm">-</span>
                <span className="btn-group-text-sm">-</span>
            </>;
        }
    }

    const showSelectClinicalTeamPopup = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_SHOW, admission: admission});

    const renderPac2Btn = () => {
        switch (admission.lifehouse_pac2) {
            case constants.CLINICAL_TEAM_PAC:
                return (
                    <button type="button" className="btn-group-table-button btn btn-lifehouse-pac" onClick={showSelectClinicalTeamPopup}>PAC</button>
                );
            case constants.CLINICAL_TEAM_PAC2:
                return (
                    <button type="button" className="btn-group-table-button btn btn-lifehouse-pac" onClick={showSelectClinicalTeamPopup}><span className="icon ico-checkmark"></span></button>
                );
            case constants.CLINICAL_TEAM_PSN:
                return (
                    <button type="button" className="btn-group-table-button btn btn-lifehouse-psn" onClick={showSelectClinicalTeamPopup}>PSN</button>
                );
            case constants.CLINICAL_TEAM_PSN2:
                return (
                    <button type="button" className="btn-group-table-button btn btn-lifehouse-psn" onClick={showSelectClinicalTeamPopup}><span className="icon ico-checkmark"></span></button>
                );
            case constants.CLINICAL_TEAM_NONE:
            default:
                return (
                    <button type="button" className="btn-group-table-button btn btn-gray10" onClick={showSelectClinicalTeamPopup}>-</button>
                );
        }
    }
    const renderCommentsBtn = () => {
        if (admission.lifehouse_comments_count > 0) {
            switch (admission.lifehouse_comments_status) {
                case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PENDING:
                    return (
                        <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange" onClick={showLifehostCommentsModal}>{admission.lifehouse_comments_count}</button>
                    );
                case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_CLOSED:
                    return (
                        <button type="button" className="btn-group-table-button btn lifehouse-button-green" onClick={showLifehostCommentsModal}>{admission.lifehouse_comments_count}</button>
                    )
            }
        }

        return (
            <button type="button" className="btn-group-table-button btn btn-gray10" onClick={showLifehostCommentsModal}>-</button>
        );
    }

    const renderComments2Btn = () => {
        if (admission.lifehouse_comments2_count > 0) {
            switch (admission.lifehouse_comments2_status) {
                case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PENDING:
                    return (
                        <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange" onClick={showLifehostComments2Modal}>{admission.lifehouse_comments2_count}</button>
                    );
                case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_CLOSED:
                    return (
                        <button type="button" className="btn-group-table-button btn lifehouse-button-green" onClick={showLifehostComments2Modal}>{admission.lifehouse_comments2_count}</button>
                    )
            }
        }

        return (
            <button type="button" className="btn-group-table-button btn btn-gray10" onClick={showLifehostComments2Modal}>-</button>
        );
    }

    if (auth.selectedHospital.lifehouse_admissionListPanel_enable) {
        return (
            <>
                <td className="lifehouse-adm-list-btn">
                    <button onClick={showLifeHouseERFAModal} type="button" className="btn-group-table-button btn btn-success10 text-success flex-column">
                        {/*<span className="icon ico-checkmark"></span>*/}
                        {renderErfaDate()}
                    </button>
                </td>
                {auth.selectedHospital.lifehouse_admissionComments_enable && <td className="lifehouse-adm-list-btn">{renderCommentsBtn()}</td>}
                {auth.selectedHospital.lifehouse_selectClinicalTeam_enable && <td className="lifehouse-adm-list-btn">{renderPac2Btn()}</td>}
                <td className="lifehouse-adm-list-btn">{renderPac()}</td>
                <LifehouseAdmissionPanel_PatMedTd admission={admission} />
                {isCustomLogicLifehouse() && <td className="lifehouse-adm-list-btn">{renderBook()}</td>}
                {isCustomLogicLifehouse() && <td className="lifehouse-adm-list-btn">{renderPreg()}</td>}
                <td className="lifehouse-adm-list-btn">{renderAdm()}</td>
                {auth.selectedHospital.lifehouse_admissionComments2_enable && <td className="lifehouse-adm-list-btn">{renderComments2Btn()}</td>}
            </>
        )
    }
    return null;
}

export default LifehouseAdmissionPanel;