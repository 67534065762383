import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useToasts} from "react-toast-notifications";

import loadingGif from '../../../../assets/images/loader.gif';
import * as actionTypes from "../../../../store/actions/main";

const CloneToDemoLink = ({admission}) => {
    const {addToast} = useToasts();
    const dispatch = useDispatch();
    const {cloneToDemoIds} = useSelector(state => state.main);
    const {demo} = useSelector(state => state.auth);

    const doSync = () => dispatch(actionTypes.cloneToDemo(admission, addToast));

    if (!demo.demoHospital) {
        return null;
    }
    if(cloneToDemoIds.indexOf(admission.id) > -1) {
        return (
            <a className="dropdown-item disabled pointer"><img src={loadingGif} alt="loading"/>[Demo] Clone to {demo.demoHospital.title} on {demo.demoDay} and depersonalize</a>
        );
    } else {
        return (
            <a className="dropdown-item pointer" onClick={doSync}>[Demo] Clone to {demo.demoHospital.title} on {demo.demoDay} and depersonalize</a>
        );
    }
}

export default CloneToDemoLink;