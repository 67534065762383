import React, {useState} from "react";
import AsyncSelect from "react-select/async";
import axios from 'axios';
import {useSelector} from "react-redux";

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: '1000',
    }),
}


const AsyncSelectField = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const idToken = useSelector(state => state.auth.idToken);

    const {
        input: { value, onChange, name, deps },
        placeholder, formatSelectOption, apiPath, isClearable = false //change
    } = props;
    console.log('[AsyncSelectField] name=', name);
    const loadOptions = (inputValue, callback) => {
        console.log('[AsyncSelectField] loading ', inputValue, apiPath);
        setIsLoading(true);
        axios.get(apiPath.replace('{inputValue}', inputValue), {headers: {'Authorization': idToken}})
            .then((response) => {
                setOptions(response.data);
                setIsLoading(false);
                callback(response.data);
            })
    }

    return (
        <AsyncSelect
            name={name}
            // id={name}
            // options={options}
            // styles={customStyles}
            // isMulti={false}
            value={value}
            onChange={onChange}
            isClearable={isClearable}
            isSearchable={true}
           {...deps}
            placeholder={placeholder}
            formatOptionLabel={formatSelectOption}
            loadOptions={loadOptions}
            isLoading={isLoading}
        />
    );
}

export default AsyncSelectField;