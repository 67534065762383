import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import * as modalActions from "../../store/actions/modal";
import {sendMessage} from "../../store/actions/admission";
import {useToasts} from "react-toast-notifications";
import {parseAdmissionTemplateVars} from '../../utils/utils';
import Admission_ModalInfo from "../Block/Admission_ModalInfo";
import ReactQuill from "react-quill";
import Admission_TargetPhone from "../Block/Admission_TargetPhone";
import useHtmlValidation from "../../utils/htmlValidation";

const ModalAdmissionTime_Customize = () => {
    const {valid, validationErrors, loading, resetValidation, validateHtml, hasErrors} = useHtmlValidation();
    const [text, setText] = useState('');
    const [patientTitle, setPatientTitle] = useState('');
    const [smsNotification, setSmsNotification] = useState(false);
    const [smsText, setSmsText] = useState('');
    const {addToast} = useToasts();
    const {show, admission, admTime, selectedMessageTemplate, extraFields} = useSelector(state => state.modal.admissionTimeCustomize);
    const {selectedHospital} = useSelector(state => state.auth);

    let admissionTemplateVars = {vars: [...admission.template_vars.vars], values: [...admission.template_vars.values], extraFields: extraFields};
    const admTimeKey = admissionTemplateVars.vars.push('{{_admTime}}') - 1;
    admissionTemplateVars.values.push('');

    const p = (str) => parseAdmissionTemplateVars(admissionTemplateVars, str);

    useEffect(() => {
        admissionTemplateVars.values[admTimeKey] = admTime;

        setPatientTitle(p(selectedMessageTemplate.patient_title));
        setSmsText(p(selectedMessageTemplate.sms_template));
        setSmsNotification(selectedMessageTemplate.sms_enable);
        setText(p(selectedMessageTemplate.message));
    }, [show, admTime, selectedMessageTemplate]);

    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADMISSION_TIME_CUSTOMIZE_HIDE});
    const changeAdmTime = () => dispatch({type: actionTypes.MODAL_ADMISSION_TIME_RESTART});

    const doSendMessage = (event) => {
        // setSendClicked(true);
        dispatch(sendMessage(admission, addToast, patientTitle, text, smsNotification, smsText, {'_admTime': admTime}));
        event.preventDefault();
        dispatch({type: actionTypes.MODAL_ADMISSION_TIME_CUSTOMIZE_HIDE});
    }

    const handleSmsNotification = (event) => setSmsNotification(event.target.checked);
    const handleSmsText = event => setSmsText(event.target.value);
    const handleTitle = event => setPatientTitle(event.target.value);

    const modules = {
        toolbar: [
            // [{ 'header': [4, false] }],
            ['bold', 'italic', 'underline', 'strike'], // , 'blockquote'
            // [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    const renderSmsTextBlock = () => {
        if (smsNotification) {
            return (
                <div className="form-group mb-4">
                    Sms text<br />
                    <textarea style={{minHeight: '4rem', height: '4rem'}} className="form-control" cols="30" rows="5" value={smsText} onChange={handleSmsText}>{admission.enablePatientPortalMessage}</textarea>
                </div>
            )
        }
        return null;
    }

    const selectTemplate = event => {
        dispatch({type: actionTypes.MODAL_ADMISSION_TIME_CUSTOMIZE_SELECT_TEMPLATE, selectedMessageTemplate: selectedHospital.atm_templates[event.target.value]});
        event.preventDefault();
    }

    const selectedAtmTemplateKey = () => selectedHospital.atm_templates.findIndex(item => item.id === selectedMessageTemplate.id);

    const handleChangeHtml = (text) => {
        setText(text);
        resetValidation();
    }

    const handleValidate = () => validateHtml(text);

    const renderModal = () => {
        if (show) {
            return (
                <div className="modal-dialog modal-content-large" role="document" ref={ref} autoFocus={false}>
                    <div className="modal-content modal-content-fit">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <h3>Change admission time for {admission.title} {admission.surname}, {admission.name}</h3>
                            <Admission_ModalInfo admission={admission} />
                            <form onSubmit={doSendMessage} className="person-info-form">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-7">
                                            Select Template <br />
                                            <select className="custom-select" id="inputGroupSelect01" onChange={selectTemplate} value={selectedAtmTemplateKey()}>
                                                {selectedHospital.atm_templates.map((item, key) => <option key={key} value={key}>{item.title}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-5">
                                            Admission time <br/>
                                            <div>{admTime} <input style={{marginLeft: '10px'}} type="button" value="Back and Change" className="btn btn-secondary" onClick={changeAdmTime}/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    Title <br/>
                                    <input name="title" className="form-control" onChange={handleTitle} value={patientTitle}/>
                                </div>
                                <div className="form-group mb-4">
                                    Message <br/>
                                    {validationErrors()}
                                    <ReactQuill theme="snow" value={text} onChange={handleChangeHtml} modules={modules} className={`${hasErrors() ? 'error' : ''}`}/>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="custom-control custom-switch custom-switch-right-side">
                                                <input type="checkbox" className="custom-control-input"
                                                       id="smsNotificationSwitch" checked={smsNotification} onChange={handleSmsNotification}/>
                                                <label className="custom-control-label"
                                                       htmlFor="smsNotificationSwitch">Sms notification?</label>
                                            </div>
                                        </div>
                                        <div className="col-8 d-flex">
                                            <dd><Admission_TargetPhone admission={admission} /></dd>
                                        </div>
                                    </div>
                                </div>
                                {renderSmsTextBlock()}
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <input type="button" value="Cancel" className="btn" onClick={hideModal}/>
                                            {valid ? <input type="submit" value="Send via Portal" className="btn btn-primary" onClick={doSendMessage}/>
                                                : <input type="button" value="Validate message" className="btn btn-primary" onClick={handleValidate} disabled={loading}/>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {renderModal()}
            </div>
        </>
    );
}

export default ModalAdmissionTime_Customize;