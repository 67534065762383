import React from 'react';

const Badge_YN = ({status}) => {
    switch (status.toString().toLowerCase()) {
        default:
            return <div className="badge badge-custom-grey">{status}</div>;
        case "y":
        case "yes":
            return <div className="badge badge-success">yes</div>
        case "n":
        case "no":
            return <div className="badge badge-danger">no</div>
    }
}

export default Badge_YN;