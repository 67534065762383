import * as actionTypes from './actionTypes';
import axios from 'axios';

import * as constants from "../../utils/constants";

export const ignoreComment = (commentId, admissionId, type, addToast) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admissionId});

        const idToken = getState().auth.idToken;

        console.log('[actions/lifehouse] ignoreComment', admissionId);
        if (type === 2) {
            dispatch({type: actionTypes.MODAL_LIFEHOUSE_PAC_REQUIRED_HIDE});
        } else {
            dispatch({type: actionTypes.MODAL_LIFEHOUSE_COMMENTS_HIDE});
        }

        axios.get(
            `${process.env.REACT_APP_API_PATH}/lifehouse/admission/${admissionId}/ignore-comment/${commentId}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] ignoreComment reload admissions list');
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admissionId});
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                if (type === 2) {
                    dispatch({type: actionTypes.MODAL_LIFEHOUSE_PAC_REQUIRED_SHOW, admission: response.data.admission});
                } else {
                    dispatch({type: actionTypes.MODAL_LIFEHOUSE_COMMENTS_SHOW, admission: response.data.admission, commentsType: type});
                }

                addToast(`Comment ignored`, {
                    appearance: 'success',
                    autoDismiss: true
                });

                // dispatch({type: actionTypes.MODAL_LIFEHOUSE_COMMENTS_SHOW, admission: admission})
            });
    }
};

    // dispatch({type: actionTypes.MODAL_LIFEHOUSE_COMMENTS_SHOW, admission: admission})

export const selectClinicalTeam = (admission, clinicalTeam, addToast) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});

        const idToken = getState().auth.idToken;

        console.log('[actions/lifehouse] selectClinicalTeam', admission.id);
        dispatch({type: actionTypes.MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_HIDE});

        axios.post(
            `${process.env.REACT_APP_API_PATH}/lifehouse/admission/${admission.id}/select-clinical-team`,
            {clinicalTeam},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] selectClinicalTeam reload admissions list');
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                addToast(`${constants.CLINICAL_TEAM_TITLES[clinicalTeam]} selected for ${admission.label}`, {
                        appearance: 'success',
                        autoDismiss: true
                });
            });
    }
};

export const setPacRequired = (admission, comment, pacRequired, addToast) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});

        const idToken = getState().auth.idToken;

        console.log('[actions/lifehouse] setPacRequired', admission.id);
        dispatch({type: actionTypes.MODAL_LIFEHOUSE_PAC_REQUIRED_HIDE});

        axios.post(
            `${process.env.REACT_APP_API_PATH}/lifehouse/admission/${admission.id}/set-pac-required`,
            {comment, pacRequired},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] setPacRequired reload admissions list');
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                addToast(`PAC Required changed for ${admission.label}`, {
                    appearance: 'success',
                    autoDismiss: true
                });
            });
    }
}

export const addComment = (admission, body, status, commentsType, addToast) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});

        const idToken = getState().auth.idToken;

        console.log('[actions/lifehouse] addComment', admission.id);
        dispatch({type: actionTypes.MODAL_LIFEHOUSE_COMMENTS_HIDE});

        axios.post(
            `${process.env.REACT_APP_API_PATH}/lifehouse/admission/${admission.id}/add-comment`,
            {body, status, commentsType},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/modal] addComment reload admissions list');
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                addToast(`Internal comment added for ${admission.label}`, {
                    appearance: 'success',
                    autoDismiss: true
                });
            });
    }
}