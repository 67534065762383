import React from 'react';

import RFAList_item from "./RFAList_item";

const RFAList = ({items = []}) => {
    const renderItems = (item, key) => <RFAList_item key={key} item={item} />

    if (!items.length) {
        return null;
    }
    return <>
        <div className="row">
            <div className="col-12">
                <div className="tabs-holder bg-white rounded shadow-sm border mb-4">
                    <table className="table" cellSpacing="0" width="100%">
                        <thead>
                        <tr>
                            <th><strong className="title">Hospital</strong></th>
                            <th><strong className="title">Doctor</strong></th>
                            <th><strong className="title">Files</strong></th>
                            <th><strong className="title">Admission Date</strong></th>
                            <th><strong className="title">Hospital Status</strong></th>
                            <th><strong className="title">&nbsp;</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map(renderItems)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        {/*<div className="form-group">*/}
        {/*    <div className="row">*/}
        {/*        <div className="col-12 d-flex justify-content-end">*/}
        {/*            <input className="btn btn-primary" type="button" value="Send Form" onClick={sendAdmissionDocument}/>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        </>;
}

export default RFAList;