import React from "react";
import {useSelector, useDispatch} from "react-redux";

import loadingGif from '../../assets/images/loader.gif';
import * as actionTypes from "../../store/actions/actionTypes";

const SendMessageBtn = () => {

    const dispatch = useDispatch();
    const {admission, sendMessageIds} = useSelector(state => state.admission);

    const showResendModal = () => dispatch({type: actionTypes.MODAL_SEND_MESSAGE_SHOW, admission});

    if (admission.cancelled) { // !admission.pp_invitedAt ||
        return (
            <button className="btn btn-sm btn-white-primary" data-toggle="modal"
                    disabled={true}
                    data-target="#personModal">
                <i className="icon ico-a-chat"/>
                <span>Send Message</span>
            </button>
        );
    } else if(sendMessageIds.indexOf(admission.id) > -1) {
        return (
            <button className="btn btn-sm btn-white-primary disabled" data-toggle="modal" disabled={true}
                    data-target="#personModal">
                <img src={loadingGif} />
                <span>Send Message</span>
            </button>
        );
    } else {
        return (
            <button className="btn btn-sm btn-white-primary" data-toggle="modal"
                    onClick={showResendModal}
                    data-target="#personModal">
                <i className="icon ico-a-chat"/>
                <span>Send Message</span>
            </button>
        );
    }
}

export default SendMessageBtn;