export const CLINICAL_TEAM_NONE = 'CLINICAL_TEAM_NONE';
export const CLINICAL_TEAM_PAC = 'CLINICAL_TEAM_PAC';
export const CLINICAL_TEAM_PAC2 = 'CLINICAL_TEAM_PAC2';
export const CLINICAL_TEAM_PSN = 'CLINICAL_TEAM_PSN';
export const CLINICAL_TEAM_PSN2 = 'CLINICAL_TEAM_PSN2';

export const CLINICAL_TEAM_TITLES = {
    CLINICAL_TEAM_NONE: 'None',
    CLINICAL_TEAM_PAC: 'PAC',
    CLINICAL_TEAM_PAC2: 'PAC completed',
    CLINICAL_TEAM_PSN: 'PSN',
    CLINICAL_TEAM_PSN2: 'PSN completed',
}

export const LIFEHOUSE_COMMENTS_STATUS = {
    STATUS_PENDING: "STATUS_PENDING",
    STATUS_CLOSED: "STATUS_CLOSED",
    STATUS_NONE: "STATUS_NONE",
    STATUS_PAC_REQUIRED: "STATUS_PAC_REQUIRED",
    STATUS_PAC_IN_PROGRESS: "STATUS_PAC_IN_PROGRESS",
    STATUS_PAC_NOT_REQUIRED: "STATUS_PAC_NOT_REQUIRED",
}

export const COMMENTS_STATUS = {
    STATUS_PENDING: "STATUS_PENDING",
    STATUS_CLOSED: "STATUS_CLOSED",
    STATUS_NONE: "STATUS_NONE",
}

export const TYPE_DISCHARGE_COMMENTS = 1;

export const STATUS_PAC_REQUIRED = 10;
export const STATUS_PAC_IN_PROGRESS = 11;
export const STATUS_PAC_NOT_REQUIRED = 12;