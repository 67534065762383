import React, {useState} from "react";
import {Form} from 'react-final-form';
import {getProperty} from "dot-prop";
import {useDispatch} from "react-redux";

import Admission_Form from "./Admission_Form";
import FlashMessages from "../../FlashMessages/FlashMessages";

const AdmissionForm = ({initialValues = [], title, handleSubmitAction, apiHash = '-'}) => {
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const formHandle = Math.round(1000000000 * Math.random());
    // console.log('[RfaForm] render', initialValues)
    const onSubmit = (data) => {
        // console.log('[AdmissionForm] onSubmit()');
        dispatch(handleSubmitAction({...data, formHandle}, setError));
    }

    const validate = (data) => {
        // fields validation are used for now
    }

    const renderError = () => {
        if (error) {
            return <div className="alert alert-danger mt-2">{error}</div>;
        }

        return null;
    }

    return (
        <main className="main">
            <div className="container">
                <FlashMessages/>
                <h1>{title}</h1>
                <div className="row justify-content-center bg-white rounded shadow-sm border">
                    <div className="col-12">
                        {renderError()}
                        <Form onSubmit={onSubmit}
                              validate={validate}
                              initialValues={initialValues}
                              render={Admission_Form}
                              subscription={{ submitting: true, pristine: true }}
                              formHandle={formHandle}
                              files={getProperty(initialValues, 'files', [])}
                              apiHash={apiHash}
                              title={title}
                        />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default AdmissionForm;