import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import AdmissionDocumentPdf from "../AdmissionDocumentPdf";
import ClinicalFlags from "./Blocks/ClinicalFlags";
import {showAdmEmrView} from "../../store/actions/modal";


// TODO: refactor https://www.notion.so/admissioncomau/Modal-AdmissionDocumentPDF-847dfa81733649a488d56aac291a1827
const ModalAdmissionDocumentPdf = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    {/* ACA-638 - refactor jmphMedForm flag */}

    //
    const {show, admissionDocument, admission, customUIStatus, managerCustomUi, jmphMedForm} = useSelector(state => state.modal.admissionDocumentPdf);
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_HIDE});

    const renderClinicalFlag = (item) => {
        if (item.statusRating !== false) {
            return (<li className="alerts-list-item">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <defs></defs>
                    <path className={`${item.rating > 1 ? "alert-bg-danger" : "alert-bg-warning"} `}
                          d="M0,12c0,-6.62742 5.37258,-12 12,-12h0c6.62742,0 12,5.37258 12,12v0c0,6.62742 -5.37258,12 -12,12h0c-6.62742,0 -12,-5.37258 -12,-12z"></path>
                    <g transform="matrix(1,0,0,1,9,1)">
                        <text className={`symbol ${item.rating > 1 ? "alert-symbol-danger" : "alert-symbol-warning"} `}
                              dominant-baseline="text-before-edge">!
                        </text>
                    </g>
                </svg>
                <span className="alert-text">{item.title}</span>
            </li>)
        }
    }
    const admissionDocumentTitle = () => {
        switch (admissionDocument) {
            case actionTypes.ADMISSION_DOCUMENT_COVID_SCREENING:
                return 'Covid Screening';
            case actionTypes.ADMISSION_DOCUMENT_MANAGER_CUSTOM_UI:
                return managerCustomUi.managerTitle || 'Covid Screening';
            case actionTypes.ADMISSION_DOCUMENT_ADMISSION_INFO:
                return 'Admission Information';
            case actionTypes.ADMISSION_DOCUMENT:
                return customUIStatus.title;
        }
    }

    const getSubType = () => {
        if (admissionDocument === actionTypes.ADMISSION_DOCUMENT_MANAGER_CUSTOM_UI) {
            return customUIStatus.admissionDocumentTypeId;
        }
        if (admissionDocument === actionTypes.ADMISSION_DOCUMENT) {
            return customUIStatus.id;
        }
        return '-';
    }

    const showEmrView = () => dispatch(showAdmEmrView(admission));

    const renderModal = () => {
        if (show) {
            return <div ref={ref} className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body modal-cols-holder">
                        <div className="content-holder">
                            <div className="modal-header">
                                <h5 className="modal-title h1">{admissionDocumentTitle()} for {admission.name} {admission.surname}</h5>
                                {jmphMedForm ? <button className="btn btn-group-table-button btn-dark-gray send-reg-btn" onClick={showEmrView}>EMR VIEW</button> : <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                                    <span aria-hidden="true">×</span>
                                </button>}
                            </div>
                            {/*<DevUiData data={{admissionDocument, customUIStatus: customUIStatus || '...null', managerCustomUi: managerCustomUi || '...null', admission: '...admission'}}/>*/}
                            <AdmissionDocumentPdf admissionId={admission.id} type={admissionDocument} width={jmphMedForm ? 700 : 1075} subType={getSubType()} height={620}/>
                        </div>

                        {jmphMedForm && <ClinicalFlags admission={admission} hideModal={hideModal} jmphMedForm={jmphMedForm}/>}
                    </div>
                </div>
            </div>
        }
    }

    return (
        <div className={`modal modal-med-history fade ${show ? 'show' : ''}`} id="modalClinicalFlags" tabIndex="-1" role="dialog"
             style={{display: "block", paddingRight: "11px"}} ariaModal="true">
            {renderModal()}
        </div>
    )
}

export default ModalAdmissionDocumentPdf;