import React from "react";
import Select from "react-select";

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: '1000',
    }),
}


const SelectField = (props) => {
    const {
        input: { value, onChange, name, deps },
        placeholder, options, formatSelectOption, //change
    } = props;

    return (
        <Select
            id={name}
            options={options}
            styles={customStyles}
            isMulti={false}
            value={value}
            onChange={onChange}
            {...deps}
            placeholder={placeholder}
            isClearable={true}
            formatOptionLabel={formatSelectOption}
        />
    );
}

export default SelectField;