import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import * as modalActions from "../../store/actions/modal";
import ModalSelectDocumentSet_Actions from "./ModalSelectDocumentSet_Actions";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import {MODAL_ADMISSION_DOCUMENTS_HIDE} from "../../store/actions/actionTypes";

const ModalAdmissionDocuments = () => {
    const {show, admission, admissionDocuments} = useSelector(state => state.modal.admissionDocuments);
    const showSendAdmissionDocuments = useSelector(state => state.modal.sendAdmissionDocuments.show);
    const selectedHospital = useSelector(state => state.auth.selectedHospital);
    const idToken = useSelector(state => state.auth.idToken);

    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => {
        if (!showSendAdmissionDocuments) {
            hideModal()
        }
    });

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADMISSION_DOCUMENTS_HIDE});

    const sendAdmissionDocument = () => dispatch({
        type: actionTypes.MODAL_SEND_ADMISSION_DOCUMENT_SHOW,
        admission,
        admissionDocumentTypes: selectedHospital.admissionDocumentTypes,
        parentModal: "admissionDocuments"
    });

    const renderItems = (item, key) => {
        return (
            <tr key={key}>
                <td>{item.title}</td>
                <td>{item.createdAt}</td>
                <td>-</td>
                <td>{renderStatus(item)}</td>
                <td>
                    {renderActions(item)}
                </td>
            </tr>
        );
    }

    const renderStatus = (admissionDocument) => {
        switch (admissionDocument.status) {
            case "STATUS_NEW":
                return 'Sent to patient';
            case "STATUS_VIEWED":
                return 'Seen on '+admissionDocument.lastViewedAt;
            case "STATUS_NEXT":
                return 'Pending';
            case "STATUS_COMPLETED":
                return 'Completed on ' + admissionDocument.completedAt;
            case "STATUS_CUTOFF":
                return 'Expired';
        }
    }
    const renderActions = (admissionDocument) => {
        switch (admissionDocument.status) {
            case "STATUS_NEW":
            case "STATUS_VIEWED":
            case "STATUS_NEXT":
            case "STATUS_CUTOFF":
                return null;
            case "STATUS_COMPLETED":
                return (
                    <form id="pdf" method="POST" action={`${process.env.REACT_APP_API_PATH}/admission-document/${admissionDocument.id}/pdf`} target="_blank">
                        <input type="hidden" name="token" value={`${idToken}`} />
                        <input className="btn btn-sm2 btn-primary" type="submit" value="View"/>
                    </form>
                );
        }
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content modal-content-large">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>Forms for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                    </dl>
                    <table className="table" cellSpacing="0" width="100%">
                        <thead>
                        <tr>
                            <th><strong className="title">Title</strong></th>
                            <th><strong className="title">Sent Date</strong></th>
                            <th><strong className="title">Expires</strong></th>
                            <th><strong className="title">Status</strong></th>
                            <th><strong className="title">&nbsp;</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {admissionDocuments.map(renderItems)}

                        </tbody>
                    </table>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <input className="btn btn-primary" type="button" value="Send Form" onClick={sendAdmissionDocument}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="documentStatusModal" tabIndex="-1">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalAdmissionDocuments;