import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import {push} from 'react-router-redux';

import * as actionTypes from "../../store/actions/actionTypes";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import RFAList from "../RFAList/RFAList";

const ModalAdmissionRfa = () => {
    const {show, admission, admissionRfa} = useSelector(state => state.modal.admissionRfa);
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADMISSION_RFA_HIDE});
    const goToHospitalPatient = () => {
        hideModal();
        dispatch(push(`/hp/${admission.hospitalPatient.id}`));
    }
    const goToNewRfa = () => {
        hideModal();
        dispatch(push(`/hp/${admission.hospitalPatient.id}/rfa/add`));
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content modal-content-large">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>RFA for <a onClick={goToHospitalPatient} className="pointer">{admission.title} {admission.surname}, {admission.name}</a></h3>
                    {/*<dl className="person-list mb-3">*/}
                    {/*    <UrDt admission={admission}/>*/}
                    {/*    <AdmDt admission={admission}/>*/}
                    {/*</dl>*/}
                    <RFAList items={admissionRfa}/>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <a className="btn btn-sm btn-primary" onClick={goToNewRfa} href="#">
                                Add RFA
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="documentStatusModal" tabIndex="-1">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalAdmissionRfa;