import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import {useToasts} from "react-toast-notifications";

import * as actionTypes from "../../store/actions/actionTypes";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import * as lifehouseActions from "../../store/actions/lifehouse";
import LifehouseInternalComments from "../Lifehouse/LifehouseInternalComments";
import * as constants from "../../utils/constants";
import Spinner from "../Spinner";

const ModalLifehousePacRequired = () => {
    const {show, admission} = useSelector(state => state.modal.lifehouse_pacRequired);
    const [comment, setComment] = useState('');
    const [pacRequired, setPacRequired] = useState(constants.STATUS_PAC_REQUIRED);
    const {addToast} = useToasts();
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_PAC_REQUIRED_HIDE});
    const sendStatus = () => dispatch(lifehouseActions.setPacRequired(admission, comment, pacRequired, addToast));
    const selectNone = () => setPacRequired(constants.STATUS_PAC_NOT_REQUIRED);
    const selectInProgress = () => setPacRequired(constants.STATUS_PAC_IN_PROGRESS);
    const selectRequired = () => setPacRequired(constants.STATUS_PAC_REQUIRED);
    const updateComment = event => setComment(event.target.value);

    useEffect(() => {
        setPacRequired(admission.lifehouse_pac_status);
        setComment(admission.lifehouse_pac_required_comment);
    }, [])

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content modal-height-600">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>PAC required for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                    </dl>
                    <table cellSpacing="0">
                        <tbody>
                        <tr>
                            <td>
                                <button type="button" className={`btn-group-table-button btn btn-gray10 ${pacRequired === constants.STATUS_PAC_REQUIRED?'btn-border':''}`}  onClick={selectRequired}>-</button>
                            </td>
                            <td>
                                <button type="button" className={`btn-group-table-button btn btn-gray10 ${pacRequired === constants.STATUS_PAC_IN_PROGRESS ?'btn-border':''}`}  onClick={selectInProgress}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-hourglass-split" viewBox="0 0 16 16">
                                        <path
                                            d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                    </svg>
                                </button>
                            </td>
                            <td>
                                <button type="button" className={`btn-group-table-button btn btn-gray10 btn-label-red ${pacRequired === constants.STATUS_PAC_NOT_REQUIRED ?'btn-border':''}`} onClick={selectNone}>X</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h4>Comment</h4>
                    <textarea onChange={updateComment} value={comment}></textarea>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <input className="btn btn-primary" type="button" value="Save Status" onClick={sendStatus}/>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <LifehouseInternalComments admission={admission} type={2}/>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="documentStatusModal" tabIndex="-1">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalLifehousePacRequired;