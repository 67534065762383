import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as modalActions from "../../store/actions/modal";
import * as actionTypes from "../../store/actions/actionTypes";

const Admission_Funding = ({admission}) => {
    const [mainBgClass, setMainBgClass] = React.useState('');
    const dispatch = useDispatch();
    const manager_noMbsUi = useSelector(state => state.auth.manager_noMbsUi);

    const showModal = event => {
        if ((admission.statusOECStr !== 'STATUS_OEC_MISSING_DATA')
            && (admission.statusOECStr !== 'STATUS_OEC_NOT_SUPPORTED'))
        {
            dispatch(modalActions.showOecForAdmission(admission));
            event.preventDefault();
        } else if ((admission.statusOECStr === 'STATUS_OEC_MISSING_DATA')) {
            // TODO: ACA-31 refactor statuses
            if (manager_noMbsUi) {
                dispatch({type: actionTypes.MODAL_PRESENTING_ILLNESS_CODES_SHOW, admission: admission});
            }
            event.preventDefault();
        }
    }
    const doNothing = event => event.preventDefault();

    const renderOecStep = () => {
        let statusClass = 'bg-secondary';
        let statusTitle = 'No MBS codes';
        let statusDate = admission.oec_statusVars.oec_date;

        switch (admission.statusOECStr) {
            case "STATUS_OEC_MISSING_DATA":
                statusClass = 'bg-secondary';
                statusTitle = 'No MBS codes';
                statusDate = '';
                break;
            case "STATUS_OEC_MODERATION":
                statusClass = 'status-scheduled';
                statusTitle = 'OEC Moderation';
                statusDate = '';
                break;
            case "STATUS_OEC_IN_PROGRESS":
                statusClass = 'status-scheduled';
                statusTitle = 'OEC Processing';
                statusDate = '';
                break;
            case "STATUS_OEC_COMPLETED":
                statusClass = 'status-done';
                statusTitle = 'OEC Completed';
                statusDate = admission.oec_statusVars.oec_date;
                break;
            case "STATUS_OEC_PENDING":
                statusClass = 'status-scheduled';
                statusTitle = 'OEC Pending';
                statusDate = '';
                break;
            case "STATUS_OEC_FAILED":
                statusClass = 'status-error';
                statusTitle = 'OEC Failed';
                statusDate = '';
                break;
            case "STATUS_OEC_NOT_SUPPORTED":
                statusClass = 'bg-secondary';
                statusTitle = 'OEC N/A';
                statusDate = '';
                break;
        }
        return (
            <div className={`status-info-step status-info-step1 ${statusClass}`}>
                <div className="content">
                    <div className="d-flex">
                        <strong className="sub-heading">{statusTitle}</strong>
                        {/*<span className="info-tooltip text-white" data-toggle="tooltip"*/}
                        {/*      data-placement="top" title="Tooltip on top">*/}
                        {/*									<i className="icon ico-c-info"/>*/}
                        {/*								</span>*/}
                    </div>
                    <span className="d-block">{statusDate}</span>
                </div>
                <svg className="arrow-shape" viewBox="0 0 24 64" version="1.1"

                     xmlns="http://www.w3.org/2000/svg">
                    <polyline points="0,0 3,0 22,32 3,64 0,64 0,0" fill="none"
                              fillRule="evenodd" stroke="#ffffff" strokeWidth="4px"
                              strokeLinejoin="round"/>
                    <polyline className="cover-line" points="0,0 19,32 0,64 0,0"
                              fill="none" fillRule="evenodd" stroke="none"
                              strokeWidth="4px" strokeLinejoin="round"/>
                </svg>
            </div>
        );
    }

    const renderFundingStep = () => {
        switch (admission.statusFundingStr) {
            default:
            case "STATUS_FUND_NOT_CHECKED":
                if (mainBgClass !== 'bg-secondary') {
                    setMainBgClass('bg-secondary');
                }
                return (
                    <div className="status-info-step bg-secondary">
                        <div className="content">
                            <strong className="sub-heading">Funding unknown</strong>
                        </div>
                    </div>
                );
            case "STATUS_FUND_WARNING":
                if (mainBgClass !== 'status-scheduled') {
                    setMainBgClass('status-scheduled');
                }
                return (
                        <div className="status-info-step status-scheduled">
                        <div className="content">
                            <strong className="sub-heading">Funding {admission.oec_statusVars.acceptedCount}/{admission.oec_statusVars.totalCount}</strong>
                            {/*<span className="d-block">5/5/20 at 5:15pm</span>*/}
                        </div>
                    </div>
                );
            case "STATUS_FUND_REJECTED":
                if (mainBgClass !== 'status-error') {
                    setMainBgClass('status-error');
                }
                return (
                    <div className="status-info-step status-error">
                        <div className="content">
                            <strong className="sub-heading">Funding {admission.oec_statusVars.acceptedCount}/{admission.oec_statusVars.totalCount}</strong>
                        </div>
                    </div>
                );
            case "STATUS_FUND_ACCEPTED":
                if (mainBgClass !== 'bg-success') {
                    setMainBgClass('bg-success');
                }
                return (
                    <div className="status-info-step status-done">
                        <div className="content">
                            <strong className="sub-heading">Funding {admission.oec_statusVars.acceptedCount}/{admission.oec_statusVars.totalCount}</strong>
                        </div>
                    </div>
                );
        }
    }

    return (
        <>
        <h2 className="h4">Funding</h2>
        <div className="funding-box bg-white rounded shadow-sm border p-3 mb-5"  onClick={showModal}>
            <div className={`status-info-hold rounded ${mainBgClass} d-flex align-items-stretch`}>
                {renderOecStep()}
                {renderFundingStep()}
            </div>
        </div>

        </>
    )
}

export default Admission_Funding;