import React from "react";

const useDoctorHospitalData = (data) => {
    // console.log('[useDoctorHospitalData]', data);
    const hospitalOption_Label = item => item.title;
    const hospitalOption = item => ({value: item.id, label: hospitalOption_Label(item), contextData: data.doctors.filter(doctor => doctor.hospitals.filter(hospital => hospital.id === item.id).length), type: 'hospital'});
    const hospitalOptions = (selectedDoctor) => {
        // console.log('[useDoctorHospitalData] hospitalOptions selectedDoctor=',selectedDoctor);
        if (selectedDoctor) {
            return data.doctors.filter(doctor => doctor.id === selectedDoctor.value)[0].hospitals.map(hospitalOption);
        } else {
            return data.doctorHospitals.map(hospitalOption);
        }
    }
    const doctorOption_Label = item => `Dr ${item.firstName} ${item.lastName}`;
    const doctorOption = item => ({value: item.id, label: doctorOption_Label(item), contextData: item, type: 'doctor'});
    const doctorOptions = (selectedHospital) => {
        // console.log('[useDoctorHospitalData] doctorOptions selectedHospital=',selectedHospital);
        if (selectedHospital) {
            return data.doctors.filter(doctor => doctor.hospitals.filter(hospital => hospital.id === selectedHospital.value).length).map(doctorOption);
        } else {
            return data.doctors.map(doctorOption);
        }
    }

    const formatSelectOption = (option, meta) => {
        // console.log('[formatSelectOption]', option, meta);
        if (meta.context === 'value') {
            return option.label;
        } else { // menu
            switch (option.type) {
                case 'doctor':
                    return `${option.label} (${option.contextData.hospitals.map(hospitalOption_Label).join(', ')})`;
                case 'hospital':
                    return `${option.label} (${option.contextData.map(doctorOption_Label).join(', ')})`;
            }
            return `ERROR - unknown type ${option.type}`;
        }
    }

    const buildDoctorOptionById = (doctor_id) => doctorOption(data.doctors.filter(doctor => doctor.id === doctor_id)[0]);
    const buildHospitalOptionById = (hospital_id) => hospitalOption(data.doctorHospitals.filter(hospital => hospital.id === hospital_id)[0]);

    return {hospitalOptions, doctorOptions, formatSelectOption, buildDoctorOptionById, buildHospitalOptionById}
}

export default useDoctorHospitalData;