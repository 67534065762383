import React from "react";
import {useSelector, useDispatch} from "react-redux";

import loadingGif from '../../../../assets/images/loader.gif';
import * as actionTypes from "../../../../store/actions/actionTypes";
import {useCustomLogic} from "../../../../utils/customLogic";
import {archiveAdmissionDocuments2_init} from "../../../../store/actions/admission";
import {useToasts} from "react-toast-notifications";

const ArchiveAdmissionDocumentsLink = ({admission, onHide}) => {
    const dispatch = useDispatch();
    const {archiveAdmissionDocumentsIds} = useSelector(state => state.admission);
    const {role} = useSelector(state => state.auth);
    const {isCustomLogicLifehouse, isCustomLogicJMPH} = useCustomLogic();
    const {addToast} = useToasts();

    const showArchiveModal = () => {
        onHide();
        if (isCustomLogicLifehouse() || isCustomLogicJMPH()) {
            dispatch(archiveAdmissionDocuments2_init(admission, addToast))
        } else {
            dispatch({type: actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS_SHOW, admission});
        }
    }

    if (!admission.haveDocuments || !role.canArchiveAdmissionDocuments) {
        return null;
    } else if(archiveAdmissionDocumentsIds.indexOf(admission.id) > -1) {
        return (
            <a className="dropdown-item disabled pointer"><img src={loadingGif} alt="pointer" />Archive Admission Documents</a>
        );
    } else {
        return (
            <a className="dropdown-item pointer" onClick={showArchiveModal}>Archive Admission Documents</a>
        );
    }
}

export default ArchiveAdmissionDocumentsLink;