import * as actionTypes from "../actions/actionTypes";

const initialState = {
    loading: true,
    reloading: false,
    selectedMenu: false,
    selectedMenu_subAction: false,
    dashboardStats: [],
    webPasSyncIds: [],
    cloneToDemoIds: [],
    generateApiIfcDataIds: [],
    generateApiOecDataIds: [],
    reloadingProgress: [],
    reloadingCustomColumn: [],
    apiError: null,
    apiUrl: null,
}

const reducer = (state =initialState, action) => {
    switch (action.type) {
        case actionTypes.API_ERROR:
            return {
                ...state,
                loading: false,
                reloading: false,
                apiError: action.apiError,
                apiUrl: action.apiUrl,
            }
        case actionTypes.RELOADING_PROGRESS_START:
            return {
                ...state,
                reloadingProgress: [...state.reloadingProgress, action.id]
            };
        case actionTypes.RELOADING_PROGRESS_DONE:
            return {
                ...state,
                reloadingProgress: state.reloadingProgress.filter(value => value !== action.id)
            };
        case actionTypes.RELOADING_CUSTOM_COLUMN_START:
            return {
                ...state,
                reloadingCustomColumn: [...state.reloadingCustomColumn, action.id]
            };
        case actionTypes.RELOADING_CUSTOM_COLUMN_DONE:
            return {
                ...state,
                reloadingCustomColumn: state.reloadingCustomColumn.filter(value => value !== action.id)
            };

        case actionTypes.RELOADING_START:
            return {
                ...state,
                reloading: true,
            };
        case actionTypes.RELOADING_DONE:
            return {
                ...state,
                reloading: false,
            };
        case actionTypes.CLONE_TO_DEMO_START:
            return {
                ...state,
                cloneToDemoIds: [...state.cloneToDemoIds, action.id]
            }
        case actionTypes.CLONE_TO_DEMO_DONE:
            return {
                ...state,
                cloneToDemoIds: state.cloneToDemoIds.filter(value => value !== action.id)
            }

        case actionTypes.GENERATE_API_IFC_START:
            return {
                ...state,
                generateApiIfcDataIds: [...state.generateApiIfcDataIds, action.id]
            }
        case actionTypes.GENERATE_API_IFC_DONE:
            return {
                ...state,
                generateApiIfcDataIds: state.generateApiIfcDataIds.filter(value => value !== action.id)
            }
        case actionTypes.GENERATE_API_OEC_START:
            return {
                ...state,
                generateApiOecDataIds: [...state.generateApiOecDataIds, action.id]
            }
        case actionTypes.GENERATE_API_OEC_DONE:
            return {
                ...state,
                generateApiOecDataIds: state.generateApiOecDataIds.filter(value => value !== action.id)
            }

        case actionTypes.WEB_PAS_SYNC_START:
            return {
                ...state,
                webPasSyncIds: [...state.webPasSyncIds, action.id]
            }
        case actionTypes.WEB_PAS_SYNC_DONE:
            return {
                ...state,
                webPasSyncIds: state.webPasSyncIds.filter(value => value !== action.id)
            }
        case actionTypes.LOADING_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.DASHBOARD_SET_STATS:
            return {
                ...state,
                dashboardStats: action.stats
            }
        case actionTypes.LOADING_DONE:
            return {
                ...state,
                loading: false
            }
        case actionTypes.MENU_ADMISSIONS_LIST:
            return {
                ...state,
                selectedMenu: actionTypes.MENU_ADMISSIONS_LIST,
                selectedMenu_subAction: action.subAction ? action.subAction : false,
            }
        case actionTypes.MENU_DISCHARGE_LIST:
            return {
                ...state,
                selectedMenu: actionTypes.MENU_DISCHARGE_LIST,
                selectedMenu_subAction: action.subAction ? action.subAction : false,
            }
        case actionTypes.MENU_DASHBOARD:
            return {
                ...state,
                selectedMenu: actionTypes.MENU_DASHBOARD,
                selectedMenu_subAction: action.subAction ? action.subAction : false,
            }
        case actionTypes.MENU_REPORTS:
            return {
                ...state,
                selectedMenu: actionTypes.MENU_REPORTS,
                selectedMenu_subAction: action.subAction ? action.subAction : false,
            }
        case actionTypes.MENU_SETTINGS:
            return {
                ...state,
                selectedMenu: actionTypes.MENU_SETTINGS,
                selectedMenu_subAction: action.subAction ? action.subAction : false,
            }
        default:
            return state;
    }
}

export default reducer;