import React from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import ModalSelectDocumentSet_RemovePaymentOption from "./ModalSelectDocumentSet_RemovePaymentOption";
import DownloadAdmissionDocumentFile from "../UI/DownloadAdmissionDocumentFile";

const ModalAdmissionDocumentFiles = () => {
    const {show, admission, document, files} = useSelector(state => state.modal.admissionDocumentFiles);
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_ADMISSION_DOCUMENT_FILES_HIDE});

    const renderItem = (file, key) => {

        return (
            <tr key={key}>
                <td>{file.originalName}</td>
                <td><DownloadAdmissionDocumentFile file={file} /></td>
            </tr>
        );
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>{document.title} files for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                    </dl>
                    <ModalSelectDocumentSet_RemovePaymentOption />
                    <table className="table" cellSpacing="0" width="100%">
                        <thead>
                        <tr>
                            <th><strong className="title">File</strong></th>
                            <th><strong className="title">Download</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {files.map(renderItem)}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalAdmissionDocumentFiles;