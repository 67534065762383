import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import Pager from "../Pager/Pager";
import {useCustomLogic} from "../../utils/customLogic";
import * as actionTypes from "../../store/actions/actionTypes";
import useAdmissionListNavigation from "../../utils/admissionListNavigation";

const AdmissionList_Pager = () => {
    const {page, totalPages} = useSelector(state => state.admissionList);
    const {isCustomLogicLifehouse, isCustomLogicJMPH} = useCustomLogic();
    const dispatch = useDispatch();
    const {pushAdmissionsListUrl} = useAdmissionListNavigation();

    const changePage = (_page) => {
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_PAGE, page: _page});
        pushAdmissionsListUrl(null, null, null, null, null, null, null, null, _page);
    }

    if ((isCustomLogicLifehouse() || isCustomLogicJMPH()) && totalPages && (totalPages > 1)) {
        return <Pager currentPage={page} totalPages={totalPages} changePage={changePage} />
    }
    return null;
}

export default AdmissionList_Pager;