import React, {useRef, useState} from "react";
import useOnClickOutside from "use-onclickoutside";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import ResendSmsLink from "./MoreDrop/ResendSmsLink";
import WebPasSyncLink from "./MoreDrop/WebPasSyncLink";
import CloneToDemoLink from "./MoreDrop/CloneToDemoLink";
import CloneToTestLink from "./MoreDrop/CloneToTestLink";
import CustomLogicItemMoreDrop from "../../CustomLogic/CustomLogicItemMoreDrop";
import LifehouseUploadPdfLink from "./MoreDrop/LifehouseUploadPdfLink";
import ArchiveAdmissionDocumentsLink from "./MoreDrop/ArchiveAdmissionDocumentsLink";
import CopyAdmissionData from "./MoreDrop/CopyAdmissionData";
import ManagerCustomUI from "./MoreDrop/ManagerCustomUI";
import DeletePatientLink from "./MoreDrop/DeletePatientLink";

const AdmissionList_Item_MoreDrop = ({admission}) => {
    const {manager_patientImpersonate_enable} = useSelector(state => state.auth);
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    const popupRef = useRef(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const hidePopup = () => setPopupOpen(false);
    useOnClickOutside(popupRef, hidePopup);

    const togglePopup = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setPopupOpen(!popupOpen);
    }

    const renderImpersonateUI = () => (
            <>
                <div className="dropdown-divider" />
                <a className="dropdown-item pointer" href={`/admission/${admission.id}/impersonate`} target="_blank">View Patient Portal</a>
            </>
    );

    const renderDevUI = () => (
                <>
                    <div className="dropdown-divider" />
                    <CopyAdmissionData admission={admission} />
                    <div className="dropdown-divider" />
                    <CustomLogicItemMoreDrop admission={admission}/>
                    <CloneToDemoLink admission={admission}/>
                    <CloneToTestLink admission={admission}/>
                </>
            );

    return (
        <div className="more-options-drop d-flex" ref={popupRef}>
            <button type="button" className="dropdown-toggle dropdown-toggle-split" onClick={togglePopup}
                    id={`adm_dropdownMenuReference_${admission.id}`} data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" data-reference="parent" >
                <span><i className="icon ico-more" /></span>
            </button>
            {popupOpen && <div className="dropdown-menu dropdown-menu-right show"
                 aria-labelledby={`adm_dropdownMenuReference_${admission.id}`}>
                <ManagerCustomUI admission={admission} onHide={hidePopup}/>
                <Link to={`/admission/${admission.id}`} className="dropdown-item">View admission</Link>
                <ResendSmsLink admission={admission}/>
                <ArchiveAdmissionDocumentsLink admission={admission} onHide={hidePopup}/>
                <DeletePatientLink admission={admission}/>
                <WebPasSyncLink admission={admission} />
                <LifehouseUploadPdfLink admission={admission} />
                {manager_patientImpersonate_enable && renderImpersonateUI()}
                {state_showDevUI && renderDevUI()}
            </div>}
        </div>
    );
}

export default AdmissionList_Item_MoreDrop;