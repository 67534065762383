import React from "react";
import {useSelector, useDispatch} from "react-redux";

import loadingGif from '../../../../assets/images/loader.gif';
import * as actionTypes from "../../../../store/actions/actionTypes";

const ResendSmsLink = ({admission}) => {

    const dispatch = useDispatch();
    const {resendSmsIds} = useSelector(state => state.admission);

    const showResendModal = () => dispatch({type: actionTypes.MODAL_RESEND_SMS_SHOW, admission});

    if (!admission.pp_invitedAt) {
        return null;
    } else if(resendSmsIds.indexOf(admission.id) > -1) {
        return (
            <a className="dropdown-item disabled pointer"><img src={loadingGif} alt="pointer" />Resend SMS</a>
        );
    } else {
        return (
            <a className="dropdown-item pointer" onClick={showResendModal}>Resend SMS</a>
        );
    }
}

export default ResendSmsLink;