import React from "react";
import {useFormState, useForm} from "react-final-form";
import {getProperty} from "dot-prop";

export const rfaDataEnoughForHospital = (rfaFormData) =>
    getProperty(rfaFormData, 'selectedDate')
    && (getProperty(rfaFormData, 'selectedDoctor.value') || getProperty(rfaFormData, 'selectedDoctor.id'))
    // TODO: Refactor for some reasons default value is not working in production in firefox
    && (getProperty(rfaFormData, 'selectedHospital.value') || getProperty(rfaFormData, 'selectedHospital.id'))
;


const RfaForm_Submit = () => {
    const formState = useFormState();
    const form = useForm();

    const markSendSubmit = () => form.change('sendToHospital', true);
    const markDraftSubmit = () => form.change('sendToHospital', false);

    return (
        <div className="form-group">
            <div className="row">
                <div className="col-2 d-flex justify-content-end">
                    <input type="submit" value="Save Draft" className="btn btn-primary" onClick={markDraftSubmit}/>
                </div>
                <div className="col-2 d-flex justify-content-end">
                    <input type="submit" value="Send to hospital" className="btn btn-primary" disabled={!rfaDataEnoughForHospital(formState.values)}  onClick={markSendSubmit}/>
                </div>
            </div>
        </div>
    )
}

export default RfaForm_Submit;