import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from '../../../../store/actions/actionTypes';
import pdfSvg from '../../../../assets/images/pdf.svg';

const AdmissionList_Item_ManagerCustomUI_PreAdmission = ({admission, customUI}) => {
    const dispatch = useDispatch();
    const showSetModal = () => dispatch({type: actionTypes.MODAL_CUSTOM_UI_PRE_ADMISSION_SET_SHOW, admission, customUI})

    const showPreviewModal = () => dispatch({type: actionTypes.MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_SHOW, admission, customUI})
    const showPreviewApiModal = () => dispatch({type: actionTypes.MODAL_CUSTOM_UI_UPLOAD_PREVIEW_PDF_SHOW, admission, customUI})

    if(admission.manager_customUI[customUI.id].apiId || admission.manager_customUI[customUI.id].idLink) {
        return <>
            <button type="button" className="btn-sm-tick btn btn-success10 text-success"><span className="icon ico-checkmark" onClick={showPreviewApiModal}></span></button>
        </>
    }
    if(admission.manager_customUI[customUI.id].statusUpload) {
        return <>
            <button className="btn-pink btn-sm btn btn-success10 text-success" onClick={showPreviewModal}><img src={pdfSvg} /></button>
        </>
    }

    return <button className="btn btn-sm btn-secondary" onClick={showSetModal}>-</button>
}

export default AdmissionList_Item_ManagerCustomUI_PreAdmission;