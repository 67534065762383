import React, {useState} from 'react';
import * as _ from 'lodash';

const Diff = ({data, row1Title = "Original (Existed in Meditech)", row2Title = "Submitted by patient", field1 = 'eRFA', field2 = 'pep', emrView= false, search = ''}) => {
    const renderGroup = group => {
        return (
            <>
                <h1>{group.title}</h1>
                <table>
                    <tr>
                        <td>&nbsp;</td>
                        <td className="mai-table-title">{row1Title}</td>
                        <td className="mai-table-title">{row2Title}</td>
                    </tr>
                    {group.items.map((item, key) => <_Diff_Item title={item.title} value1={item[field1]} value2={item[field2]} key={key}/>)}
                </table>
            </>
        )
    }
    const renderGroupEmr = group => {
        let _items = group.items;
        const headerHighlight = search && group.title.toLowerCase().includes(search.toLowerCase());
        if (search && !group.title.toLowerCase().includes(search.toLowerCase())) {
            _items = group.items.filter(item => itemSearch(item.title, item[field2], search));
            if (!_items.length) {
                return null;
            }
        }
        const _render = () => {
            if(data.previousDataDate) {
                return _items.map((item, key) => <_Diff_Item title={item.title} value1={item[field1]} value2={item[field2]} key={key}/>)
            }
            return _items.map((item, key) => <_Diff_Item_Emr title={item.title} value={item[field2]} key={key} search={search}
                                                      previousDate={data.previousDataDate} previousValue={item[field1]}
            />)
        }

        return (
            <>
                <h1 className={`${headerHighlight ? 'yellow' : ''}`}>{group.title}</h1>
                <table>
                    <tr>
                        <td>&nbsp;</td>
                        {data.previousDataDate && <td className="mai-table-title">{data.previousDataDate}</td>}
                        <td className="mai-table-title">{row2Title}</td>
                    </tr>
                    {_render()}
                </table>
            </>
        )
    }

    if (emrView) {
        return data.diff.groups.map(renderGroupEmr);
    }
    return data.diff.groups.map(renderGroup);
}

export default Diff;

const itemSearch = (title, value, search) => title.toLowerCase().includes(search.toLowerCase()) || (value && value.toLowerCase().includes(search.toLowerCase()));

const _Diff_Item = ({title, value1, value2, }) => {
    const [mouseOver, setMouseOver] = useState(false);

    const handleMouseOver = () => setMouseOver(true);
    const handleMouseOut = () => setMouseOver(false);

    const className1 = (value1 && (value1 !== value2)) ? 'mai-yellow' : "mai-none";

    const className2 = value2 ? (value1 ? ((value1 !== value2) ? 'mai-yellow' : "mai-none") : 'mai-green') : 'mai-none';
    return (
        <tr onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`${mouseOver ? 'diff-grey-bg' : ''}`}>
            <td><span className="mai-none">{title}</span></td>
            <td><span className={className1}>{value1}</span></td>
            <td><span className={className2}>{value2}</span></td>
        </tr>
    )
}

const _Diff_Item_Emr = ({title, value, search, previousDate=null, previousValue=null}) => {
    const [mouseOver, setMouseOver] = useState(false);

    const handleMouseOver = () => setMouseOver(true);
    const handleMouseOut = () => setMouseOver(false);
    return (
        <tr onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`${mouseOver ? 'diff-grey-bg' : ''} ${(search && itemSearch(title, value, search)) ? 'yellow' : ''}`}>
            <td><span className="mai-none">{title}</span></td>
            {previousDate && <td><span className={'mai-none'}>{_.isString(previousValue) ? previousValue : 'NOT_A_STRING'}</span></td>}
            <td><span className={'mai-none'}>{value}</span></td>
        </tr>
    )
}