import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import * as actionTypes from "../../store/actions/actionTypes";
import * as modalActions from "../../store/actions/modal";
import {useToasts} from "react-toast-notifications";
import Admission_ModalInfo from "../Block/Admission_ModalInfo";
import {presentingIllnessCodesOptions} from '../../utils/presentingIllnessCodes';

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: '1000',
    }),
}

const animatedComponents = makeAnimated();

const ModalPresentingIllnessCodes = () => {
    const [selectedOptions, setSelectedOptions] = useState(null);
    const {addToast} = useToasts();
    const {show, admission, initialCodes} = useSelector(state => state.modal.presentingIllnessCodes);

    useEffect(() => {
        if (initialCodes) {
            setSelectedOptions(presentingIllnessCodesOptions.filter(item => initialCodes.includes(item.value)));
        }
    }, [])

    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_PRESENTING_ILLNESS_CODES_HIDE});

    const doSetPresentingIllnessCodes = (event) => {
        event.preventDefault();
        dispatch(modalActions.setPresentingIllnessCodes(admission, selectedOptions, addToast));
    }

    const handleChange = (options) => setSelectedOptions(options);

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                <div className="modal-dialog" role="document" ref={ref} autoFocus={false}>
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <h3>Add Presenting Illness Codes for {admission.title} {admission.surname}, {admission.name}</h3>
                            <Admission_ModalInfo admission={admission} showMobileInfo={false}/>
                            <form onSubmit={doSetPresentingIllnessCodes} className="person-info-form">
                                <div className="form-group mt-5">
                                    <div className="row">
                                        <div className="col-12">
                                            Select presenting illness codes <br />
                                            <Select
                                                id="inputGroupSelect01"
                                                options={presentingIllnessCodesOptions}
                                                styles={customStyles}
                                                isMulti={true}
                                                components={animatedComponents}
                                                value={selectedOptions}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <input type="button" value="Cancel" className="btn" onClick={hideModal}/>
                                            <input type="submit" value="Run OEC" className="btn btn-primary" onClick={doSetPresentingIllnessCodes} disabled={selectedOptions === null}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalPresentingIllnessCodes;