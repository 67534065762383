import React from "react";
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from "../../../store/actions/actionTypes";
import useAdmissionListNavigation from "../../../utils/admissionListNavigation";

const NavBar_HospitalSelector = ({hospital}) => {
    const dispatch = useDispatch();
    const {pushAdmissionsListUrl} = useAdmissionListNavigation();
    const {selectedHospital} = useSelector(state => state.auth);

    const handleSelection = event => {
        dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, ...hospital});
        pushAdmissionsListUrl(hospital.id);
        event.preventDefault();
    }

    if (hospital.id !== selectedHospital.id) {
        return (
            <a className="dropdown-item" href="#" onClick={handleSelection}>{hospital.title}</a>
        );
    } else {
        return (
            <a className="dropdown-item dropdown-current" style={{cursor: 'default'}}>{hospital.title}</a>
        );
    }
}

export default NavBar_HospitalSelector;