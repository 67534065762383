import React, {useState, Fragment} from "react";
import enGB from 'date-fns/locale/en-GB';
import {formatRelative } from 'date-fns';
import EventLogs_Item from "./EventLogs_Item";
import {formatToTimeZone} from "date-fns-timezone";
import {useDispatch, useSelector} from "react-redux";
import * as modalActions from "../../store/actions/modal";

const EventLogs = ({eventTypes, logs}) => {
    const dispatch = useDispatch();
    const selectedHospital = useSelector(state => state.auth.selectedHospital);
    const [selectedType, setSelectedType] = useState('');
    const formatRelativeLocale = {
        lastWeek: "'Last' eeee",
        yesterday: "'Yesterday'",
        today: "'Today'",
        tomorrow: "'Tomorrow'",
        nextWeek: "'Next' eeee",
        other: 'dd.MM.yyyy',
    };

    const locale = {
        ...enGB,
        formatRelative: (token) => formatRelativeLocale[token],
    };

    const renderTab = (label, filterType) => {
        if (!filterType || eventTypes[filterType] || filterType === 'EVENT_TYPE_MESSAGE') {
            return (
                <li className="nav-item">
                    <a className={`pointer nav-link ${(filterType === selectedType) ? ' active' : ''}`} onClick={event => {
                        console.log('Set filter type', filterType);
                        setSelectedType(filterType);
                        event.preventDefault();
                    }}>{label}</a>
                </li>
            );
        } else {
            return (
                <li className="nav-item">
                    <a className={`nav-link nav-link-disabled`}>{label}</a>
                </li>
            );
        }
    }
    const renderTabs = () => {
        return (
            <ul className="nav nav-tabs person-activity-tabs" role="tablist">
                {renderTab('All', '')}
                {renderTab('OEC', 'EVENT_TYPE_ECLIPSE')}
                {renderTab('Patient', 'EVENT_TYPE_PATIENT')}
                {renderTab('Manager', 'EVENT_TYPE_MANAGER')}
                {renderTab('Admin', 'EVENT_TYPE_ADMIN')}
                {renderTab('Payment', 'EVENT_TYPE_STRIPE')}
                {renderTab('Data', 'EVENT_TYPE_CSV')}
                {renderTab('Messages', 'EVENT_TYPE_ADMISSION_MESSAGE')}
            </ul>
        )
    }


    const renderLogsDay = (dailyLogs, key) => {
        console.log('renderLogsDay', selectedType);
        const blockDate = new Date(dailyLogs.date);
        let rows = [];
        if (selectedType) {
            rows = dailyLogs.logs.filter((row) => row.eventTypeConst === selectedType);
        } else {
            rows = dailyLogs.logs;
        }
        if (rows.length) {
            return (
                <Fragment key={key}>
                    <div className="heading">
                        <h3 className="h6"><span>{formatRelative(blockDate, new Date(), {locale})} ({formatToTimeZone(blockDate, "DD MMM YYYY", {timeZone: selectedHospital.timezone})})</span></h3>
                    </div>
                    <ul className="activity-list">
                        {rows.map((data, key) => <EventLogs_Item key={key} data={data}/>)}
                    </ul>
                </Fragment>
            )
        }
        return null;
    }

    const renderRows = () => {
        return <div className="tab-content">
            <div className="tab-pane fade show active" id="tab1" role="tabpanel"
                 aria-labelledby="tab1">
                <div className="tab-pane-holder">
                    {logs.map(renderLogsDay)}
                </div>
            </div>
        </div>
    }

    return (
        <>
            <div className="row">
                <div className="col-9">
                    <h2 className="h4">Latest Activity</h2>
                </div>
                <div className="col-3 d-flex justify-content-end">
                    {/* TODO: add Date filter for events */}
                    {/*<button className="btn btn-white btn-time-opener">*/}
                    {/*    <span className="icon-calendar"><img width="18" height="18"*/}
                    {/*                                         src={calendarSvg}*/}
                    {/*                                         alt="image description" /></span>*/}
                    {/*    <span>All time</span>*/}
                    {/*    <i className="icon  ico-small-down"/>*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="tabs-holder bg-white rounded shadow-sm border mb-4">
                        {renderTabs()}
                        {renderRows()}
                    </div>
                </div>
            </div>
            {/* TODO: add PAGER */}
            {/*<div className="pagination-line">*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-2">*/}
            {/*            <button className="btn btn-sm btn-white btn-prev-page">*/}
            {/*                <i className="icon ico-left-arrow-caret"/>*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*        <div className="col-8">*/}
            {/*            <div className="pagination d-flex justify-content-center">*/}
            {/*                <button className="btn btn-sm btn-white">1</button>*/}
            {/*                <button className="btn btn-sm btn-primary active-page">2</button>*/}
            {/*                <button className="btn btn-sm btn-white">...</button>*/}
            {/*                <button className="btn btn-sm btn-white">6</button>*/}
            {/*                <button className="btn btn-sm btn-white">7</button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="col-2 d-flex justify-content-end">*/}
            {/*            <button className="btn btn-sm btn-white btn-next-page">*/}
            {/*                <i className="icon ico-right-arrow-caret"/>*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
}

export default EventLogs;