import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import {addDays, isToday, format, isYesterday, startOfYesterday} from "date-fns";

import * as actionTypes from '../../../store/actions/actionTypes';
import TabItem from "./TabItem";
import useAdvancedListNavigation from "../../../utils/advancedListNavigation";

const DATE_SELECTOR_THIS_WEEK = 'this-week';
const DATE_SELECTOR_PREVIOUS_WEEK = 'previous-week';

const DischargeList_Tabs = () => {
    const [datePopupVisible, setDatePopupVisible] = useState(false);
    // REFACTOR: use advancedList instead
    const dischargeListVars = useSelector(state => state.dischargeList);
    const selectedHospital = useSelector(state => state.auth.selectedHospital);
    const dispatch = useDispatch();
    const {pushAdvancedListUrl} = useAdvancedListNavigation();

    const ref = React.useRef(null);
    useOnClickOutside(ref, () => setDatePopupVisible(false));

    const showDatePopup = (event) => {
        setDatePopupVisible(!datePopupVisible);
        event.preventDefault();
    }

    const selectDate = date => {
        setDatePopupVisible(false);
        pushAdvancedListUrl(null, date, '', null,null, false, false);
        dispatch({type: actionTypes.DISCHARGE_LIST_SET_DATE, date})
        dispatch({type: actionTypes.DISCHARGE_LIST_SET_KEYWORD})
    }
    const selectDateSelector = dateSelector => {
        setDatePopupVisible(false);
        pushAdvancedListUrl(null, null, '', null,null, 'd', dateSelector);
        dispatch({type: actionTypes.DISCHARGE_LIST_SET_DATE, date: ''})
        dispatch({type: actionTypes.DISCHARGE_LIST_SET_KEYWORD})
        dispatch({type: actionTypes.DISCHARGE_LIST_SET_GROUP, groupType: 'd', groupId: dateSelector})
    }

    const selectToday = event => {
        if (!isDayToday()) {
            selectDate(new Date());
        }
        event.preventDefault();
    }
    const selectYesterday = event => {
        if (!dischargeListVars.date || !isYesterday(dischargeListVars.date) || dischargeListVars.keyword) {
            selectDate(startOfYesterday());
        }
        event.preventDefault();
    }
    const selectThisWeek = event => {
        if (!isDayThisWeek()) {
            selectDateSelector(DATE_SELECTOR_THIS_WEEK);
        }
        event.preventDefault();
    }
    const selectPreviousWeek = event => {
        if (!isDayPreviousWeek()) {
            selectDateSelector(DATE_SELECTOR_PREVIOUS_WEEK);
        }
        event.preventDefault();
    }

    const isDayTabSelected = () => isToday(dischargeListVars.date) || isYesterday(dischargeListVars.date);
    const isDayToday = () => isToday(dischargeListVars.date) && !dischargeListVars.keyword && !dischargeListVars.groupType;
    const isDayYesterday = () => isYesterday(dischargeListVars.date) && !dischargeListVars.keyword;
    const isDayThisWeek = () => (dischargeListVars.groupType === 'd') && (dischargeListVars.groupId === DATE_SELECTOR_THIS_WEEK);
    const isDayPreviousWeek = () => (dischargeListVars.groupType === 'd') && (dischargeListVars.groupId === DATE_SELECTOR_PREVIOUS_WEEK);

    const dateSelectorTitle = !dischargeListVars.date || isDayTabSelected() ? 'Select Date' : format(dischargeListVars.date, 'yyyy-MM-dd');
    const dateSelectorCount = !dischargeListVars.date || isDayTabSelected() ? '' : dischargeListVars.admissions.length;

    const extraFlags = () => {
        let flags = {};
        if (selectedHospital.manager_datePicker_limit_enable) {
            flags.maxDate = addDays(new Date(), selectedHospital.manager_datePicker_limit);
        }
        return flags;
    }

    return (
        <ul className="nav nav-tabs ajax-tabs procedure-list-tabs" role="tablist">
            <TabItem isSelected={isDayToday()} clickHandler={selectToday} label="Today" count={dischargeListVars.count_today} />
            <TabItem isSelected={isDayYesterday()} clickHandler={selectYesterday} label="Yesterday" count={dischargeListVars.count_yesterday} />
            <TabItem isSelected={isDayThisWeek()} clickHandler={selectThisWeek} label="This Week" count={dischargeListVars.count_thisWeek} />
            <TabItem isSelected={isDayPreviousWeek()} clickHandler={selectPreviousWeek} label="Previous Week" count={dischargeListVars.count_previousWeek} />

            <li className="nav-item dropdown" ref={ref}>
                <a className={`nav-link${isDayTabSelected() || dischargeListVars.keyword ? '' : (dischargeListVars.date ? ' active' : '')}`} href="#" onClick={showDatePopup}><span>{dateSelectorTitle}</span> {dateSelectorCount} <i
                    className="icon ico-small-down"></i></a>
                <div className={`date-dropdown-menu tabs-dropdown-menu dropdown-menu-right${datePopupVisible ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                    <DatePicker
                        {...extraFlags()}
                        onChange={selectDate}
                        inline
                    />
                </div>
            </li>
        </ul>
    );
}

export default DischargeList_Tabs;