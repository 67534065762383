export const userTimingMiddleware = () => (next) => (action) => {
    const start = Date.now();
    const result = next(action);
    const doneIn = Date.now() - start;
    if (doneIn > 50) {
        console.log('[userTimingMiddleware] slow action (ms) - ', action.type, doneIn);
    }
    return result;
    // --------------------------------------------

    // Code above used for Browser profiling
    // https://medium.com/@vcarl/performance-profiling-a-redux-app-c85e67bf84ae

    // if (performance.mark === undefined) return next(action);
    //
    // performance.mark(`${action.type}_start`);
    // const result = next(action);
    // performance.mark(`${action.type}_end`);
    // performance.measure(
    //     `${action.type}`,
    //     `${action.type}_start`,
    //     `${action.type}_end`,
    // );
    // return result;
}