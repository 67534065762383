import React from "react";
import {useSelector} from "react-redux";

const DownloadAdmissionDocumentFile = ({file}) => {
    const idToken = useSelector(state => state.auth.idToken);

    const handleDownload = () => {
        // https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication

        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let fileApiPath = `${process.env.REACT_APP_API_PATH}/admission/document-file/${file.id}/download`;
        let headers = new Headers();
        headers.append('Authorization', idToken);

        fetch(fileApiPath, {headers})
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = file.originalName;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });
    }

    return <a className="btn btn-success btn-sm" onClick={handleDownload}>Download</a>
}

export default DownloadAdmissionDocumentFile;