import React from "react";
import {useDispatch, useSelector} from "react-redux";

import useAdmissionListNavigation from "../../../utils/admissionListNavigation";
import * as actionTypes from "../../../store/actions/actionTypes";

const AdmissionList_Tab_TestHospital_TabItem = ({admissionTestGroup}) => {
    const {pushAdmissionsListUrl} = useAdmissionListNavigation();
    const admissionList = useSelector(state => state.admissionList);
    const dispatch = useDispatch();

    const selectGroup = (event) => {
        pushAdmissionsListUrl(null, false, false, null, null, 'test', admissionTestGroup.id);
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_GROUP, groupType: 'test', groupId: admissionTestGroup.id});
        event.preventDefault();
    }

    if ((admissionTestGroup.id !== admissionList.groupId) || (admissionList.groupType !== 'test')) {
        return (
            <a className="dropdown-item" href="#" onClick={selectGroup}>{admissionTestGroup.title}</a>
        )
    } else {
        return (
            <a className="dropdown-item dropdown-current" style={{cursor: 'default'}}>{admissionTestGroup.title}</a>
        )

    }


}

export default AdmissionList_Tab_TestHospital_TabItem;