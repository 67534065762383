import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import {isPast, parse} from "date-fns";

import * as actionTypes from "../../store/actions/actionTypes";
import * as modalActions from "../../store/actions/modal";
import * as admissionListActions from "../../store/actions/admissionList";
import ModalSelectDocumentSet_Actions from "./ModalSelectDocumentSet_Actions";
import ModalOecStatus_Claim from "./ModalOecStatus_Claim";
import ModalOecStatus_Claim_NOI from "./ModalOecStatus_Claim_NOI";
import CollapsableString from "../CollapsableString";
import FundAssessmentCode from "../FundAssessmentCode";
import {setPresentingIllnessCodes} from "../../store/actions/modal";
import {presentingIllnessCodeDescription} from "../../utils/presentingIllnessCodes";
import AdmissionDocumentPdf from "../AdmissionDocumentPdf";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import {useCustomLogic} from "../../utils/customLogic";

const ModalOecStatus = () => {
    const {show, admission, oecClaims} = useSelector(state => state.modal.oecStatus);
    const manager_noMbsUi = useSelector(state => state.auth.manager_noMbsUi);
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());
    const [visibleClaim, setVisibleClaim] = useState(false);
    const {isCustomLogicLifehouse} = useCustomLogic();

    const hideModal = () => {
        dispatch({type: actionTypes.MODAL_OEC_STATUS_HIDE});
        // Modal can change admissions status, so list need to be reloaded
        // dispatch doesnt work for admission page
        // dispatch(admissionListActions.load());
        // reload dont loose filter settings (hospital / date) any more
        // window.location.reload();
    }

    useEffect(() => {
        if (oecClaims.length > 0) {
            setVisibleClaim(oecClaims[0]);
        } else {
            setVisibleClaim(false);
        }
    }, [oecClaims]);

    const renderClaimTitle = claim => {
        switch (claim.statusStr) {
            case "STATUS_NEW":
                return (
                    <>
                        <dt><span className="badge badge-warning">Scheduled</span> at {claim.createdAt}</dt>
                        <dd><FundAssessmentCode fundAssessmentCode={claim.statusStr} /> </dd>
                    </>
                );
            case "STATUS_PROCESSING":
                return (
                    <>
                        <dt><span className="badge badge-warning">Processing</span> at {claim.createdAt}</dt>
                        <dd><FundAssessmentCode fundAssessmentCode={claim.statusStr} /> </dd>
                    </>
                );
            case "STATUS_ERROR":
                return (
                    <>
                        <dt><span className="badge badge-danger">ERROR</span> at {claim.createdAt}</dt>
                        <dd><FundAssessmentCode fundAssessmentCode={claim.statusStr} /> </dd>
                    </>
                );
            case "STATUS_DONE":
            case "STATUS_DONE_ACCEPTED":
            case "STATUS_DONE_REJECTED":
            case "STATUS_DONE_WARNING":
                return (
                    <>
                        <dt><span className="badge badge-success">Done</span> at {claim.oec_request_date}</dt>
                        <dd><FundAssessmentCode fundAssessmentCode={claim.statusStr} /> </dd>
                    </>
                );
        }
    }

    const renderOecSelectors = () => {
        return oecClaims.map((claim, index) => (
            <li className={`oec-selector${visibleClaim && (visibleClaim.id === claim.id) ? ' oec-selector-selected' : ''}`} onClick={() => setVisibleClaim(claim)} key={index} >
                <dl className="person-list">
                    {renderClaimTitle(claim)}
                    {claim.mbsCode && <>
                        <dt>{claim.mbsCode}</dt>
                        <dd><CollapsableString str={claim.mbsCode_description} length={150} /></dd>
                    </>}
                    {claim.illnessCode && <>
                        <dd>{presentingIllnessCodeDescription(claim.illnessCode)}</dd>
                    </>}
                </dl>
            </li>
        ));
    }

    const renderClaim = () => {
        if (visibleClaim) {
            // return <ModalOecStatus_Claim_NOI claim={visibleClaim} admission={admission} />
            return <ModalOecStatus_Claim claim={visibleClaim} admission={admission} />
        } else if (oecClaims.length ===0 ) {
            return <div className="col-9">
                <div className="main-content">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="h4">OEC claims not founded</h2>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return <div className="col-9">
                <div className="main-content">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="h4">Please select claim</h2>
                        </div>
                    </div>
                </div>
            </div>
        }
    }

    const sendToQueue = () => {
        dispatch({type: actionTypes.MODAL_OEC_STATUS_HIDE})
        dispatch(modalActions.sendToQueue(admission))
    }

    const reRunOEC = () => {
        dispatch({type: actionTypes.MODAL_OEC_STATUS_HIDE})
        dispatch(modalActions.reRunOec(admission))
    }

    const codesCountStr = () => {
        if (admission.mbsCodes_count) {
            return `${admission.mbsCodes_count} MBS codes`;
        } else if (admission.illCodes_count) {
            return `${admission.illCodes_count} Presenting Illness Codes`;
        } else {
            return `0 MBS codes`;
        }
    }

    const codesInfo = () => {
        if (admission.mbsCodes_count) {
            return <>
                    <dt>MBS codes</dt>
                    <dd>{admission.mbsCodes_str}</dd>
                </>;
        } else if (admission.illCodes_count) {
            return <>
                <dt>Presenting Illness Codes</dt>
                <dd>{admission.illCodes_str} <a href="#" onClick={setPresentingIllnessCodes}>edit</a></dd>
            </>;
        } else {
            return null;
        }
    }

    const setPresentingIllnessCodes = event => {
        event.preventDefault();
        if (manager_noMbsUi) {
            dispatch({type: actionTypes.MODAL_OEC_STATUS_HIDE});
            dispatch({type: actionTypes.MODAL_PRESENTING_ILLNESS_CODES_SHOW, admission: admission});
        }
    }

    const renderButtons = () => {
        if (admission.statusOECStr === 'STATUS_OEC_MODERATION') {
            return <>
                <p>OEC Auto check is not enabled for hospital {admission.hospitalTitle}</p>
                <button className="btn btn-sm btn-success" onClick={sendToQueue}>Send to OEC Queue</button>
                </>
        } else {
            const _date = parse(admission.admissionDate, 'dd/MM/yyyy', new Date())
            _date.setHours(23,59,59);
            // console.log('[isPast]', , admission.admissionDate, _date);
            return <button className="btn btn-sm btn-warning" disabled={isPast(_date)} onClick={reRunOEC}>Re-run {codesCountStr()}</button>
        }
    }
    const leftSidebarClassname = isCustomLogicLifehouse() ? "col-2" : "col-3";
    const claimClassname = isCustomLogicLifehouse() ? "col-5" : "col-9";

    const renderModal = () => {
        return (
            <div className="modal-dialog modal-wide height-90" role="document" ref={ref}>
                <div className="modal-content height-100">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="row">
                        <div className="col-12">
                            <div className="heading">
                                <h1 className="h2">
                                    <span>OEC Claims for {admission.title} {admission.surname}, {admission.name}</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={leftSidebarClassname}>
                            <aside className="sidebar bg-white rounded shadow-sm border">
                                <ul className="person-info-list">
                                    <li>
                                        <dl className="person-list">
                                            <UrDt admission={admission}/>
                                            <AdmDt admission={admission}/>
                                            <dt>Date</dt>
                                            <dd>{admission.admissionDate} {admission.admissionTime}</dd>

                                        </dl>
                                    </li>
                                    <li>
                                        <dl className="person-list" style={{marginBottom: '10px'}}>
                                            <dt>Fund</dt>
                                            <dd>{admission.fundCompleteTitle}</dd>
                                            <dt>Membership Num</dt>
                                            <dd>{admission.fundMemberNo}</dd>
                                            {admission.adm2_medicareNo && <>
                                                <dt>Medicare Num</dt>
                                                <dd>{admission.adm2_medicareNo}</dd>
                                            </>}
                                            {admission.adm2_medicareRefNo && <>
                                                <dt>Medicare Ref Num</dt>
                                                <dd>{admission.adm2_medicareRefNo}</dd>
                                            </>}
                                            {codesInfo()}
                                        </dl>
                                        {renderButtons()}
                                    </li>
                                    {renderOecSelectors()}
                                </ul>
                            </aside>
                        </div>
                        {renderClaim()}
                        {visibleClaim && isCustomLogicLifehouse()
                            && <div className={claimClassname}>
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12">
                                            <AdmissionDocumentPdf admissionId={admission.id} type="ifc" width="100%"
                                                                  height="750px"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalOecStatus;