import * as actionTypes from '../actions/actionTypes';

const initialState = {
    admission: {},
    resendSmsIds: [],
    archiveAdmissionDocumentsIds: [],
    sendMessageIds: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMISSION_SET:
            return {
                ...state,
                admission: action.admission,
            };
        case actionTypes.ADMISSION_UPDATE:
            if (state.admission && (state.admission.id === action.admission.id)) {
                return {
                    ...state,
                    admission: action.admission,
                };
            }
            return state;
        case actionTypes.RESEND_SMS_START:
            return {
                ...state,
                resendSmsIds: [...state.resendSmsIds, action.id]
            }
        case actionTypes.RESEND_SMS_DONE:
            return {
                ...state,
                resendSmsIds: state.resendSmsIds.filter(value => value !== action.id)
            }
        case actionTypes.SEND_MESSAGE_START:
            return {
                ...state,
                sendMessageIds: [...state.sendMessageIds, action.id]
            }
        case actionTypes.SEND_MESSAGE_DONE:
            return {
                ...state,
                sendMessageIds: state.sendMessageIds.filter(value => value !== action.id)
            }
        case actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_START:
            return {
                ...state,
                sendMessageIds: [...state.archiveAdmissionDocumentsIds, action.id]
            }
        case actionTypes.ARCHIVE_ADMISSION_DOCUMENTS_DONE:
            return {
                ...state,
                sendMessageIds: state.archiveAdmissionDocumentsIds.filter(value => value !== action.id)
            }
        default:
            return state;
    }
}

export default reducer;