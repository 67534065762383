import React from 'react';
import {useCustomLogic} from "../../utils/customLogic";

const JMPH_AltNumber = ({admission}) => {
    const {isCustomLogicJMPH} = useCustomLogic();
    if (!isCustomLogicJMPH()) {
        return null;
    }

    return <>
        <dt>Alt.</dt>
        <dd>{admission.adm2_single_encounter_number}</dd>
    </>
}

export default JMPH_AltNumber;