import {useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";

const useHtmlValidation = () => {
    const [valid, setValid] = useState(false);
    const [validatorVersion, setValidatorVersion] = useState('');
    const [validationErrorsRaw, setValidationErrorsRaw] = useState([]);
    const [loading, setLoading] = useState(false);
    const idToken = useSelector(state => state.auth.idToken);

    const resetValidation = () => setValid(false);

    const apiUrl = `${process.env.REACT_APP_API_PATH}/utils/validate-html`;
    const validateHtml = (text) => {
        setLoading(true);
        axios.post(
            apiUrl,
            {html: text},
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                setLoading(false);
                setValidatorVersion(response.data.validator);
                if (!response.data.valid) {
                    setValidationErrorsRaw(response.data.errors);
                    setValid(false);
                } else {
                    setValidationErrorsRaw([]);
                    setValid(true);
                }
            });
    }

    const validationErrors = () => <p className="error">{validationErrorsRaw.map((item, index) => (
        <>
            {!!index && <br />}
            {item}
        </>
        )
    )}</p>

    const hasErrors = () => validationErrorsRaw.length > 0;

    return {valid, loading, resetValidation, validateHtml, validationErrors, hasErrors, validatorVersion}
}

export default useHtmlValidation;