import React from "react";
import JSONPretty from "react-json-pretty";
import {useSelector} from "react-redux";

const ModalOecStatus_ClaimDevInfo = ({claim}) => {
    const {manager_showOECDebugInfo} = useSelector(state => state.auth);
    if (manager_showOECDebugInfo) {
        return (
            <>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        <h4>OEC Request - {claim.devNotes}</h4>
                        Type: {claim.typeConst}<br/>
                        Status: {claim.statusConst} <br/>
                        Transaction: {claim.id} <br/>
                        Request headers:
                        <JSONPretty id="json-pretty" data={claim.requestHeaders}/><br/>
                        Request body:
                        <JSONPretty id="json-pretty" data={claim.requestBody}/><br/>
                        Response headers:
                        <JSONPretty id="json-pretty" data={claim.responseHeaders}/>
                        Response body:&nbsp;<div className="badge badge-success">{claim.devNotes}</div>
                        <JSONPretty id="json-pretty" data={claim.responseBody}/><br/>
                    </div>
                </div>
            </>
        );
    }
    return null;
}

export default ModalOecStatus_ClaimDevInfo;