import React from 'react';

const TabItem = ({isSelected, clickHandler, label, count}) => {
    return (
        <li className="nav-item">
            <a className={`nav-link${isSelected ? ' active' : ''}`}
               href="#" onClick={clickHandler}><span>{label}</span> {count}</a>
        </li>
    );
}

export default TabItem;