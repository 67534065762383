import React from "react";
import healthRecordLogo from "../../assets/images/health-record-logo.png";

const Admission_MhrImage = ({admission}) => {
    if (admission.mhR_URL) {
        return (
            <a href={admission.mhR_URL} target="_blank" className="logo">
                <img src={healthRecordLogo} alt="image description"/>
            </a>
        )
    } else {
        return (
            <a href="#" className="logo">
                <img src={healthRecordLogo} alt="image description"/>
            </a>
        )
    }
}

export default Admission_MhrImage;