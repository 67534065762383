import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useForm, useFormState} from "react-final-form";
import dotPropImmutable from "dot-prop-immutable";
import {format,parse} from "date-fns";
import PatientSmsGroup_SmsType from "./PatientSmsGroup_SmsType";

const PatientSmsGroup = () => {
    const {change} = useForm();
    const form = useFormState();
    const user = useSelector(state => state.auth);
    const hospitalPatient = useSelector(state => state.hospitalPatient.hospitalPatient);
    let defaultTemplate = '';

    const changeCustomSms = (event) => {
        console.log('[PatientMessageGroup] changeCustomSms', event.target.value);
        change('patientSms_custom', event.target.value);
    }

    const defaultSmsText = () => {
        if (defaultTemplate) {
            const selectedDate = dotPropImmutable.get(form.values, 'selectedDate', false);
            const templateVars = {
                '{{ rfa.firstName }}': dotPropImmutable.get(hospitalPatient, 'name', '_____'),
                '{{ rfa.hospital.title }}': dotPropImmutable.get(form.values, 'selectedHospital.label', '_____'),
                '{{ rfa.doctor.label }}': dotPropImmutable.get(form.values, 'selectedDoctor.label', '_____'),
                '{{ auth0user.firstName }}': dotPropImmutable.get(user, 'firstName', '_____'),
                '{{ rfa.admissionDate.longFormat }}': selectedDate ? format(parse(selectedDate, 'dd/MM/yyyy', new Date()), 'eeee, do MMMM uuuu') : '_____',
            };
            let parsedTemplate = defaultTemplate;
            Object.keys(templateVars).forEach(key => {
                parsedTemplate = parsedTemplate.replace(key, templateVars[key]);
            })
            return parsedTemplate;
        }
        return '';
    }

    const getTextarea = () => {
        switch (dotPropImmutable.get(form.values, 'patientSms_type', 'TYPE_SMS_DEFAULT')) {
            case 'TYPE_SMS_CUSTOM':
                const patientSms_custom = dotPropImmutable.get(form.values, 'patientSms_custom', false);
                return <textarea value={patientSms_custom ? patientSms_custom : defaultSmsText()} onChange={changeCustomSms}/>
            case 'TYPE_SMS_DEFAULT':
                return <textarea value={defaultSmsText()} disabled={true}/>
        }
        return null;
    }

    if (form.values.selectedHospital || form.values.hospital) {
        const hospitalId = form.values.hospital ? form.values.hospital.id : form.values.selectedHospital.value;
        defaultTemplate = user.doctorHospitals.find(hospital => hospital.id === hospitalId).rfa_patient_sms;
        console.log('[PatientMessageGroup] default Hospital sms', defaultTemplate);

        if (defaultTemplate) {
            return (
                <div className="form-group">
                    <div className="row">
                        <div className="col-2 form-title2 margin0">
                            Patient SMS message
                        </div>
                        <div className="col-5">
                            <div className="patient-message">
                                <PatientSmsGroup_SmsType />
                                {getTextarea()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return null;
}

export default PatientSmsGroup;