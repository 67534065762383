import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import ModalLifeHouseERFA_pastProcedures from "./ModalLifeHouseERFA_pastProcedures";
import Admission_MhrImage from "../Lifehouse/Admission_MhrImage";
import {useCustomLogic} from "../../utils/customLogic";

const ModalLifeHouseERFA = () => {
    const manager_lifehouse_enableERFAFrame = useSelector(state => state.auth.manager_lifehouse_enableERFAFrame);
    const {isCustomLogicDemo} = useCustomLogic();
    const dispatch = useDispatch();
    const {show, admission, admTime, selectedMessageTemplate} = useSelector(state => state.modal.lifehouse_erfa);
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_ERFA_HIDE});

    const renderClinicalFlag = (item) => {
        if (item.statusRating !== false) {
            return (<li className="alerts-list-item">
                <svg width="24" height="24" viewBox="0 0 24 24">
                    <defs></defs>
                    <path className={`${item.rating > 1 ? "alert-bg-danger" : "alert-bg-warning"} `}
                          d="M0,12c0,-6.62742 5.37258,-12 12,-12h0c6.62742,0 12,5.37258 12,12v0c0,6.62742 -5.37258,12 -12,12h0c-6.62742,0 -12,-5.37258 -12,-12z"></path>
                    <g transform="matrix(1,0,0,1,9,1)">
                        <text className={`symbol ${item.rating > 1 ? "alert-symbol-danger" : "alert-symbol-warning"} `}
                              dominant-baseline="text-before-edge">!
                        </text>
                    </g>
                </svg>
                <span className="alert-text">{item.title}</span>
            </li>)
        }
    }

    const renderIframe = () => {
        if (isCustomLogicDemo()) {
            return <iframe style={{border: "none"}} width={1070} height={600} src="https://demo.admission.com.au/assets/demo/erfa2.pdf"/>;
        }
        if (manager_lifehouse_enableERFAFrame) {
            return <iframe style={{border: "none"}} width={1000} height={500} src={admission.erfA_URL}/>;
        }
        return <h3>manager_lifehouse_enableERFAFrame - disabled</h3>
    }

    const renderModal = () => {
        if (show) {
            return <div ref={ref} className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body modal-cols-holder">
                        <div className="content-holder">
                            <div className="modal-header">
                                <h5 className="modal-title h1">eRFA for {admission.firstName} {admission.lastName}</h5>
                                <Admission_MhrImage admission={admission} />
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {/* TODO: put values*/}
                            <div className="medical-history-signed-at-block">Completed by patient at {admission.lifehouseFlags_erfa_date[1]} {admission.lifehouseFlags_erfa_date[0]}</div>
                            <div className="direction-right">
                                <div className="direction-left">
                                    {renderIframe()}
                                    <ModalLifeHouseERFA_pastProcedures admission={admission} />
                                </div>
                            </div>
                        </div>

                        {/*<div className="modal-sidebar">*/}
                        {/*    <div className="modal-header">*/}
                        {/*        <h5 className="modal-title h1">Alerts ({admission.clinicalFlags_summaryRating})</h5>*/}
                        {/*        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>*/}
                        {/*            <span aria-hidden="true">×</span>*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        {/*    <ul className="alerts-list">*/}
                        {/*        {admission.clinicalFlags.map(renderClinicalFlag)}*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        }
    }

    return (
        <div className={`modal modal-med-history modal-single-column fade ${show ? 'show' : ''}`} id="modalERFA" tabIndex="-1" role="dialog"
             style={{display: "block", paddingRight: "11px"}} ariaModal="true">
            {renderModal()}
        </div>
    )
}

export default ModalLifeHouseERFA;