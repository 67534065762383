import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import AdmissionDocumentPdf from "../AdmissionDocumentPdf";
import Admission_MhrImage from "../Lifehouse/Admission_MhrImage";
import ClinicalFlags from "./Blocks/ClinicalFlags";

const ModalLifeHouseClinicalFlags = () => {

    const dispatch = useDispatch();
    const {show, admission, admTime, selectedMessageTemplate} = useSelector(state => state.modal.clinicalFlags);
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_CLINICAL_FLAGS_HIDE});

    const renderModal = () => {
        if (show) {
            return <div ref={ref} className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body modal-cols-holder">
                        <div className="content-holder">
                            <div className="modal-header">
                                <h5 className="modal-title h1">Medical History for {admission.firstName} {admission.lastName}</h5>
                                <Admission_MhrImage admission={admission} />

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {/* TODO: put values*/}
                            <div className="medical-history-signed-at-block">Completed by {(admission.lifehouseFlags_hist && admission.lifehouseFlags_hist.signedByManager) ? 'staff member (' + admission.lifehouseFlags_hist.signedByManager+')' : 'patient'} at {admission.lifehouseFlags_hist.completedAt}</div>
                            <div className="direction-right">
                                <div className="direction-left">
                                    <AdmissionDocumentPdf admissionId={admission.id} type="medicalInfo" />
                                </div>
                            </div>
                        </div>
                        <ClinicalFlags admission={admission} hideModal={hideModal}/>
                    </div>
                </div>
            </div>
        }
    }

    return (
        <div className={`modal modal-med-history fade ${show ? 'show' : ''}`} id="modalClinicalFlags" tabIndex="-1" role="dialog"
             style={{display: "block", paddingRight: "11px"}} ariaModal="true">
            {renderModal()}
        </div>
    )
}

export default ModalLifeHouseClinicalFlags;