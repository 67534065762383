import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from 'axios';

import * as constants from "../../utils/constants";
import Spinner from "../Spinner";
import {ignoreComment} from "../../store/actions/lifehouse";
import {useToasts} from "react-toast-notifications";
import useOnClickOutside from "use-onclickoutside";

const LifehouseInternalComments = ({admission, type=1}) => {
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState(true);
    const idToken = useSelector(state => state.auth.idToken);

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API_PATH}/lifehouse/admission/${admission.id}/comments/${type}`,
            {headers: {'Authorization': idToken}}
        )
        .then((response) => {
            console.log('[actions/modal] selectClinicalTeam reload admissions list');
            setComments(response.data.comments)
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Spinner />
    }
    return comments.map((item, key) => <_Item item={item} key={key} />);
}

const _Item = ({item}) => {
    const [mouseOver, setMouseOver] = useState(false);

    const renderStatus = status => {
        switch (status) {
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PENDING:
                return (
                    <div className="badge badge-warning">Pending</div>
                    // <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange btn-25px">Pending</button>
                );
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_CLOSED:
                return (
                    <div className="badge badge-success badge-flex">Closed</div>
                    // <button type="button" className="btn-group-table-button btn btn-25px lifehouse-button-green">Closed</button>
                )
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PAC_IN_PROGRESS:
                return (
                    <div className="badge badge-warning">In Progress</div>
                    // <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange btn-25px">In Progress</button>
                );
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PAC_REQUIRED:
                return (
                    <div className="badge badge-success badge-flex">Required</div>
                    // <button type="button" className="btn-group-table-button btn btn-25px lifehouse-button-green"></button>
                )
            case constants.LIFEHOUSE_COMMENTS_STATUS.STATUS_PAC_NOT_REQUIRED:
                return (
                    <div className="badge badge-danger">Not required</div>
                    // <button type="button" className="btn-group-table-button btn btn-25px lifehouse-button-red">Not required</button>
                )
        }
    }

    const handleMouseOver = () => setMouseOver(true);
    const handleMouseOut = () => setMouseOver(false);

    return (
        <>
            <hr/>
            <div className="row" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div className="col-6">
                    <strong className={`${item.isIgnored ? 'ignored-comment': ''}`}>{item.body}</strong>
                    <br/>
                    <i>{item.createdBy} {item.createdAt}</i> <br/>
                    {item.isIgnored ? <i>Ignored by {item.ignoredBy} {item.ignoredAt}</i> : null}
                </div>
                <div className="col-6 d-flex justify-content-end flex-badges">
                    {renderStatus(item.status)}
                    <_More item={item}/>
                </div>
            </div>
        </>
    )
}

const _More = ({item}) => {
    const {selectedHospital, role} = useSelector(state => state.auth);
    const popupRef = useRef(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const hidePopup = () => setPopupOpen(false);
    useOnClickOutside(popupRef, hidePopup);

    const togglePopup = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setPopupOpen(!popupOpen);
    }
    if (!role.canIgnoreLifehouseComment) {
        return;
    }

    return <div className="more-options-drop btn lifehouse-comments-more" ref={popupRef}>
        <button type="button" className="dropdown-toggle dropdown-toggle-split" onClick={togglePopup}
                id={`adm_dropdownMenuReference_${item.id}`} data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" data-reference="parent">
            <span><i className="icon ico-more"/></span>
        </button>
        {popupOpen && <div className="dropdown-menu dropdown-menu-right show"
                           aria-labelledby={`adm_dropdownMenuReference_${item.id}`}>
            <_IgnoreLink item={item}/>
        </div>}
    </div>
}

const _IgnoreLink = ({item}) => {
    const dispatch = useDispatch();
    const addToast = useToasts();

    const handleIgnore = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(ignoreComment(item.id, item.admissionId, item.type, addToast))
    }

    if (item.isIgnored) {
        return <a className="dropdown-item disabled pointer">Ignore</a>
    } else {
        return (
            <a className="dropdown-item pointer" onClick={handleIgnore}>Ignore</a>
        );
    }
}

export default LifehouseInternalComments;