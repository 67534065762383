import React from 'react';
import {useSelector} from "react-redux";

import EventLogs from "../EventLogs/EventLogs";
import RFAList from "../RFAList/RFAList";

const HospitalPatient = () => {
    const hospitalPatient = useSelector(state => state.hospitalPatient.hospitalPatient);
    return (
        <main className="main">
            <div className="container">
                {/*<FlashMessages admission={admission}/>*/}
                <div className="row">
                    <div className="col-3">
                        <div className="heading">
                            <h1 className="h2"><span>{hospitalPatient.surname}, {hospitalPatient.name}</span></h1>
                        </div>
                    </div>
                    <div className="col-9">
                        {!hospitalPatient.mobilePhone &&
                            <div className="alert alert-danger" role="alert">
                                Mobile phone is not set
                            </div>
                        }
                        {/*{hospitalPatient.sms_opt_out &&*/}
                        {/*    <div className="alert alert-danger" role="alert">*/}
                        {/*        Patient opted out of messages (set in PAS)*/}
                        {/*    </div>*/}
                        {/*}*/}
                        <div className="heading-info d-flex justify-content-end">
                            <div className="btns">
                                <a className="btn btn-sm btn-white-primary" href={`/hp/${hospitalPatient.id}/rfa/add`}>
                                    <svg width="1em" height="12" viewBox="0 0 16 16" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                                    </svg>&nbsp;<span>Add RFA</span>
                                </a>
                                <a className="btn btn-sm btn-white-primary" href={`/hp/${hospitalPatient.id}/edit`}>
                                    <svg width="1em" height="12" viewBox="0 0 16 16" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                                    </svg>&nbsp;<span>Edit Patient</span>
                                </a>
                                {/*<WebPasSyncBtn />*/}
                                {/*<Admission_Pdf admission={admission} />*/}
                                {/*<ResendSmsBtn />*/}
                                {/*{admission.hospital.messages_enableManagerUI && <SendMessageBtn />}*/}
                                {/*{manager_patientImpersonate_enable && <ImpersonateBtn admission={admission}/>}*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <aside className="sidebar bg-white rounded shadow-sm border">
                            <ul className="person-info-list">
                                <li>
                                    <strong className="title">Patient Details</strong>
                                    <strong className="sub-heading d-block">DOB - {hospitalPatient.DOB} <span className="age">(Age {hospitalPatient.age})</span></strong>
                                    <span className="add-info d-block">{hospitalPatient.address}</span>
                                </li>
                                <li>
                                    <strong className="title">Contact</strong>
                                    <dl className="contact-list">
                                        {hospitalPatient.mobilePhone &&
                                            <>
                                                <dt><i className="icon ico-phone"/></dt>
                                                <dd><a href={`tel:${hospitalPatient.mobilePhone}`}>{hospitalPatient.mobilePhone}</a></dd>
                                            </>
                                        }
                                        {hospitalPatient.email &&
                                            <>
                                                <dt><i className="icon ico-letter"/></dt>
                                                <dd><a href={`mailto:${hospitalPatient.email}`}>{hospitalPatient.email}</a></dd>
                                            </>
                                        }
                                    </dl>
                                </li>
                            </ul>
                        </aside>
                    </div>
                    <div className="col-9">
                        <div className="main-content">
                            {/*{renderProgressBlocks()}*/}
                            <h3>RFA</h3>
                            <RFAList items={hospitalPatient.rfa}/>
                            <EventLogs logs={hospitalPatient.logs} eventTypes={hospitalPatient.logs_eventTypes} />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default HospitalPatient;