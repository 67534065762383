import * as actionTypes from '../actions/actionTypes';

const initialState = {
    admissionList: {
        selectAllChecked: false,
        checkedAdmissions: [],
    },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMISSIONS_LIST_BULK_TOGGLE:
            return {
                ...state,
                admissionList: {
                    selectAllChecked: state.admissionList.checkedAdmissions.includes(action.id) ? false : state.selectAllChecked,
                    checkedAdmissions: state.admissionList.checkedAdmissions.includes(action.id) ? [...state.admissionList.checkedAdmissions.filter(value => value !== action.id)] : [...state.admissionList.checkedAdmissions, action.id],
                }
            };
        case actionTypes.ADMISSIONS_LIST_BULK_UNSELECT_ALL:
        case actionTypes.ADMISSIONS_LIST_SET:
            return {
                ...state,
                admissionList: {
                    selectAllChecked: false,
                    checkedAdmissions: [],
                }
            };
        case actionTypes.ADMISSIONS_LIST_BULK_SELECT_ALL:
            return {
                ...state,
                admissionList: {
                    selectAllChecked: true,
                    checkedAdmissions: action.ids,
                }
            };
        default:
            return state;
    }
}

export default reducer;