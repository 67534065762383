import React from 'react';
import {useSelector} from "react-redux";

const AdmissionListMessages = ({admission}) => {
    const {selectedHospital} = useSelector(state => state.auth);
    const admissionList = useSelector(state => state.admissionList);

    const testGroupMessage = () => {
        if (selectedHospital.dev_isTestHospital && admissionList.groupId && (admissionList.groupType === 'test')) {
            const groupMessage = selectedHospital.dev_test_admissionTestGroups.find(group => parseInt(group.id) === parseInt(admissionList.groupId));
            const newDescription = `${groupMessage.title}<br />${groupMessage.descriptionHtml}<br/><a href="${groupMessage.documentationUrl}" target="_blank">Documentation</a>`;
            return <div className="alert alert-info" dangerouslySetInnerHTML={{__html: newDescription }} />
        }
        return null
    }

    return (
        <>
            {testGroupMessage()}
        </>
    );
}

export default AdmissionListMessages;