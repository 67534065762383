import * as actionTypes from './actionTypes';
import axios from 'axios';
import {format, parse} from "date-fns";

export const dischargeListSet = (urlHospitalId, urlDate, urlKeyword, urlGroupType, urlGroupId, urlFilter, urlPage, urlSortColumn, urlSortMode) => {
    return (dispatch, getState) => {
        console.log('[action/dischargeList] dischargeListSet', urlHospitalId, urlDate, urlKeyword, urlGroupType, urlGroupId, urlFilter, urlPage, urlSortColumn, urlSortMode);
        dispatch({type: actionTypes.LOADING_START});
        // dispatch({type: actionTypes.DEV_SET_DISCHARGE_LIST_PROFILER_URL, url: false});
        const dischargeListDate = getState().dischargeList.date ? format(
            getState().dischargeList.date,
            'yyyy-MM-dd'
        ) : false;

        const hospitalId = getState().auth.selectedHospital.id;
        const {keyword, groupType, groupId, filter, page, sortColumn, sortMode } = getState().dischargeList;

        if (urlHospitalId && urlKeyword &&  ((hospitalId !== parseInt(urlHospitalId)) || (keyword !== urlKeyword))) {
            dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, id: urlHospitalId,});
            dispatch({type: actionTypes.DISCHARGE_LIST_SET_KEYWORD, keyword: urlKeyword })
        } else if (urlHospitalId && urlDate &&  ((hospitalId !== parseInt(urlHospitalId)) || (dischargeListDate !== urlDate))) {
            console.log('[action/dischargeList] dispatching hospitalId / date from url', hospitalId, urlHospitalId, dischargeListDate, urlDate);
            dispatch({type: actionTypes.DISCHARGE_LIST_SET_DATE, date: parse(urlDate, 'yyyy-MM-dd', new Date())})
            dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, id: urlHospitalId,})
        } else if (urlHospitalId && urlGroupType && urlGroupId && ((hospitalId !== parseInt(urlHospitalId)) || (groupId !== urlGroupId) || (groupType !== urlGroupType) || (filter !== urlFilter) || (page !== urlPage) || (sortColumn !== urlSortColumn) || (sortMode !== urlSortMode))) {
            console.log('[action/dischargeList] dispatching groupId / groupType / filter / page from url', hospitalId, groupId, urlGroupId, groupType, urlGroupType, (hospitalId !== parseInt(urlHospitalId)), (groupId !== urlGroupId), (groupType !== urlGroupType), (filter !== urlFilter), (page !== urlPage), (sortColumn !== urlSortColumn), (sortMode !== urlSortMode));
            dispatch({type: actionTypes.DISCHARGE_LIST_SET_GROUP, groupType: urlGroupType, groupId: urlGroupId, filter: urlFilter, page: urlPage, sortMode: urlSortMode, sortColumn: urlSortColumn});
            dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, id: urlHospitalId,})
        } else {
            dispatch(dischargeListReload());
        }
    }
};

export const dischargeListReload = () => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.LOADING_START});

        const dischargeListDate = getState().dischargeList.date ? format(
            getState().dischargeList.date,
            'yyyy-MM-dd'
        ) : false;

        const idToken = getState().auth.idToken;
        const hospitalId = getState().auth.selectedHospital.id;
        const {keyword, groupType, groupId, filter, page, sortColumn, sortMode } = getState().dischargeList;

        console.log('[action/dischargeList] dischargeListReload', hospitalId, dischargeListDate);

        let apiEndpoint = '';
        if (groupType && groupId) {
            apiEndpoint = `/discharge-list/admissions/${hospitalId}/group/${groupType}/${groupId}/${sortColumn}/${sortMode}/${page}/${filter}`;
        } else if (keyword) {
            apiEndpoint = `/discharge-list/admissions/${hospitalId}/search/${keyword}`;
        } else {
            apiEndpoint = `/discharge-list/admissions/${hospitalId}/${dischargeListDate}`;
        }
        const startedIn = Date.now();
        axios.get(
            apiEndpoint,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                dispatch({type: actionTypes.DISCHARGE_LIST_SET, ...response.data})
                dispatch({type: actionTypes.LOADING_DONE});
            })
        ;
    }
};