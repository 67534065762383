import React from 'react';
import LifehouseAdmMedPdf_Button from "./LifehouseAdmMedPdf_Button";
import LifehouseAdmissionPanel_ButtonWithStatus from "./LifehouseAdmissionPanel_ButtonWithStatus";
import * as modalActions from "../../../store/actions/modal";
import * as actionTypes from "../../../store/actions/actionTypes";
import {useDispatch} from "react-redux";
import {useCustomLogic} from "../../../utils/customLogic";

const LifehouseAdmissionPanel_PatMedTd = ({admission}) => {
    const dispatch = useDispatch();
    const {isCustomLogicDemo} = useCustomLogic();

    const showLifeHouseAdmissionInfoModal = () => {
        if (isCustomLogicDemo()) {
            dispatch({
                type: actionTypes.MODAL_ADMISSION_DOCUMENT_PDF_SHOW,
                admissionDocument: actionTypes.ADMISSION_DOCUMENT_ADMISSION_INFO,
                admission: admission
            });
        } else {
            dispatch(modalActions.showAdmRegDiff(admission));
        }
    }
    const showClinicalFlagsModal = () => dispatch({type: actionTypes.MODAL_CLINICAL_FLAGS_SHOW, admission: admission});

    const renderPatientDetails = () => {
        if (admission.lifehouse_admPdfUpload) {
            return <LifehouseAdmMedPdf_Button admission={admission}/>
        } else {
            return <LifehouseAdmissionPanel_ButtonWithStatus flag={admission.lifehouseFlags_reg}>
                <button onClick={showLifeHouseAdmissionInfoModal} type="button" className="btn-group-table-button btn btn-success10 text-success">
                    <span className="icon ico-checkmark"></span>
                </button>
            </LifehouseAdmissionPanel_ButtonWithStatus>;
        }
    }
    const renderMedInfo = () => {
        if (admission.lifehouse_medPdfUpload) {
            return <LifehouseAdmMedPdf_Button admission={admission}/>
        } else {
            return <LifehouseAdmissionPanel_ButtonWithStatus flag={admission.lifehouseFlags_hist}>
                {renderHist(admission.clinicalFlags_summaryRating)}
            </LifehouseAdmissionPanel_ButtonWithStatus>;
        }
    }
    const renderHist = (rating) => {
        if (rating === undefined) {
            return <button type="button" className="btn-group-table-button btn btn-gray10">-</button>;
        } else if(rating < 3) {
            return <button onClick={showClinicalFlagsModal} type="button" className="btn-group-table-button btn btn-success-light10 text-success-light">{rating}</button>
        } else if(rating < 6) {
            return <button onClick={showClinicalFlagsModal} type="button" className="btn-group-table-button btn btn-warning10 text-warning">{rating}</button>
        } else {
            return <button onClick={showClinicalFlagsModal} type="button" className="btn-group-table-button btn btn-danger10 text-danger">{rating}</button>;
        }
    }

    const dataTipValue = (manager) => {
        if (!manager) {
            return null;
        }
        return `signed by ${manager}`;
    }

    if (admission.lifehouse_medPdfUpload && admission.lifehouse_admPdfUpload) {
        return <td className="lifehouse-adm-list-btn" colSpan={2}><LifehouseAdmMedPdf_Button admission={admission} extraClass="large-btn"/></td>;
    } else {
        return <>
            <td className={`lifehouse-adm-list-btn ${(admission.lifehouseFlags_reg && admission.lifehouseFlags_reg.signedByManager) ? 'red-border' : ''}`} data-tip={dataTipValue(admission.lifehouseFlags_reg ? admission.lifehouseFlags_reg.signedByManager : '')}>{renderPatientDetails()}
            </td>
            <td className={`lifehouse-adm-list-btn ${(admission.lifehouseFlags_hist && admission.lifehouseFlags_hist.signedByManager) ? 'red-border' : ''}`}  data-tip={dataTipValue(admission.lifehouseFlags_hist ? admission.lifehouseFlags_hist.signedByManager : '')}>{renderMedInfo()}</td>
        </>
    }
}

export default LifehouseAdmissionPanel_PatMedTd;