import React from "react";

const FundAssessmentCode = ({fundAssessmentCode}) => {
    switch (fundAssessmentCode) {
        case "STATUS_DONE_ACCEPTED":
            return <span className="badge badge-success">Accepted</span>;
        case "STATUS_DONE_REJECTED":
            return <span className="badge badge-danger">Rejected</span>;
        case "STATUS_DONE_WARNING":
            return <span className="badge badge-warning">Warning</span>;
        default:
            return <span className="badge badge-warning">ERROR - {fundAssessmentCode}</span>;
    }
    return null;
}

export default FundAssessmentCode;