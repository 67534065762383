import React from 'react';
import {useSelector} from "react-redux";

import {CUSTOM_LOGIC_DEMO, CUSTOM_LOGIC_MARYVALE, useCustomLogic} from "../../utils/customLogic";
import AdvancedList_Bulk_SelectAll from "../AdvancedList/AdvancedList_Bulk_SelectAll";

const DischargeList_SortHeaders = ({_requestSort, _getClassNamesFor}) => {
    const {isCustomLogicHealtheCare,isCustomLogicLifehouse, isCustomLogicESPH, isCustomLogicDemo, isCustomLogicMaryvale} = useCustomLogic();
    const {selectedHospital} = useSelector(state => state.auth);
    // REFACTOR - use advancedList instead
    const {admissions, totalPages} = useSelector(state => state.admissionList);

    const sortingEnabled= (!totalPages) || (totalPages === 1)  || (totalPages === "1");

    const requestSort = _param => {
        if (sortingEnabled) {
            return _requestSort(_param)
        }
        return null;
    }
    const getClassNamesFor = _param => {
        if (sortingEnabled) {
            return _getClassNamesFor(_param)
        }
        return "no-sort";
    }

    const renderManagerCustomUI = () => {
        if (selectedHospital.managerCustomUI_dischargeList.length) {
            return selectedHospital.managerCustomUI_dischargeList.map(item => <th className="no-sort ta-center"><strong className="title">{item.columnTitle}</strong></th>)
        }
    }

    const renderManagerDischargeListComments = () => {
        if (selectedHospital.manager_dischargeList_commentsEnable) {
            return (
                <th className={getClassNamesFor('discharge_comments_count')} onClick={() => requestSort('discharge_comments_count')}><strong className="title">Com</strong></th>
            )
        }
        return null;
    }

    switch (selectedHospital.hospitalConfig.customLogic)
    {
        case "CUSTOM_LOGIC_LIFEHOUSE":
        case CUSTOM_LOGIC_DEMO:
        case "CUSTOM_LOGIC_ESPH":
        case "CUSTOM_LOGIC_HEALTHECARE":
        case "CUSTOM_LOGIC_MARYVALE":
        default:
            return (
                <thead>
                <tr>
                    <th className="no-sort"><AdvancedList_Bulk_SelectAll /></th>
                    <th className={getClassNamesFor('surname')} onClick={() => requestSort('surname')}><strong className="title">Patient</strong></th>
                    {(isCustomLogicMaryvale() || isCustomLogicHealtheCare() || isCustomLogicESPH() || isCustomLogicDemo()) && <th className={getClassNamesFor('admittingDoctor')} onClick={() => requestSort('admittingDoctor')}><strong className="title">Surgeon</strong></th>}
                    <th className={getClassNamesFor('dischargeTime')} onClick={() => requestSort('dischargeTime')}><strong className="title">Dis. Time</strong></th>
                    {renderManagerCustomUI()}
                    {renderManagerDischargeListComments()}
                    <th className="no-sort" />
                </tr>
                </thead>
            );
    }

}

export default DischargeList_SortHeaders;