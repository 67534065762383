import * as actionTypes from "../store/actions/actionTypes";

export const errorEvent = (error, apiUrl) => {
    let apiError = '';
    if (error.response && error.response.status) {
        switch (error.response.status) {
            case 404:
                apiError = 'Object not founded';
                break;
            case 403:
                apiError = 'Access denied';
                break;
            case 500:
                apiError = 'Server error - please send screenshot to the developers';
                if (error.request && error.request.responseText && (error.request.responseText.length < 100)) {
                    apiError = apiError + ' ' + error.request.responseText;
                }

                break;
            default:
                apiError = `UNKNOWN_ERROR ${error.response.status} - please send screenshot to the developers`;
                break;
        }
    }
    console.log(error.request, error.response, error);
    return {type: actionTypes.API_ERROR, apiError: apiError, apiUrl: apiUrl}
}