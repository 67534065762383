import React from 'react';
import {useSelector} from "react-redux";

const DevUi = ({children, mark=true}) => {
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    if (!state_showDevUI) return null;

    if (mark) {
        return <div className="alert alert-warning">{children}</div>;
    }
    return children;
}

export default DevUi;