import React from "react";
import {Field} from "react-final-form";
import {useSelector} from "react-redux";
import {parse, isValid} from "date-fns";

import PhoneField from "../Field/PhoneField";
import RfaForm_DoctorHospital from "./RfaForm_DoctorHospital";
import RfaForm_Values from "./RfaForm_Values";
import DateField from "../Field/DateField";
import RfaForm_Submit from "./RfaForm_Submit";
import RfaForm_FileUploader from "./RfaForm_FileUploader";
import DateMaskField from "../Field/DateMaskField";
import RfaForm_HospitalPatient from "./RfaForm_HospitalPatient";
import PatientSmsGroup from "../FormGroup/PatientSmsGroup";
import AdmissionMessageTemplate from "../AdmissionMessageTemplate/AdmissionMessageTemplate";


const validPhoneNumber = value => (value && ((value.length !== 10) || (value.substr(0,2) !== '04'))) ? 'Must be a number' : undefined;
const validDate = value => {
    // console.log('PARSE ', value, parse(value, 'dd/MM/yyyy', new Date()) );
    const date = parse(value, 'dd/MM/yyyy', new Date())
    return isValid(date) && (date.getFullYear() < 2030) && (date.getFullYear() > 1900) ? undefined : 'Wrong date';
}

const RfaForm_Form = ({handleSubmit, files}) => {
    // console.log('[RfaForm_Form] render');
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    return (
        <form className="person-info-form mt-6" onSubmit={handleSubmit}>
            <RfaForm_HospitalPatient/>

            {/*<div className="form-group">*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-2 form-title2">*/}
            {/*            MBA Codes*/}
            {/*        </div>*/}
            {/*        <div className="col-5">*/}
            {/*            TODO: need codes list*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="form-group">*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-2 form-title2">*/}
            {/*            Upload signed RFA PDF*/}
            {/*        </div>*/}
            {/*        <div className="col-5">*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <RfaForm_DoctorHospital/>
            <div className="form-group">
                <div className="row">
                    <div className="col-2 form-title2">
                        Upload files
                    </div>
                    <div className="col-5">
                        <RfaForm_FileUploader/>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-2 form-title2">
                        Admission Date
                    </div>
                    <div className="col-5">
                        <Field component={DateField}
                               name="selectedDate"/>
                    </div>
                </div>
            </div>
            <AdmissionMessageTemplate />
            <PatientSmsGroup />
            <RfaForm_Submit />
            {state_showDevUI && <RfaForm_Values/>}
        </form>
    );
}

export default RfaForm_Form;