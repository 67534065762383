import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import AdmissionForm from "../../components/FinalForm/Admission/AdmissionForm";
import * as actionTypes from "../../store/actions/actionTypes";
import {addAdmission} from "../../store/actions/admission";

const AddAdmissionView = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: actionTypes.LOADING_DONE});
    }, []);

    return <AdmissionForm title="Add Consult" handleSubmitAction={addAdmission}/>;
}

export default AddAdmissionView;