import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";

const AdmissionList_Bulk_SelectAll = () => {
    const {selectAllChecked} = useSelector(state => state.bulk.admissionList);
    const admissions = useSelector(state => state.admissionList.admissions);
    const hospital = useSelector(state => state.auth.selectedHospital);
    const dispatch = useDispatch();

    if (admissions.length > hospital.manager_massAction_limit) {
        return null;
    }

    const handleSelectAll = () => {
        if (selectAllChecked) {
            dispatch({type: actionTypes.ADMISSIONS_LIST_BULK_UNSELECT_ALL});
        } else {
            dispatch({type: actionTypes.ADMISSIONS_LIST_BULK_SELECT_ALL, ids: admissions.map(item => item.id)});
        }
    }

    return (
        <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" name="checkbox1" id="customCheck1" checked={selectAllChecked} onChange={handleSelectAll}/>
            <label className="custom-control-label" htmlFor="customCheck1" />
        </div>
    );
}

export default AdmissionList_Bulk_SelectAll;