import React from 'react';
import {useForm, useFormState} from "react-final-form";
import dotPropImmutable from "dot-prop-immutable";

const PatientSmsGroup_SmsType = () => {
    const {change} = useForm();
    const form = useFormState();

    const selectedType = dotPropImmutable.get(form.values, 'patientSms_type', 'TYPE_SMS_DEFAULT');
    const patientSms_custom = dotPropImmutable.get(form.values, 'patientSms_custom', false);

    const options = [
        {value: 'TYPE_SMS_DEFAULT', label: 'Default'},
        {value: 'TYPE_SMS_CUSTOM', label: 'Custom'},
        {value: 'TYPE_SMS_NONE', label: 'None'},
    ];

    const changeType = (event) => {
        console.log('[PatientMessageGroup] changeType', event.target.value);
        change('patientSms_type', event.target.value);
    }

    return (
        <ul className="check-list list-unstyled d-flex flex-wrap flex-row">
            {options.map((option, index) => (
                <li key={option.value}>
                    <div className="custom-control custom-radio">
                        <input type="radio" name="patient-message"
                               checked={option.value === selectedType}
                               className="custom-control-input"
                               onChange={changeType}
                               id={`radio_patient-message_${option.value.replace(' ', '_')}`}
                               value={option.value}/>
                        <label className="custom-control-label p-0 m-0"
                               htmlFor={`radio_patient-message_${option.value.replace(' ', '_')}`}>{option.label}{(option.value === 'TYPE_SMS_CUSTOM') && patientSms_custom && <span> *</span>}</label>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default PatientSmsGroup_SmsType;