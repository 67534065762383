import React from "react";
import {useSelector} from "react-redux";
import {useCustomLogic} from "../../utils/customLogic";
import Admission_DevInfo_OechCheckData from "./Admission_DevInfo_OechCheckData";

const Admission_DevInfo = () => {
    // IMPORTANT: dev UI should depend on info, which is not returned for regular users
    // TODO: detect manager_devUI = true + empty devInfo (hack attempt)
    const auth = useSelector(state => state.auth);
    const admission = useSelector(state => state.admission.admission);
    const {isCustomLogicLifehouse, isCustomLogicHealtheCare, isCustomLogicESPH, isCustomLogicMaryvale, isCustomLogicJMPH} = useCustomLogic();
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    //
    const url_apiResponseLifehouse = `${auth.dev_AdminPortalPath}/app/admission/${admission.id}/lifehouseApiResponse`;
    const url_apiResponseHealthe = `${auth.dev_AdminPortalPath}/app/admission/${admission.id}/apiResponse`;
    const url_ifcDebug = `${auth.dev_AdminPortalPath}/app/admission/${admission.id}/ifcDebug`;
    const url_ifcPreview = `${auth.dev_AdminPortalPath}/app/admission/${admission.id}/ifcPreview`;

    const url_eventDepositReceipt = `${auth.dev_AdminPortalPath}/app/eventlog/list?filter[devDanger][type]=&filter[devDanger][value]=&filter[admission][type]=&filter[admission][value]=${admission.id}&filter[eventType][type]=&filter[eventType][value][]=202&filter[eventTitle][type]=&filter[eventTitle][value]=&filter[jsonData][type]=&filter[jsonData][value]=&filter[_page]=1&filter[_sort_by]=id&filter[_sort_order]=DESC&filter[_per_page]=32`;
    const url_admissionDocuments = `${auth.dev_AdminPortalPath}/app/admissiondocument/list?filter%5Bid%5D%5Btype%5D=&filter%5Bid%5D%5Bvalue%5D=&filter%5Badmission%5D%5Btype%5D=&filter%5Badmission%5D%5Bvalue%5D=${admission.id}&filter%5Bstatus%5D%5Btype%5D=&filter%5BjsonDocumentData%5D%5Btype%5D=&filter%5BjsonDocumentData%5D%5Bvalue%5D=&filter%5BjsonPatiendData%5D%5Btype%5D=&filter%5BjsonPatiendData%5D%5Bvalue%5D=&filter%5BcreatedAt%5D%5Btype%5D=&filter%5BcreatedAt%5D%5Bvalue%5D=&filter%5B_page%5D=1&filter%5B_sort_by%5D=id&filter%5B_sort_order%5D=DESC&filter%5B_per_page%5D=32`;
    const url_admissionDatas = `${auth.dev_AdminPortalPath}/app/admissiondata/list?filter%5BadmissionDataType%5D%5Btype%5D=&filter%5BadmissionDataType%5D%5Bvalue%5D=&filter%5BjsonData%5D%5Btype%5D=&filter%5BjsonData%5D%5Bvalue%5D=&filter%5Badmission%5D%5Btype%5D=&filter%5Badmission%5D%5Bvalue%5D=${admission.id}&filter%5Badmission__hospital%5D%5Btype%5D=&filter%5Badmission__hospital%5D%5Bvalue%5D=&filter%5B_page%5D=1&filter%5B_sort_by%5D=id&filter%5B_sort_order%5D=DESC&filter%5B_per_page%5D=32`;
    const url_eventLog = `${auth.dev_AdminPortalPath}/app/eventlog/list?filter%5BdevDanger%5D%5Btype%5D=&filter%5BdevDanger%5D%5Bvalue%5D=&filter%5Badmission%5D%5Btype%5D=&filter%5Badmission%5D%5Bvalue%5D=${admission.id}&filter%5BeventType%5D%5Btype%5D=&filter%5BeventTitle%5D%5Btype%5D=&filter%5BeventTitle%5D%5Bvalue%5D=&filter%5BjsonData%5D%5Btype%5D=&filter%5BjsonData%5D%5Bvalue%5D=&filter%5B_page%5D=1&filter%5B_sort_by%5D=id&filter%5B_sort_order%5D=DESC&filter%5B_per_page%5D=32`;
    const url_stripePayment = `${auth.dev_AdminPortalPath}/app/stripepayment/list?filter%5Bid%5D%5Btype%5D=&filter%5Bid%5D%5Bvalue%5D=&filter%5Bstatus%5D%5Btype%5D=&filter%5Bstatus%5D%5Bvalue%5D=&filter%5BapiHash%5D%5Btype%5D=&filter%5BapiHash%5D%5Bvalue%5D=&filter%5Bamount%5D%5Btype%5D=&filter%5Bamount%5D%5Bvalue%5D=&filter%5Bstripe_checkoutSessionKey%5D%5Btype%5D=&filter%5Bstripe_checkoutSessionKey%5D%5Bvalue%5D=&filter%5Bstripe_checkoutSessionCreatedAt%5D%5Btype%5D=&filter%5Bstripe_checkoutSessionCreatedAt%5D%5Bvalue%5D=&filter%5Badmission%5D%5Btype%5D=&filter%5Badmission%5D%5Bvalue%5D=${admission.id}&filter%5Badmission__hospital%5D%5Btype%5D=&filter%5Badmission__hospital%5D%5Bvalue%5D=&filter%5B_page%5D=1&filter%5B_sort_by%5D=id&filter%5B_sort_order%5D=ASC&filter%5B_per_page%5D=32`;
    const url_pinPaymentCharges = `${auth.dev_AdminPortalPath}/app/pinpaymentcharge/list?filter%5Bid%5D%5Btype%5D=&filter%5Bid%5D%5Bvalue%5D=&filter%5Bstatus%5D%5Btype%5D=&filter%5Badmission%5D%5Btype%5D=&filter%5Badmission%5D%5Bvalue%5D=${admission.id}&filter%5Badmission__hospital%5D%5Btype%5D=&filter%5Badmission__hospital%5D%5Bvalue%5D=&filter%5BapiHash%5D%5Btype%5D=&filter%5BapiHash%5D%5Bvalue%5D=&filter%5BdevCanceledAdmissionId%5D%5Btype%5D=&filter%5BdevCanceledAdmissionId%5D%5Bvalue%5D=&filter%5BisDepositReceiptSent%5D%5Btype%5D=&filter%5BisDepositReceiptSent%5D%5Bvalue%5D=&filter%5BpinPayment_cardId%5D%5Btype%5D=&filter%5BpinPayment_cardId%5D%5Bvalue%5D=&filter%5BpinPayment_chargeId%5D%5Btype%5D=&filter%5BpinPayment_chargeId%5D%5Bvalue%5D=&filter%5BpinPayment_createdAt%5D%5Btype%5D=&filter%5BpinPayment_createdAt%5D%5Bvalue%5D=&filter%5BpinPayment_errorMessage%5D%5Btype%5D=&filter%5BpinPayment_errorMessage%5D%5Bvalue%5D=&filter%5BpinPayment_fees%5D%5Btype%5D=&filter%5BpinPayment_fees%5D%5Bvalue%5D=&filter%5BpinPayment_clientIp%5D%5Btype%5D=&filter%5BpinPayment_clientIp%5D%5Bvalue%5D=&filter%5B_page%5D=1&filter%5B_sort_by%5D=id&filter%5B_sort_order%5D=ASC&filter%5B_per_page%5D=32`;
    const url_logRocket = `https://app.logrocket.com/xwlrmu/patient-engagement-portal?filters=%255B%257B%2522type%2522%253A%2522userID%2522%252C%2522operator%2522%253A%257B%2522name%2522%253A%2522is%2522%252C%2522type%2522%253A%2522IS%2522%252C%2522hasStrings%2522%253Atrue%252C%2522autocompleteEnabled%2522%253Atrue%257D%252C%2522strings%2522%253A%255B%2522${admission.apiHash}%2522%255D%257D%255D`;

    const url_apiLog = `${auth.dev_AdminPortalPath}/app/apilog/list?filter%5Bid%5D%5Btype%5D=&filter%5Bid%5D%5Bvalue%5D=&filter%5Btype%5D%5Btype%5D=&filter%5Brequest_body%5D%5Btype%5D=&filter%5Brequest_body%5D%5Bvalue%5D=${admission.eRFAid}&filter%5Brequest_url%5D%5Btype%5D=&filter%5Brequest_url%5D%5Bvalue%5D=&filter%5BdevData%5D%5Btype%5D=&filter%5BdevData%5D%5Bvalue%5D=&filter%5Bresponse_statusCode%5D%5Btype%5D=&filter%5Bresponse_statusCode%5D%5Bvalue%5D=&filter%5Bresponse_body%5D%5Btype%5D=&filter%5Bresponse_body%5D%5Bvalue%5D=&filter%5B_page%5D=1&filter%5B_sort_by%5D=id&filter%5B_sort_order%5D=DESC&filter%5B_per_page%5D=32`;

    const url_prodaRequests = `${auth.dev_AdminPortalPath}/app/prodawebservicerequest/list?filter%5Bid%5D%5Btype%5D=&filter%5Bid%5D%5Bvalue%5D=&filter%5Brequest_body%5D%5Btype%5D=&filter%5Brequest_body%5D%5Bvalue%5D=&filter%5Bstatus%5D%5Btype%5D=&filter%5BprodaConfig%5D%5Btype%5D=&filter%5BprodaConfig%5D%5Bvalue%5D=&filter%5Btype%5D%5Btype%5D=&filter%5Badmission%5D%5Btype%5D=&filter%5Badmission%5D%5Bvalue%5D=${admission.id}&filter%5BcreatedAt%5D%5Btype%5D=&filter%5BcreatedAt%5D%5Bvalue%5D=&filter%5B_page%5D=1&filter%5B_sort_by%5D=id&filter%5B_sort_order%5D=DESC&filter%5B_per_page%5D=32`;

    const renderHaveApiDeposit = () => {
        if (admission.dev_have_apiDeposit) {
            return (
                <>
                    <dt>WebPAS Deposit Receipt</dt>
                    <dd>EXISTS <a href={url_eventDepositReceipt} target="_blank">admin</a> </dd>
                </>
                    );
        } else {
            return (
                <>
                    <dt>WebPAS Deposit Receipt</dt>
                    <dd>NONE </dd>
                </>
                    );
        }
    }

    const renderCustomLogicInfo = () => {
        if (isCustomLogicJMPH()) {
            return (
                <>
                    <dt>Custom Logic</dt>
                    <dd>JMPH</dd>
                    <dt>isAdmissionOnBookingStage</dt>
                    <dd>{admission.isAdmissionOnBookingStage ? 'TRUE' : 'FALSE'}</dd>
                    <dt>API</dt>
                    <dd>
                        [ <a href={url_apiResponseHealthe} target="_blank">Current API</a> ]
                    </dd>
                    <dt>Patient status</dt>
                    <dd>
                        {admission.adm2_patientStatus}
                    </dd>
                </>
            );
        }
        if (isCustomLogicHealtheCare()) {
            return (
                <>
                    <dt>Custom Logic</dt>
                    <dd>Healthe</dd>
                    <dt>API</dt>
                    <dd>
                        [ <a href={url_apiResponseHealthe} target="_blank">Current API</a> ]
                    </dd>
                </>
            );
        }
        if (isCustomLogicMaryvale()) {
            return (
                <>
                    <dt>Custom Logic</dt>
                    <dd>Maryvale</dd>
                    <dt>API</dt>
                    <dd>
                        [ <a href={url_apiResponseHealthe} target="_blank">Current API</a> ]
                    </dd>
                </>
            );
        }
        if (isCustomLogicESPH()) {
            return (
                <>
                    <dt>Custom Logic</dt>
                    <dd>ESPH</dd>
                    <dt>API</dt>
                    <dd>
                        [ <a href={url_apiResponseHealthe} target="_blank">Current API</a> ]
                    </dd>
                </>
            );
        }
        if (isCustomLogicLifehouse()) {
            return (
                <>
                    <dt>Custom Logic</dt>
                    <dd>Lifehouse</dd>
                    <dt>ERFA</dt>
                    <dd>{admission.eRFAid}
                    </dd>
                    <dt>Lifehouse API</dt>
                    <dd>
                        [ <a href={url_apiLog} target="_blank">ERFA Logs</a> ]&nbsp;
                        [ <a href={url_apiResponseLifehouse} target="_blank">Current API</a> ]
                    </dd>
                </>
            );
        }
        return null;
    }

    if (state_showDevUI) {
        return (
            <>
                <li style={{backgroundColor: '#ffeeee'}}>
                    <strong className="title">Dev Info</strong>
                    <dl className="person-list">
                        {renderCustomLogicInfo()}
                        <dt>IFC</dt>
                        <dd><a href={url_ifcDebug} target="_blank">DEBUG</a>&nbsp;<a href={url_ifcPreview} target="_blank">Preview</a></dd>
                        <dt>PEP id.</dt>
                        <dd>{admission.id} - <a href={`${auth.selectedHospital.hospitalConfig.patientPortalPath}${admission.apiHash}`} target="_blank">{admission.apiHash}</a> </dd>
                        <dt>({admission.dev_count_admissionDocuments}) Admission documents</dt>
                        <dd><a href={url_admissionDocuments} target="_blank">admin</a></dd>
                        <dt>({admission.dev_count_admissionData}) Admission datas</dt>
                        <dd><a href={url_admissionDatas} target="_blank">admin</a></dd>
                        <dt>({admission.dev_count_eventLogs}) Event Log</dt>
                        <dd><a href={url_eventLog} target="_blank">admin</a> </dd>
                        <dt>({admission.dev_count_stripePayments}) Stripe payments</dt>
                        <dd><a href={url_stripePayment} target="_blank">admin</a> </dd>
                        <dt>({admission.dev_count_pinPaymentCharges}) PinPayment Charges</dt>
                        <dd><a href={url_pinPaymentCharges} target="_blank">admin</a> </dd>
                        <dt>({admission.dev_count_prodaRequests}) Proda Requests</dt>
                        <dd><a href={url_prodaRequests} target="_blank">admin</a> </dd>
                        <dt>({admission.dev_count_documentSets}) Document sets</dt>
                        <dd>- </dd>
                        {renderHaveApiDeposit()}
                        <dt>Log Rocket</dt>
                        <dd><a href={url_logRocket} target="_blank">Search</a></dd>
                        <dt>Pre-admission id</dt>
                        <dd>{admission.adm2_admissionPortalID ? admission.adm2_admissionPortalID : 'NONE'}</dd>
                        <dt>Discharge date</dt>
                        <dd>{admission.discharge_date ? admission.discharge_date : 'NOT_SET'}</dd>
                        <dt>Admission Cutoff status</dt>
                        <dd>{JSON.stringify(admission.cutoff)}</dd>
                        <dt>Admission Discharged status</dt>
                        <dd>{JSON.stringify(admission.discharged)}</dd>
                        <dt>Admission completed_payments_count</dt>
                        <dd>{admission.completed_payments_count}</dd>
                    </dl>
                </li>
            </>
        );
    }

    return null;
}

export default Admission_DevInfo;