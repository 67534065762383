import React, {useState, useEffect} from 'react';
import {formatToTimeZone} from 'date-fns-timezone';
import JSONPretty from 'react-json-pretty';
import axios from 'axios';
import {useSelector, useDispatch} from "react-redux";

import loadingGif from "../../assets/images/loader.gif";
import * as actionTypes from "../../store/actions/actionTypes";

const EventLogs_Item = ({data}) => {
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jsonData, setJsonData] = useState(null);
    const idToken = useSelector(state => state.auth.idToken);
    const dispatch = useDispatch();
    const selectedHospital = useSelector(state => state.auth.selectedHospital);

    const eventId = data.id;
    const renderLogsTypeIcon = (data) => {
        switch (data.eventTypeConst) {
            case "EVENT_TYPE_CSV":
                return (
                    <div className="activity-icon success">
                        <i className="icon ico-c-check"/>
                    </div>
                );
            case "EVENT_TYPE_ECLIPSE":
                return (
                    <div className="activity-icon primary">
                        <i className="icon ico-c-check"/>
                    </div>
                );
            case "EVENT_TYPE_PATIENT":
                return (
                    <div className="activity-icon success">
                        <i className="icon ico-user"/>
                    </div>
                );
            case "EVENT_TYPE_ADDED_ADMISSION":
            case "EVENT_TYPE_MANAGER":
                return (
                    <div className="activity-icon primary">
                        <i className="icon ico-user"/>
                    </div>
                );
            case "EVENT_TYPE_ADMIN":
                return (
                    <div className="activity-icon primary">
                        <i className="icon ico-user"/>
                    </div>
                );
            case "EVENT_TYPE_STRIPE":
                return (
                    <div className="activity-icon primary">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi-credit-card-2-back"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M14 3H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                            <path
                                d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1zM1 9h14v2H1V9z"/>
                        </svg>
                    </div>
                );
            case "EVENT_TYPE_ARCHIVED_ADMISSION_DOCUMENT":
                return (
                    <div className="activity-icon grey">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-zip"
                             viewBox="0 0 16 16">
                            <path
                                d="M6.5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.109 0l-.93-.62a1 1 0 0 1-.415-1.074l.4-1.599V7.5zm2 0h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243V7.5z"/>
                            <path
                                d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm5.5-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9v1H8v1h1v1H8v1h1v1H7.5V5h-1V4h1V3h-1V2h1V1z"/>
                        </svg>
                    </div>
                );
            case "EVENT_TYPE_API_DEPOSIT":
            case "EVENT_TYPE_API_DOCUMENT_FILE":
                return (
                    <div className="activity-icon grey">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud"
                             viewBox="0 0 16 16">
                            <path
                                d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                        </svg>
                    </div>
                );
            case "EVENT_TYPE_ADMISSION_MESSAGE":
                return (
                    <div className="activity-icon grey">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat"
                             viewBox="0 0 16 16">
                            <path
                                d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
                        </svg>
                    </div>
                );
        }
    }

    const dataDate = new Date(data.createdAt_str);

    const renderDetails = () => {
        if (expanded && !loading) {
            return <JSONPretty data={jsonData} />;
        }
        return null;
    }

    const toggleDetails = async () => {
        if (data.eventTypeConst === 'EVENT_TYPE_ARCHIVED_ADMISSION_DOCUMENT') {
            // https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication

            let anchor = document.createElement("a");
            document.body.appendChild(anchor);
            let file = `${process.env.REACT_APP_API_PATH}/event-log/${eventId}/file`;
            let headers = new Headers();
            headers.append('Authorization', idToken);

            fetch(file, { headers })
                .then(response => response.blob())
                .then(blobby => {
                    let objectUrl = window.URL.createObjectURL(blobby);

                    anchor.href = objectUrl;
                    anchor.download = 'archived.pdf';
                    anchor.click();

                    window.URL.revokeObjectURL(objectUrl);
                });
        } else {
            setExpanded(!expanded);
        }
    }

    useEffect(() => {
        const loadingEventLog = async () => {
            if (expanded) {
                setLoading(true);
                await axios.get(
                    `/event-log/${eventId}`,
                    {headers: {'Authorization': idToken}}
                )
                .then((response) => {
                    if (response.data.message) {
                        dispatch({type: actionTypes.MODAL_VIEW_ADMISSION_MESSAGE_SHOW, message: response.data.message});
                        setLoading(false);
                        setExpanded(false);
                    } else {
                        let returnData = JSON.stringify(JSON.parse(response.data.data), null, "\t");
                        setJsonData(returnData);
                        setLoading(false);
                    }
                });
            }
        }
        loadingEventLog();
    }, [expanded]);

    const doNothing = (event) => event.preventDefault();

    const renderViewMessageBadge = () => {
        if (data.eventTypeConst === 'EVENT_TYPE_ADMISSION_MESSAGE') {
            return <>
                <a href='#' onClick={doNothing} className="btn btn-sm2 btn-info">View message</a>&nbsp;
            </>
        }
        if (data.eventTypeConst === 'EVENT_TYPE_ARCHIVED_ADMISSION_DOCUMENT') {
            return <>
                <a href='#' onClick={doNothing} className="btn btn-sm2 btn-info">View file</a>&nbsp;
            </>
        }
        return null;
    }

    const renderLoading = () => loading  ? <img src={loadingGif} className="pr-1"/> : null;

    return (
        <li>
            {renderLogsTypeIcon(data)}
            <span className="activity-time pointer" onClick={toggleDetails}>{renderViewMessageBadge()}{formatToTimeZone(dataDate, "hh:mm aa", {timeZone: selectedHospital.timezone})}</span>
            <strong className="activity-title d-block pointer" onClick={toggleDetails}>{renderLoading()}{data.eventTitle}</strong>
            {renderDetails()}
        </li>
    );
}

export default EventLogs_Item;