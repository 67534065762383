import React from "react";
import {useSelector} from "react-redux";

const DownloadAdmissionDocumentPdf = ({doc, extraClass = ''}) => {
    const idToken = useSelector(state => state.auth.idToken);
    console.log('[DownloadAdmissionDocumentPdf]', doc)

    const handleDownload = () => {
        // https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication

        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let fileApiPath = `${process.env.REACT_APP_API_PATH}/admission/document/${doc.id}/download-pdf`;
        let headers = new Headers();
        headers.append('Authorization', idToken);

        fetch(fileApiPath, {headers})
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = doc.title + '.pdf';
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });
    }

    return <button className={`btn btn-secondary ${extraClass}`} type="button"  onClick={handleDownload}>
        Download PDF
    </button>
}

export default DownloadAdmissionDocumentPdf;