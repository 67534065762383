import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";

const ModalViewAdmissionMessage = () => {
    const {show, message} = useSelector(state => state.modal.viewAdmissionMessage);
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());
    const hideModal = () => dispatch({type: actionTypes.MODAL_VIEW_ADMISSION_MESSAGE_HIDE});

    const renderModal = () => {
        return (
            <div className="modal-dialog w-50" role="document" ref={ref}>
                <div className="modal-content">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="modal-body">
                        <div dangerouslySetInnerHTML={{ __html: message.message }} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="viewSentMessageModal" tabIndex="-1">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalViewAdmissionMessage;