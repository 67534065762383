import * as actionTypes from './actionTypes';
import axios from 'axios';
import LogRocket from 'logrocket';
import * as admissionActions from "./admission";
import {webPasTerm} from "../../components/CustomLogic/Terms";
import {errorEvent} from "../../utils/axiosErrors";

export const webPasSync = (admission, addToast = false) => {
    return (dispatch, getState) => {
        console.log('[actions/main] webPasSync');
        const idToken = getState().auth.idToken;
        const selectedHospital = getState().auth.selectedHospital;
        dispatch({type: actionTypes.WEB_PAS_SYNC_START, id: admission.id});

        axios.get(
            `/admission/${admission.id}/web-pas-sync`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/main] webPasSync response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    if (addToast) {
                        const _message = response.data.customStatus ? response.data.customStatus : `${webPasTerm(selectedHospital)}  Sync completed for admission ${admission.label}`;
                        addToast(_message, {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    }
                    dispatch({type: actionTypes.WEB_PAS_SYNC_DONE, id: admission.id});
                    const currentAdmission = getState().admission.admission;
                    if (currentAdmission && (currentAdmission.id === admission.id)) {
                        console.log('[action/main] webPasSync fast reload');
                        dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                    }
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    if (addToast) {
                        const _message = response.data.customStatus ? response.data.customStatus : `Failed ${webPasTerm(selectedHospital)} Sync for admission ${admission.label}`;
                        addToast(_message, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    }
                    dispatch({type: actionTypes.WEB_PAS_SYNC_DONE, id: admission.id});
                }
            });
    }
}

export const cloneToDemo = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[actions/main] cloneToDemo');
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.CLONE_TO_DEMO_START, id: admission.id});

        axios.get(
            `/admission/${admission.id}/clone-to-demo`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/main] cloneToDemo response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`admission ${admission.label} cloned to Demo Hospital`, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.CLONE_TO_DEMO_DONE, id: admission.id});
                } else {
                    addToast(`Failed to clone admission ${admission.label} to Demo Hospital`, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.CLONE_TO_DEMO_DONE, id: admission.id});
                }
            });
    }
}

export const cloneToTest = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[actions/main] cloneToTest');
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.CLONE_TO_DEMO_START, id: admission.id});

        axios.get(
            `/admission/${admission.id}/clone-to-test`,
            {headers: {'Authorization': idToken}}
        )
            .catch((error) => {
                console.log('[action/main] cloneToTest error', error);
                addToast(`Failed to clone admission ${admission.label} to Test Hospital`, {
                    appearance: 'error',
                    autoDismiss: true
                });
                dispatch({type: actionTypes.CLONE_TO_DEMO_DONE, id: admission.id});
            })
            .then((response) => {
                console.log('[action/main] cloneToTest response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`admission ${admission.label} cloned to Test Hospital`, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.CLONE_TO_DEMO_DONE, id: admission.id});
                } else {
                    addToast(`Failed to clone admission ${admission.label} to Test Hospital`, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.CLONE_TO_DEMO_DONE, id: admission.id});
                }
            });
    }
}

export const generateApiIfcData = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[actions/main] generateApiIfcData');
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.GENERATE_API_IFC_START, id: admission.id});

        axios.get(
            `/admission/${admission.id}/generate-api-ifc-data`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/main] generateApiIfcData response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`admission ${admission.label} generated API IFC Data`, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.GENERATE_API_IFC_DONE, id: admission.id});
                } else {
                    addToast(`Failed to generate API IFC data admission ${admission.label}`, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.GENERATE_API_IFC_DONE, id: admission.id});
                }
            });
    }
}
export const generateApiOecData = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[actions/main] generateApiOecData');
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.GENERATE_API_OEC_START, id: admission.id});

        axios.get(
            `/admission/${admission.id}/generate-api-oec-data`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/main] generateApiOecData response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`admission ${admission.label} generated API OEC Data`, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.GENERATE_API_OEC_DONE, id: admission.id});
                } else {
                    addToast(`Failed to generate API OEC data admission ${admission.label}`, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.GENERATE_API_OEC_DONE, id: admission.id});
                }
            });
    }
}

export const demoResetAdmission = (admission, addToast) => {
    return (dispatch, getState) => {
        console.log('[actions/main] demoResetAdmission');
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.GENERATE_API_IFC_START, id: admission.id});

        axios.get(
            `/admission/${admission.id}/demo/reset-admission`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/main] demoResetAdmission response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    addToast(`admission ${admission.label} was reset`, {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.GENERATE_API_IFC_DONE, id: admission.id});
                    dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
                } else {
                    addToast(`Failed to reset admission ${admission.label}`, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    dispatch({type: actionTypes.GENERATE_API_IFC_DONE, id: admission.id});
                }
            });
    }
}