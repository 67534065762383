// ./bin/console app:dev:presenting-illness-codes
export const presentingIllnessCodesOptions = [
    { value: '301', label: '301 - Minor Gynaecological Surgery' },
    { value: '302', label: '302 - Assisted Reproductive services or infertility treatments' },
    { value: '303', label: '303 - Pregnancy related services including Obstetrics' },
    { value: '304', label: '304 - Eye - Laser Surgery / refractive' },
    { value: '305', label: '305 - Eye – Cataracts' },
    { value: '306', label: '306 - Eye – Glaucoma' },
    { value: '309', label: '309 - Cardiac (heart and artery)' },
    { value: '310', label: '310 - Psychiatric' },
    { value: '311', label: '311 - Rehabilitation' },
    { value: '313', label: '313 - Surgical Podiatry' },
    { value: '314', label: '314 - Dialysis for chronic renal failure' },
    { value: '315', label: '315 - Chemotherapy' },
    { value: '318', label: '318 - Teeth/Dental' },
    { value: '319', label: '319 - Palliative care' },
    { value: '320', label: '320 - Medical admission' },
    { value: '321', label: '321 - Gastric Banding and all obesity related treatment' },
    { value: '322', label: '322 - Bone Marrow transfusion or transplant (autologous and allogeneic incl harvesting, cryopreservation)' },
    { value: '323', label: '323 - Spinal Fusion' },
    { value: '324', label: '324 - Back and spinal surgery excluding spinal fusion surgery' },
    { value: '330', label: '330 - Endoscopy including  Colonoscopy and gastroscopy' },
    { value: '331', label: '331 - Cystoscopy' },
    { value: '333', label: '333 - Oesophagoscopy' },
    { value: '334', label: '334 - Shoulder arthroscopy and selected minor shoulder procedures' },
    { value: '335', label: '335 - Ankle arthroscopy and ankle ligament repair' },
    { value: '336', label: '336 - Knee arthroscopy and meniscectomy' },
    { value: '341', label: '341 - Joint replacement - Hip' },
    { value: '342', label: '342 - Joint replacement - Knee' },
    { value: '343', label: '343 - Joint replacement - Shoulder' },
    { value: '344', label: '344 - Joint replacement - Other' },
    { value: '347', label: '347 - Appendicitis' },
    { value: '350', label: '350 - Lithotripsy' },
    { value: '351', label: '351 - Insulin Pumps' },
    { value: '360', label: '360 - Sleep Studies' },
    { value: '361', label: '361 - Removal of Tonsils and Adenoids' },
    { value: '370', label: '370 - Plastic and reconstructive surgery' },
    { value: '375', label: '375 - Cosmetic' },
    { value: '376', label: '376 - Abdominoplasty' },
    { value: '377', label: '377 - Blepharoplasty' },
    { value: '378', label: '378 - Breast Augumentation/Reduction' },
    { value: '379', label: '379 - Labioplasty/vulvoplasty' },
    { value: '380', label: '380 - Lipectomy' },
    { value: '381', label: '381 - Liposuction' },
    { value: '382', label: '382 - Penile Enlargements' },
    { value: '383', label: '383 - Ptosis of skin' },
    { value: '384', label: '384 - Rhinoplasty' },
    { value: '385', label: '385 - Surgical face lifts' },
    { value: '390', label: '390 - Sterilisation' },
    { value: '393', label: '393 - Brain Surgery' },
    { value: '394', label: '394 - Vascular Surgery' },
    { value: '395', label: '395 - Organ Transplant' },
    { value: '399', label: '399 - Unknown or Other Surgery' },
    { value: '400', label: '400 - Miscarriage and termination of pregnancy' },
    { value: '401', label: '401 - Gynaecology' },
    { value: '402', label: '402 - Assisted reproductive services' },
    { value: '403', label: '403 - Pregnancy and birth' },
    { value: '404', label: '404 - Male reproductive system' },
    { value: '405', label: '405 - Cataracts' },
    { value: '406', label: '406 - Eye (not cataracts)' },
    { value: '407', label: '407 - Eye - Laser' },
    { value: '408', label: '408 - Lung and chest' },
    { value: '409', label: '409 - Heart and vascular system' },
    { value: '410', label: '410 - Hospital psychiatric services' },
    { value: '411', label: '411 - Rehabilitation' },
    { value: '412', label: '412 - Palliative care' },
    { value: '413', label: '413 - Kidney and bladder' },
    { value: '414', label: '414 - Dialysis for chronic kidney failure' },
    { value: '415', label: '415 - Chemotherapy, radiotherapy and immunotherapy for cancer' },
    { value: '416', label: '416 - Blood' },
    { value: '417', label: '417 - Bone marrow transfusion or transplant' },
    { value: '418', label: '418 - Dental surgery' },
    { value: '419', label: '419 - Podiatric surgery (provided by a registered podiatric surgeon)' },
    { value: '420', label: '420 - Medical admission (unknown)' },
    { value: '423', label: '423 - Back, neck and spine' },
    { value: '424', label: '424 - Bone, joint and muscle' },
    { value: '425', label: '425 - Joint reconstructions' },
    { value: '426', label: '426 - Joint replacements - other' },
    { value: '427', label: '427 - Joint replacements (hip and knee)' },
    { value: '430', label: '430 - Gastrointestinal endoscopy' },
    { value: '431', label: '431 - Hernia and appendix' },
    { value: '432', label: '432 - Digestive system' },
    { value: '433', label: '433 - Weight loss surgery' },
    { value: '441', label: '441 - Pain management' },
    { value: '442', label: '442 - Pain management with device' },
    { value: '451', label: '451 - Insulin pumps' },
    { value: '452', label: '452 - Diabetes management (excluding insulin pumps)' },
    { value: '460', label: '460 - Sleep studies' },
    { value: '461', label: '461 - Tonsils, adenoids and grommets' },
    { value: '462', label: '462 - Ear, nose and throat' },
    { value: '463', label: '463 - Implantation of hearing devices' },
    { value: '470', label: '470 - Plastic and reconstructive surgery (medically necessary)' },
    { value: '471', label: '471 - Skin' },
    { value: '475', label: '475 - Cosmetic surgery' },
    { value: '478', label: '478 - Breast surgery (medically necessary)' },
    { value: '493', label: '493 - Brain and nervous system' },
    { value: '495', label: '495 - Organ transplant' },
    { value: '499', label: '499 - Unknown or other surgery' },
];

export const presentingIllnessCodeDescription = code => {
    const option = presentingIllnessCodesOptions.filter(item => item.value === code);
    return option.length ? option[0].label : code;
}
