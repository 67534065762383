import React, {useEffect,useState} from 'react';
import {useForm, useFormState} from "react-final-form";
import {getProperty} from "dot-prop";
import FileUploader from "../Field/FileUploader";

const RfaForm_FileUploader = () => {
    const form = useFormState();
    const {change} = useForm();
    const [uploaderHandle, setUploaderHandle] = useState(Math.round(1000000000 * Math.random()));

    const apiHash = getProperty(form, 'values.apiHash');
    const files = getProperty(form, 'values.files', []);

    // TODO: for some reasons uploaderHandle value is overwrited by inital values
    // so I use setTimeout patch
    // useEffect(() => {
    //     change('uploaderHandle', uploaderHandle);
    // }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            change('uploaderHandle', uploaderHandle);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return <FileUploader files={files} apiHash={apiHash} uploaderHandle={uploaderHandle} />
};

export default RfaForm_FileUploader;