import React from "react";
import {formatToTimeZone} from "date-fns-timezone";

const Admission_LastSms = ({admission}) => {
    if (admission.lastSmsSendAt) {
        const lastSmsDate = new Date(admission.lastSmsSendAt);
        return (<>{formatToTimeZone(lastSmsDate, "DD/MM/YYYY hh:mm aa", {timeZone: admission.hospital.timezone})}</>);
    }
    return (<>never</>);
}

export default Admission_LastSms;