import produce from "immer";

import * as actionTypes from '../actions/actionTypes';
import {DISCHARGE_LIST_SET_DATE} from "../actions/actionTypes";

const initialState = {
    date: new Date(),
    count_today: '',
    count_tomorrow: '',
    count_dayPlus2: '',
    count_dayPlus3: '',
    count_WL: null,
    count_all: '',
    count_thisMonth: '',
    count_nextMonth: '',
    count_thisWeek: '',
    count_nextWeek: '',
    admissions: [],
    keyword: false,
    sortColumn: '-',
    sortMode: '-',
    groupType: false,
    groupId: false,
    subfilters: {},
    page: 1, // for now, filter and page are supported for groups only
    filter: 0,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DISCHARGE_LIST_SET_DATE:
            return {
                ...state,
                date: action.date,
                groupType: false,
                groupId: false,
            };
        case actionTypes.DISCHARGE_LIST_SET_PAGE:
            return {
                ...state,
                page: action.page
            }
        case actionTypes.DISCHARGE_LIST_SET_GROUP:
            return {
                ...state,
                groupType: action.groupType,
                groupId: action.groupId,
                filter: action.filter,
                page: action.page,
                sortColumn: action.sortColumn,
                sortMode: action.sortMode,
            };
        case actionTypes.DISCHARGE_LIST_SET_SORT:
            return {
                ...state,
                sortColumn: action.sortColumn,
                sortMode: action.sortMode,
            };
        case actionTypes.DISCHARGE_LIST_SET_KEYWORD:
            return {
                ...state,
                keyword: action.keyword,
                groupType: false,
                groupId: false,
            };
        case actionTypes.DISCHARGE_LIST_SET:
            return {
                ...state,
                // page: 1,
                totalPages: null,
                ...action
            };

        // case actionTypes.SUBFILTER_SET:
        //     return produce(state, draft => {
        //         draft.subfilters[action.subfilter] = action.value;
        //     });
        // case actionTypes.SUBFILTER_RESET:
        //     return produce(state, draft => {
        //         draft.subfilters = {};
        //     });
        case actionTypes.ADMISSION_UPDATE:
            if (state.admissions.length) {
                return produce(state, draft => {
                    const key = Object.keys(state.admissions).find(item => state.admissions[item].id === action.admission.id);
                    draft.admissions[key] = action.admission;
                });
            }
            return state;
        default:
            return state;
    }
}

export default reducer;