import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import {useToasts} from "react-toast-notifications";

import * as actionTypes from "../../store/actions/actionTypes";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import * as lifehouseActions from "../../store/actions/lifehouse";
import * as constants from "../../utils/constants";

const ModalLifehouseSelectClinicalTeam = () => {
    const {show, admission} = useSelector(state => state.modal.lifehouse_selectClinicalTeam);
    const {addToast} = useToasts();
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_SELECT_CLINICAL_TEAM_HIDE});
    const selectNone = () => dispatch(lifehouseActions.selectClinicalTeam(admission, constants.CLINICAL_TEAM_NONE, addToast));
    const selectPac = () => dispatch(lifehouseActions.selectClinicalTeam(admission, constants.CLINICAL_TEAM_PAC, addToast));
    const selectPac2 = () => dispatch(lifehouseActions.selectClinicalTeam(admission, constants.CLINICAL_TEAM_PAC2, addToast));
    const selectPsn = () => dispatch(lifehouseActions.selectClinicalTeam(admission, constants.CLINICAL_TEAM_PSN, addToast));
    const selectPsn2 = () => dispatch(lifehouseActions.selectClinicalTeam(admission, constants.CLINICAL_TEAM_PSN2, addToast));

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content modal-height-200">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>Select clinical team for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                    </dl>
                    <table cellSpacing="0">
                        <tr>
                            <td>
                                <button type="button" className="btn-group-table-button btn btn-gray10" onClick={selectNone}>-</button>
                            </td>
                            <td>
                                <button type="button" className="btn-group-table-button btn btn-lifehouse-pac" onClick={selectPac}>PAC</button>
                            </td>
                            <td>
                                <button type="button" className="btn-group-table-button btn btn-lifehouse-pac" onClick={selectPac2}><span className="icon ico-checkmark"></span></button>
                            </td>
                            <td>
                                <button type="button" className="btn-group-table-button btn btn-lifehouse-psn" onClick={selectPsn}>PSN</button>
                            </td>
                            <td>
                                <button type="button" className="btn-group-table-button btn btn-lifehouse-psn" onClick={selectPsn2}><span className="icon ico-checkmark"></span></button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="documentStatusModal" tabIndex="-1">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalLifehouseSelectClinicalTeam;