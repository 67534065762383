import React from 'react';
import {useSelector} from "react-redux";

const FlashMessages = ({admission}) => {
    const {devSmsTarget_enable, devSmsTarget_phone, selectedHospital} = useSelector(state => state.auth);
    const admissionListProfilerUrl = useSelector(state => state.dev.admissionListProfilerUrl);
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    const devSmsTarget = () => {
        if (devSmsTarget_enable) {
            return <div className="alert alert-warning">All sms will be sent to {devSmsTarget_phone}</div>;
        }
        return null
    }
    const showAdmissionListProfilerUrl = () => {
        if (admissionListProfilerUrl && state_showDevUI) {
            return <div className="alert alert-warning"><a href={admissionListProfilerUrl} target="_blank">Profiler</a></div>;
        }
        return null
    }

    // TODO: refactor
    const hospitalWarnings = () => {
        if (selectedHospital.warnings && (selectedHospital.warnings.length > 0)) {
            return selectedHospital.warnings.map((item, key) => {
                return <div className="alert alert-warning" key={key}>{item}</div>;
            })
        }
        return null;
    }

    const renderAdmissionCancelledMessage = () => {
        if (admission && admission.cancelled) {
            return (
                <div className="alert alert-danger">Admission cancelled</div>
            )
        }
        return null;
    }


    return (
        <>
            {showAdmissionListProfilerUrl()}
            {devSmsTarget()}
            {hospitalWarnings()}
            {renderAdmissionCancelledMessage()}
        </>
    );
}

export default FlashMessages;