import * as actionTypes from './actionTypes';
import axios from 'axios';

export const approvePreAdmissionPdf = (admission, customUI, fileId, fileType) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_CUSTOM_UI_PRE_ADMISSION_SET_HIDE});
        const idToken = getState().auth.idToken;

        console.log('[actions/customUI] approvePreAdmissionPdf', admission.id, customUI, fileId);

        axios.get(
            `${process.env.REACT_APP_API_PATH}/admission/${admission.id}/custom-ui/${customUI.id}/set-pre-admission-info/${fileId}/${fileType}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/customUI] approvePreAdmissionPdf reload admissions list', response.data);
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                const currentAdmission = getState().admission.admission;
                if (currentAdmission && (currentAdmission.id === admission.id)) {
                    dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                }
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
            });
    }
};

export const sendRegMed = (admission, customUI) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        const idToken = getState().auth.idToken;

        console.log('[actions/customUI] sendRegMed', admission.id, customUI);

        axios.get(
            `${process.env.REACT_APP_API_PATH}/admission/${admission.id}/custom-ui/${customUI.id}/sendRegMed`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/customUI] sendRegMed reload admissions list', response.data);
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                const currentAdmission = getState().admission.admission;
                if (currentAdmission && (currentAdmission.id === admission.id)) {
                    dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                }
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
            });
    }
};

export const linkPreAdmission = (admission, customUI, preAdmissionId) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        dispatch({type: actionTypes.MODAL_CUSTOM_UI_PRE_ADMISSION_SET_HIDE});
        const idToken = getState().auth.idToken;

        console.log('[actions/customUI] linkPreAdmission', admission.id, customUI, preAdmissionId);

        axios.get(
            `${process.env.REACT_APP_API_PATH}/admission/${admission.id}/custom-ui/${customUI.id}/link-pre-admission/${preAdmissionId}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/customUI] linkPreAdmission reload admissions list', response.data);
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                const currentAdmission = getState().admission.admission;
                if (currentAdmission && (currentAdmission.id === admission.id)) {
                    dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                }
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
            });
    }
};


export const finishUploadPdf = (admission, customUI, fileId) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        const idToken = getState().auth.idToken;

        console.log('[actions/customUI] finishUploadPdf', admission.id, customUI, fileId);

        axios.get(
            `${process.env.REACT_APP_API_PATH}/admission/${admission.id}/custom-ui/${customUI.id}/finish-upload-pdf/${fileId}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/customUI] finishUploadPdf reload admissions list', response.data);
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                const currentAdmission = getState().admission.admission;
                if (currentAdmission && (currentAdmission.id === admission.id)) {
                    dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                }
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
            });
    }
};

export const unsetPdf = (admission, customUI) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.RELOADING_PROGRESS_START, id: admission.id});
        const idToken = getState().auth.idToken;

        console.log('[actions/customUI] unsetPdf', admission.id, customUI);

        axios.get(
            `${process.env.REACT_APP_API_PATH}/admission/${admission.id}/custom-ui/${customUI.id}/unset-pdf`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[actions/customUI] unsetPdf reload admissions list', response.data);
                dispatch({type: actionTypes.RELOADING_PROGRESS_DONE, id: admission.id});
                const currentAdmission = getState().admission.admission;
                if (currentAdmission && (currentAdmission.id === admission.id)) {
                    dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                }
                dispatch({type: actionTypes.ADMISSION_UPDATE, admission: response.data.admission});
            });
    }
};