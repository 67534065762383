import React, {useState} from "react";
import {useDispatch} from "react-redux";

import * as modalActions from "../../../store/actions/modal";

const AdmissionList_Item_SelectDocumentSet = ({admission}) => {
    const dispatch = useDispatch();

    const showModal = event => {
        dispatch(modalActions.showDocumentSetsForAdmission(admission));
        event.preventDefault();
    }
    const doNothing = event => event.preventDefault();

    if (admission.pp_available) {
        return (
            <div className="status-info-holder rounded d-flex align-items-stretch">
                <div className="select-document-set d-flex align-items-stretch">
                    <a href="#" className="link-set rounded" onClick={showModal}>
                        <i className="icon ico-document"/>
                        <span>Select document set</span>
                    </a>
                </div>
            </div>
        );
    } else {
        switch (admission.pp_availability_const) {
            default:
            case "STATUS_PPA_NA_EMPTY_PHONE":
                return (
                    <div className="status-info-holder rounded d-flex align-items-stretch">
                        <div className="select-document-set d-flex align-items-stretch">
                            <a href="#" className="link-set rounded status-red" onClick={doNothing}>
                                <i className="icon ico-document"/>
                                <span>Cant enable patient portal - mobile phone is not specified</span>
                            </a>
                        </div>
                    </div>
                );
            case "STATUS_PPA_NA_INVALID_PHONE":
                return (
                    <div className="status-info-holder rounded d-flex align-items-stretch">
                        <div className="select-document-set d-flex align-items-stretch">
                            <a href="#" className="link-set rounded status-red" onClick={doNothing}>
                                <i className="icon ico-document"/>
                                <span>{admission.mobilePhone} is not a valid mobile phone - Cant enable patient portal</span>
                            </a>
                        </div>
                    </div>
                );
        }
        // TODO: switch

    }
}

export default AdmissionList_Item_SelectDocumentSet;