import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../Spinner";
import {devUIState} from "../../../store/actions/dev";

const NavBar_DevUI = () => {
    const [loading, setLoading] = useState(false);
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);
    const dispatch = useDispatch();

    const changeUiState = state => {
        setLoading(true);
        dispatch(devUIState(state, stopLoading));
    }
    const stopLoading = () => setLoading(false);

    const doNothing = () => {}
    const disableDevUI = () => changeUiState(false);
    const enableDevUI = () => changeUiState(true);

    if (loading) {
        return <a className="dropdown-item" title="Developer UI - ENABLED" onClick={doNothing}><span className="mr-1"><Spinner height={15} width={15}/></span>Developer UI</a>
    }else if (state_showDevUI) {
        return <a className="dropdown-item pointer" title="Developer UI - ENABLED" onClick={disableDevUI}><input type="checkbox" checked={true} className="mr-1"  readOnly={true}/>Developer UI</a>
    } else {
        return <a className="dropdown-item pointer" title="Developer UI - DISABLED" onClick={enableDevUI}><input type="checkbox" className="mr-1" readOnly={true}/>Developer UI</a>
    }
}

export default NavBar_DevUI;