import React from 'react';
import {useSelector} from "react-redux";
import AdmissionList_Header_ManagerCustomUI_UploadPdf from "./AdmissionList_Header_ManagerCustomUI_UploadPdf";

const AdmissionList_Header_ManagerCustomUI = ({position, getClassNamesFor, requestSort}) => {
    const {managerCustomUI_admissionListUploadPdf, managerCustomUI_admissionListPreAdmission, managerCustomUI_sendRegMed, managerCustomUI_comments} = useSelector(state => state.auth.selectedHospital);

    const renderUploadPdf = () => {
        if (!managerCustomUI_admissionListUploadPdf || !managerCustomUI_admissionListUploadPdf.length) {
            return null;
        }
        return managerCustomUI_admissionListUploadPdf.map((customUI, key) => {
            if (position === customUI.position) {
                return <th className="no-sort"><strong className="title" key={key}>{customUI.columnTitle}</strong></th>
            }
        })
    }
    const renderPreAdmissionIntegration = () => {
        if (!managerCustomUI_admissionListPreAdmission || !managerCustomUI_admissionListPreAdmission.length) {
            return null;
        }
        return managerCustomUI_admissionListPreAdmission.map((customUI, key) => {
            if (position === customUI.position) {
                return <th className="no-sort"><strong className="title" key={key}>{customUI.columnTitle}</strong></th>
            }
        })
    }

    const renderComments = () => {
        if (!managerCustomUI_comments || !managerCustomUI_comments.length) {
            return null;
        }
        return managerCustomUI_comments.map((customUI, key) => {
            if (position === customUI.position) {
                return <th className={`${getClassNamesFor(customUI.sort_property)} custom-ui-sort-header`} onClick={() => requestSort(customUI.sort_property, false)}><strong className="title" key={`comm_${key}`}>{customUI.header_title}</strong></th>
            }
        })
    }

    const renderSendMedReg = () => {
        if (!managerCustomUI_sendRegMed || !managerCustomUI_sendRegMed.length) {
            return null;
        }
        return managerCustomUI_sendRegMed.map((customUI, key) => {
            if (position === customUI.position) {
                return <>
                    <th className={getClassNamesFor('customui_reg_sort')} onClick={() => requestSort('customui_reg_sort')}><strong className="title" key={`reg_${key}`}>REG</strong></th>
                    <th className={getClassNamesFor('customui_med_sort')} onClick={() => requestSort('customui_med_sort')}><strong className="title" key={`med_${key}`}>MED</strong></th>
                </>
            }
        })
    }

    return <>
        {renderUploadPdf()}
        {renderPreAdmissionIntegration()}
        {renderSendMedReg()}
        {renderComments()}
    </>
}

export default AdmissionList_Header_ManagerCustomUI;