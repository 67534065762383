import React from "react";
import PhoneInput from "react-phone-input-2";
import {useForm} from "react-final-form";

const PhoneField = (props) => {
    console.log('[PhoneField]', props);
    const {change} = useForm();
    const {
        input: { value, onChange, name, onFocus, onBlur },
        meta
    } = props;

    const handleFocus = (event) => {
        console.log('[PhoneField] on focus', name);
        if ((value.length <= 2) && (value !== '04')) {
            change(name, '04');
        }
        onFocus(event);
    };

    const handleBlur = (event) => {
        console.log('[PhoneField] on blur', name);
        if (value === '04') {
            change(name, '');
        }
        onBlur(event);
    };

    return <PhoneInput
                inputClass={`${meta.error && meta.touched && 'form-error'}`}
                country={'au'}
                onlyCountries={['au']}
                disableDropdown={true}
                // countryCodeEditable={false}
                disableCountryCode={true}
                // enableAreaCodes={true}
                value={value}
                // autoFormat={false}
                onChange={onChange}
                specialLabel=""
                placeholder="04__ ___ ___"
                masks={{au: '.... ... ...'}}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
    ;
}

export default PhoneField;