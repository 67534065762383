import React from "react";
import {Field} from "react-final-form";
import {useSelector} from "react-redux";

import DateField from "../Field/DateField";
import AdmissionForm_Submit from "./AdmissionForm_Submit";
import Patient from "../FormGroup/Patient";
import Dev_Values from "../FormGroup/Dev_Values";

const Admission_Form = ({handleSubmit, title }) => {
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    return (
        <form className="person-info-form mt-6" onSubmit={handleSubmit}>
            <Patient/>
            <div className="form-group">
                <div className="row">
                    <div className="col-2 form-title2">
                        Admission Date / Time
                    </div>
                    <div className="col-3">
                        <Field component={DateField}
                               name="selectedDate"/>
                    </div>
                    <div className="col-3">
                        <Field component="input" name="admissionTime" className="form-control"
                               placeholder="Time"/>
                    </div>
                </div>
            </div>
            {/*<div className="form-group">*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-2 form-title2">*/}
            {/*            PMS integration*/}
            {/*        </div>*/}
            {/*        <div className="col-3">*/}
            {/* TODO: Relabel, add unique string property to AdmissionPrivatePractice? */}

            {/*            <Field component="input" name="patientCode" className="form-control"*/}
            {/*                   autoFocus={true} placeholder="Patient Code"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <AdmissionForm_Submit title={title}/>
            {state_showDevUI && <Dev_Values/>}
        </form>
    );
}

export default Admission_Form;