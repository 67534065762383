import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from '../../../store/actions/actionTypes';

const AdmissionList_SubFilter_Item = ({value, label, handle}) => {
    const dispatch = useDispatch();
    const subfilters = useSelector(state => state.admissionList.subfilters);

    const dispatchSet = (newValue) => dispatch({type: actionTypes.SUBFILTER_SET, subfilter: handle, value: newValue});
    const toggle = () => {
        if (hasSubfilter()) {
            dispatchSet(subfilters[handle].filter(_v => _v !== value));
        } else {
            if (subfilters[handle]) {
                dispatchSet([...subfilters[handle], value]);
            } else {
                dispatchSet([value]);
            }
        }
    }

    const hasSubfilter = () => (subfilters[handle] !== undefined) && (subfilters[handle].includes(value));
    const btnClassName = () => hasSubfilter() ? "btn-teal" : "btn-dark-gray";

    return <li className="table-buttons-item"><a href="#" onClick={toggle} className={`btn btn-sm ${btnClassName()}`}>{label}</a></li>
}

export default AdmissionList_SubFilter_Item;