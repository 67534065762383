import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from '../../../../store/actions/actionTypes';

const AdmissionList_Item_ManagerCustomUI_Comments = ({admission, customUI}) => {
    const dispatch = useDispatch();
    const showCommentsModal = () => dispatch({type: actionTypes.MODAL_COMMENTS_SHOW, admission: admission, commentsType: customUI.comment_type, modalHeader: customUI.modal_header});
    const UIdata = admission.manager_customUI[customUI.id];


    const renderCommentsBtn = () => {
        if (UIdata.comments_count > 0) {
            switch (UIdata.comments_status) {
                case 1:
                    return (
                        <button type="button" className="btn-group-table-button btn btn-gray10 lifehouse-button-orange" onClick={showCommentsModal}>{UIdata.comments_count}</button>
                    );
                case 2:
                    return (
                        <button type="button" className="btn-group-table-button btn lifehouse-button-green" onClick={showCommentsModal}>{UIdata.comments_count}</button>
                    )
            }
        }

        return (
            <button type="button" className="btn-group-table-button btn btn-gray10" onClick={showCommentsModal}>-</button>
        );
    }

    return <td>{renderCommentsBtn()}</td>
}

export default AdmissionList_Item_ManagerCustomUI_Comments;