import React from "react";
import Select from "react-select";
// import makeAnimated from "react-select/animated/dist/react-select.esm";

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        zIndex: '1000',
    }),
}
// const animatedComponents = makeAnimated();


const DynamicSelectField = (props) => {
    // console.log('[DynamicSelectField]', props);
    const {
        input: { value, onChange, name, deps },
        placeholder, options, formatSelectOption, //change
    } = props;

    // automated selection. Not working any more react-select removed change function??
    // if (!value && (options.length === 1)) {
    //     change(options[0]);
    // }

    return (
        <Select
            id={name}
            options={options}
            styles={customStyles}
            isMulti={false}
            // components={animatedComponents}
            value={value}
            onChange={onChange}
            {...deps}
            placeholder={placeholder}
            isClearable={true}
            formatOptionLabel={formatSelectOption}
        />
    );
}

export default DynamicSelectField;