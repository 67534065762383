import React from 'react';

const RFAList_item = ({item}) => {
    const renderStatusAndActions = () => {
        switch (item.statusConst) {
            case "STATUS_DRAFT":
                return <>
                    <td>Draft</td>
                    <td>
                        <a className="btn btn-sm btn-white-primary" href={`/hp/${item.hospitalPatient.id}/rfa/${item.id}/edit`}>
                            <span>Edit</span>
                        </a>
                    </td>
                </>;
            case "STATUS_SCHEDULED":
                return <>
                    <td>Scheduled for delivery</td>
                    <td>&nbsp;</td>
                </>;
            case "STATUS_PROCESSING":
                return <>
                    <td>Processing</td>
                    <td>&nbsp;</td>
                </>;
            case "STATUS_SENT":
                return <>
                    <td>Sent to Hospital</td>
                    <td>&nbsp;</td>
                </>;
            case "STATUS_VIEWED":
                return <>
                    <td>Viewed by Hospital at {item.viewedByHospitalAt}</td>
                    <td>&nbsp;</td>
                </>;
        }

    }

    return <tr>
        <td>{item.hospital && item.hospital.title}</td>
        <td>{item.doctor && item.doctor.firstName} {item.doctor && item.doctor.lastName}</td>
        <td>{item.files.length}</td>
        <td>{item.selectedDate}</td>
        {renderStatusAndActions()}
    </tr>;
}

export default RFAList_item;