import * as actionTypes from '../actions/actionTypes';

const initialState = {
    hospitalPatient: {},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_PATIENT_SET:
            return {
                ...state,
                hospitalPatient: action.hospitalPatient,
            };
        default:
            return state;
    }
}

export default reducer;