import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Field, useForm} from "react-final-form";

import SelectField from "../Field/SelectField";

const Hospital = ({hospitalSelection = false}) => {
    const user = useSelector(state => state.auth);
    const form = useForm();

    const hospitalOption = item => ({value: item.id, label: item.title});
    const hospitalOptions = () => user.hospitals.map(hospitalOption);

    useEffect(() => {
        if (user.hospitals.length === 1) {
            form.change('selectedHospital', {value: user.hospitals[0].id});
        }
    }, []);

    if (!hospitalSelection || (user.hospitals.length === 1)) {
        return <div className="form-group">
            <div className="row">
                <div className="col-2 form-title2">
                    Private Practice
                </div>
                <div className="col-5 zindex100">
                    {user.hospitals[0].title}
                </div>
            </div>
        </div>
    }

    return (
        <div className="form-group">
            <div className="row">
                <div className="col-2 form-title2">
                    Private Practice
                </div>
                <div className="col-5 zindex100">
                    <Field component={SelectField}
                           name="selectedHospital"
                           placeholder="Select Private Practice..."
                           options={hospitalOptions()}
                    />
                </div>
            </div>
        </div>
    );
}

export default Hospital;