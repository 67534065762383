var nargs = /\{([0-9a-zA-Z_]+)\}/g;

const parseEscape = (str) => {
    return str.replace(nargs, function replaceArg(match, capture, index) {
        if (str[index - 1] === "{" && str[index + match.length] === "}") {
            return capture;
        }

        return match;
    });
}
const getPositions = (string, values) => {
    var postion = [];
    string.replace(nargs, function replaceArg(match, capture, index) {
        if (!(string[index - 1] === "{" && string[index + match.length] === "}")) {
            postion.push({
                startIndex: index,
                endIndex: index + match.length,
                match: match,
                capture: capture,
                value: values[capture]
            });
        }
    });
    return postion;
}

export const parseStringTemplate = (str, values) => {
    // var renderNoMatch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {
    //     return "";
    // };
    var arr = [];
    var positions = getPositions(str, values);

    if (positions.length < 1) {
        arr.push({
            type: 'general',
            value: str
        });
    } else {
        var lastIndex = 0;
        positions.forEach(function (p) {
            var startIndex = p.startIndex,
                endIndex = p.endIndex,
                value = p.value;
            var general = str.substring(lastIndex, startIndex);
            arr.push({
                type: 'general',
                value: general
            });

            if (value) {
                arr.push({
                    type: 'var',
                    value: value
                });
            } else {
                arr.push({
                    type: 'var',
                    // value: renderNoMatch(str, p),
                    value: str,
                    isNoMatch: true
                });
            }

            lastIndex = endIndex;
        });
        arr.push({
            type: 'general',
            value: str.substring(lastIndex)
        });
    }

    var parsedArr = arr.map(function (node) {
        var type = node.type,
            value = node.value;

        if (type === 'general' && typeof value === 'string') {
            return parseEscape(value);
        }

        return value;
    });
    return parsedArr.join('');
}