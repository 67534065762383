import React from "react";
import {useSelector} from "react-redux";

import ManagerCustomUI_DownloadPdf from "./ManagerCustomUI_DownloadPdf";
import ManagerCustomUI_UploadPdf from "./ManagerCustomUI_UploadPdf";
import ManagerCustomUI_PreAdmission from "./ManagerCustomUI_PreAdmission";
import ManagerCustomUI_SendRegMed from "./ManagerCustomUI_SendRegMed";

const ManagerCustomUI = ({admission, onHide}) => {
    const managerCustomUI_admissionLinkDownloadPdf = useSelector(state => state.auth.selectedHospital.managerCustomUI_admissionLinkDownloadPdf);
    const managerCustomUI_admissionListUploadPdf = useSelector(state => state.auth.selectedHospital.managerCustomUI_admissionListUploadPdf);
    const managerCustomUI_admissionListPreAdmission = useSelector(state => state.auth.selectedHospital.managerCustomUI_admissionListPreAdmission);
    const managerCustomUI_sendRegMed = useSelector(state => state.auth.selectedHospital.managerCustomUI_sendRegMed);

    return <>
        {managerCustomUI_admissionLinkDownloadPdf && managerCustomUI_admissionLinkDownloadPdf.map(customUi => <ManagerCustomUI_DownloadPdf admission={admission} managerCustomUi={customUi} onHide={onHide}/>)}
        {managerCustomUI_admissionListUploadPdf && managerCustomUI_admissionListUploadPdf.map(customUi => <ManagerCustomUI_UploadPdf admission={admission} managerCustomUi={customUi} onHide={onHide}/>)}
        {managerCustomUI_admissionListPreAdmission && managerCustomUI_admissionListPreAdmission.map(customUi => <ManagerCustomUI_PreAdmission admission={admission} managerCustomUi={customUi} onHide={onHide}/>)}
        {managerCustomUI_sendRegMed && managerCustomUI_sendRegMed.map((customUi, key) => <ManagerCustomUI_SendRegMed admission={admission} managerCustomUi={customUi} onHide={onHide} key={`reg-med-${key}`}/>)}
    </>
}

export default ManagerCustomUI;