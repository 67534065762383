import React from "react";
import {useDispatch, useSelector} from "react-redux";

import * as modalActions from "../../../store/actions/modal";
import * as actionTypes from '../../../store/actions/actionTypes';
import {useCustomLogic} from "../../../utils/customLogic";

const AdmissionList_Item_OEC = ({admission}) => {
    const dispatch = useDispatch();
    const manager_noMbsUi = useSelector(state => state.auth.manager_noMbsUi);
    const {isCustomLogicLifehouse} = useCustomLogic();

    const showModal = event => {
        event.preventDefault();
        event.stopPropagation();
        console.log('[AdmissionList_Item_OEC] showModal()', admission.eclipseModalBc);
        dispatch(modalActions.showOecForAdmission(admission));
    }
    const doNothing = event => {
        event.preventDefault();
        event.stopPropagation();
    }
    const showCustomMBSModal = event => {
        event.preventDefault();
        event.stopPropagation();
        if (manager_noMbsUi) {
            console.log('[AdmissionList_Item_OEC] showModal()');
            dispatch({type: actionTypes.MODAL_PRESENTING_ILLNESS_CODES_SHOW, admission: admission});
        }
    }

    const codesCount = admission.illCodes_count ? admission.illCodes_count : admission.mbsCodes_count;

    switch (admission.statusOECStr) {
        case "STATUS_OEC_MISSING_DATA":
            if ((admission.fundMemberNo || admission.adm2_membershipNumber) && !admission.mbsCodes_count) {
                return (
                    <div className="d-flex flex-nowrap align-items-center" onClick={showCustomMBSModal}>
                        <button className="btn btn-sm btn-danger">No MBS</button>
                        <span className="info-tooltip" data-tip="MBS is not assigned for this admission">
                            <i className="icon ico-c-info"/>
                        </span>
                    </div>
                );
            } else {
                return (
                    <div className="d-flex flex-nowrap align-items-center" onClick={doNothing}
                         data-tip="Health Fund Info is not assigned for this admission"
                    >
                        <button className="btn btn-sm btn-danger">No HFI</button>
                        <span className="info-tooltip">
                            <i className="icon ico-c-info"/>
                        </span>
                    </div>
                );
            }
        case "STATUS_OEC_MODERATION":
            return (
                <div className="d-flex flex-nowrap align-items-center">
                    <button className="btn btn-sm btn-warning" onClick={showModal}>Moderation</button>
                </div>
            );
        case "STATUS_OEC_COMPLETED":
            switch (admission.statusFundingStr) {
                case "STATUS_FUND_WARNING":
                    return (
                        <div className="d-flex flex-nowrap align-items-center">
                            <button className="btn btn-sm btn-warning" onClick={showModal}>Warning</button>
                            <span className="info-tooltip"
                                data-tip={`OEC check is completed with warnings at ${admission.oec_completedAt}`}>
						        <i className="icon ico-c-info" />
					        </span>
                        </div>
                    );
                case "STATUS_FUND_REJECTED":
                    return (
                        <div className="d-flex flex-nowrap align-items-center">
                            <button className="btn btn-sm btn-danger" onClick={showModal}>Rejected</button>
                            <span className="info-tooltip"
                                  data-tip={`OEC check rejected at ${admission.oec_completedAt}`}>
						        <i className="icon ico-c-info" />
					        </span>
                        </div>
                    );
                case "STATUS_FUND_ACCEPTED":
                    return (
                        <div className="d-flex flex-nowrap align-items-center">
                            <button className="btn btn-sm btn-success" onClick={showModal}>Done {codesCount}/{codesCount}</button>
                            <span className="info-tooltip"
                                  data-tip={`OEC check is completed at ${admission.oec_completedAt}`}>
						<i className="icon ico-c-info" />
					</span>
                        </div>
                    );
            }

        case "STATUS_OEC_IN_PROGRESS":
        case "STATUS_OEC_PENDING":
            return (
                <div className="d-flex flex-nowrap align-items-center">
                    <button className="btn btn-sm btn-warning"  onClick={showModal}>Processing</button>
                    <span className="info-tooltip"
                          data-tip={`OEC check is started ${admission.oec_pendingCreatedAtHumanDate} ago`}>
						<i className="icon ico-c-info" />
					</span>
                </div>
            );
        case "STATUS_OEC_FAILED":
            return (
                <div className="d-flex flex-nowrap align-items-center">
                    <button className="btn btn-sm btn-danger"  onClick={showModal}>Failed</button>
                    <span className="info-tooltip"
                          data-tip="Please contact dev support">
						<i className="icon ico-c-info" />
					</span>
                </div>
            );
        case "STATUS_OEC_NOT_SUPPORTED":
            return (
                <div className="d-flex flex-nowrap align-items-center" onClick={doNothing}>
                    <button className="btn btn-sm btn-secondary" onClick={doNothing}>-</button>
                    <span className="info-tooltip"
                          data-tip="OEC checks are supported only for private insurance">
						<i className="icon ico-c-info" />
					</span>
                </div>
            );
        case "STATUS_OEC_SCHEDULED":
            return (
                <div className="d-flex flex-nowrap align-items-center" onClick={showModal}>
                    <button className="btn btn-sm btn-secondary" onClick={showModal}>Scheduled</button>
                    <span className="info-tooltip"
                          data-tip={`OEC run scheduled for ${admission.oec_scheduleDate}`}>
						<i className="icon ico-c-info" />
					</span>
                </div>
            );
        default:
            // TODO: Watch logical errors in react
            return null;
    }
}

export default AdmissionList_Item_OEC;