import React from "react";
import {useSelector} from "react-redux";
import {CUSTOM_LOGIC_DEMO, CUSTOM_LOGIC_JMPH} from "../../utils/customLogic";
import JmphFundingType from "../AdmissionsList/Item/FundingType/JmphFundingType";

const AdmissionList_Item_Name = ({admission}) => {
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);
    const hospital = useSelector(state => state.auth.selectedHospital);
    const lifehouseFundingType = () => {
        switch (admission.lifehouse_erfaFundingType) {
            case "LIFEHOUSE_FUNDING_PRIVATE":
                return <div className="lifehouse-color-code yellow" />
            case "LIFEHOUSE_FUNDING_PUBLIC":
                return <div className="lifehouse-color-code pink" />
            case "LIFEHOUSE_FUNDING_SELF":
            case "LIFEHOUSE_FUNDING_DVA":
                return <div className="lifehouse-color-code grey" />
        }
        return null;
    }

    switch (hospital.hospitalConfig.customLogic) {
        case "CUSTOM_LOGIC_LIFEHOUSE":
            return (
                <>
                    <span className="patient-name uppercase">{admission.surname}, {admission.name}</span><br />
                    <span className="procedure">{state_showDevUI && `#${admission.id}`} L{admission.URNumber}</span>
                    {lifehouseFundingType()}
                </>
            );
        case "CUSTOM_LOGIC_PRIVATE_PRACTICE":
            return <>
                <span className="patient-name uppercase">{admission.name} {admission.surname}</span><br />
                <span className="procedure">{admission.DOB}</span>
            </>
        case "CUSTOM_LOGIC_ESPH":
        case CUSTOM_LOGIC_DEMO:
        case "CUSTOM_LOGIC_HEALTHECARE":
        case "CUSTOM_LOGIC_MARYVALE":
        default:
            return (
                    <>
                        <span className="patient-name">{state_showDevUI && `#${admission.id}`} {admission.surname}, {admission.name}</span><br />
                        <span className="procedure">{admission.admissionReason}</span>
                    </>
                );
        case CUSTOM_LOGIC_JMPH:
            return (
                    <>
                        <span className="patient-name">{state_showDevUI && `#${admission.id}`} {admission.surname}, {admission.name} (UR {admission.URNumber})</span><br />
                        <span className="procedure">{admission.admissionReason}</span>
                        <JmphFundingType type={admission.adm2_claimCode} />
                    </>
                );
    }
}

export default AdmissionList_Item_Name;