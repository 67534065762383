import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import * as actionTypes from "../../store/actions/actionTypes";
import useHtmlValidation from "../../utils/htmlValidation";
import {sampleInvalidHtml, sampleValidHtml} from "../../dev/aca586";

const DevAca586View = () => {
    const [text, setText] = useState('')
    const dispatch = useDispatch();
    const {valid, validationErrors, loading, resetValidation, validateHtml, validatorVersion} = useHtmlValidation();

    useEffect(() => {
        dispatch({type: actionTypes.LOADING_DONE});
    }, [])

    const handleChangeHtml = (event) => {
        setText(event.target.value);
        resetValidation();
    }

    const handleValidate = () => validateHtml(text);

    const handleSetValid = () => setText(sampleValidHtml);
    const handleSetInvalid = () => setText(sampleInvalidHtml);

    return <main className="main">
                <div className="container">
                    <h2>ACA-586 - XSS vulnerability in the send-message endpoint</h2>
                    <div className="form-group mb-4">
                        Allowed tags - 'h1', 'h2', 'h3', 'h4', 'p', 'a', 'b', 'strong', 's', 'u', 'br', 'pre', 'ul', 'li', 'ol' <br/>
                        {/*IMG with src = data:image\/png;base64,.... can be used <br />*/}
                        <strong>IFRAME</strong> with props 'src', 'width', 'height', 'style', 'allowfullscreen', 'referrerpolicy', 'class', 'frameborder' and src = https://www.google.com/maps/embed .... <br />
                        <strong>IMG</strong> with props 'src', 'width', 'height', 'style', 'class' and 'https://...' OR 'data:image/png;base64,' src <br />
                        <br />
                        {validationErrors()}
                        {validatorVersion && <p>Validator version: {validatorVersion}</p>}
                        Test HTML {valid && <div className="badge badge-success">VALID</div>}<br/>
                        <div className="form-group mb-4">
                            <textarea value={text} onChange={handleChangeHtml} cols={150} rows={20}/>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <input type="button" value="Use valid html" className="btn" onClick={handleSetValid} disabled={loading}/>
                                <input type="button" value="Use invalid html" className="btn" onClick={handleSetInvalid} disabled={loading}/>
                                <input type="button" value="Validate HTML" className="btn btn-primary" onClick={handleValidate} disabled={loading}/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>;
}

export default DevAca586View;