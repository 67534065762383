import React from "react";
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from "../../store/actions/actionTypes";
import * as mainActions from "../../store/actions/main";
import * as modalActions from "../../store/actions/modal";
import {useToasts} from "react-toast-notifications";
import {webPasTerm} from "../CustomLogic/Terms";

const AdmissionList_Bulk = () => {
    const {checkedAdmissions} = useSelector(state => state.bulk.admissionList);
    const admissions = useSelector(state => state.admissionList.admissions);
    const dispatch = useDispatch();
    const {addToast} = useToasts();
    const selectedHospital = useSelector(state => state.auth.selectedHospital);

    const selectAction = (event) => {
        dispatch({type: actionTypes.ADMISSIONS_LIST_BULK_UNSELECT_ALL});
        switch(event.target.value) {
            case "webpas-sync":
                admissions.forEach(admission => {
                    if (checkedAdmissions.includes(admission.id)) {
                        dispatch(mainActions.webPasSync(admission));
                    }
                });
                addToast(`Bulk ${webPasTerm(selectedHospital)} Sync for ${checkedAdmissions.length} patients successfully initiated`, {
                    appearance: 'success',
                    autoDismiss: true
                });
                break;
            case "oec":
                admissions.forEach(admission => {
                    if (checkedAdmissions.includes(admission.id)) {
                        dispatch(modalActions.requestOecCheck(admission))
                    }
                });
                addToast(`Bulk OEC Check for ${checkedAdmissions.length} patients successfully initiated`, {
                    appearance: 'success',
                    autoDismiss: true
                });
                break;
        }
    }

    const renderFirstOption = () => {
        if (checkedAdmissions.length === 1) {
            return <option value=''>Select bulk action for 1 admission</option>;
        } else if (checkedAdmissions.length > 1) {
            return <option value=''>{`Select bulk action for ${checkedAdmissions.length} admissions`}</option>;
        }
        return <option value=''>Select admissions for bulk action</option>;
    }

    return (<>
        <div className="col-3 mt-2">
            <select className="custom-select" id="inputGroupSelect01" onChange={selectAction}
                    disabled={!checkedAdmissions.length} value=''>
                {renderFirstOption()}
                <option value="webpas-sync">{webPasTerm(selectedHospital)} sync</option>
                <option value="oec">Check OEC</option>
            </select>
        </div>
        <div className="col-9"/>
    </>);
}

export default AdmissionList_Bulk;