import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import * as actionTypes from "../../store/actions/actionTypes";
import {useDispatch, useSelector} from "react-redux";

const AdmissionImpersonateView = () => {
    const {id} = useParams();
    const idToken = useSelector(state => state.auth.idToken);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: actionTypes.LOADING_START});

        axios.get(
            `/admission/${id}/impersonate`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('AdmissionImpersonateView response', response.data);
                document.location = response.data.url;
            });

    }, []);

    return <h1>Impersonate</h1>
}

export default AdmissionImpersonateView;