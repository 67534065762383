import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";

const ModalError = () => {
    const {message} = useSelector(state => state.modal.error);
    const dispatch = useDispatch();
    const hideModal = () => dispatch({type: actionTypes.MODAL_ERROR_HIDE});

    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    return (
        <>
            <div className={`modal fade show`} id="errorModal" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog error-modal" role="document" ref={ref} autoFocus={false}>
                    <div className="modal-content modal-content-fit">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <h3>Error</h3>
                            <div style={{color: 'red'}} className="mb-3">{message}</div>
                            <form onSubmit={hideModal} className="person-info-form">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center">
                                            <input type="submit" value="Ok" className="btn btn-primary" onClick={hideModal}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default ModalError;
