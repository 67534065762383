import React from "react";
import {useSelector} from "react-redux";

const Admission_TargetPhone = ({admission}) => {
    const {devSmsTarget_enable, devSmsTarget_phone} = useSelector(state => state.auth);

    if (devSmsTarget_enable) {
        return (
            <><strike>{admission.mobilePhone}</strike> <span className="badge badge-danger">DEV TARGET ENABLED = {devSmsTarget_phone}</span></>
        );
    } else {
        return (
            <>{admission.mobilePhone}</>
        );
    }
}

export default Admission_TargetPhone;