import React from "react";
import JSONPretty from "react-json-pretty";
import XMLViewer from "react-xml-viewer";
import {useSelector} from "react-redux";

const xmlTheme = {overflowBreak:true};

const ModalOecStatus_ClaimDevInfo = ({claim}) => {
    const {manager_showOECDebugInfo} = useSelector(state => state.auth);

    if (manager_showOECDebugInfo) {
        return (
            <>
                <hr/>
                <div className="row">
                    <div className="col-12">
                        <h4>OEC Request</h4>
                        Transaction: {claim.oec_request.response_transactionId} <br/>
                        Response status code: {claim.oec_request.response_statusCode} <br/>
                        Response body: {claim.oec_request.response_body} <br/>
                        Response headers:
                        <JSONPretty id="json-pretty" data={claim.oec_request.response_headers}/>
                        <XMLViewer xml={claim.oec_request.request_xml} theme={xmlTheme}/>
                        {claim.rtv_request && <>
                            <h4>RTV Response</h4>
                            Transaction: {claim.rtv_request.response_transactionId} <br/>
                            Response status code: {claim.rtv_request.response_statusCode} <br/>
                            Response headers: <JSONPretty id="json-pretty" data={claim.rtv_request.response_headers}/>
                            <XMLViewer xml={claim.rtv_request.response_body} theme={xmlTheme}/>
                        </>}
                    </div>
                </div>
            </>
        );
    }
    return null;
}

export default ModalOecStatus_ClaimDevInfo;