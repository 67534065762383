import React from "react";
import {useSelector} from "react-redux";

const AdmissionList_Item_Pdf = ({admission}) => {
    const idToken = useSelector(state => state.auth.idToken);
    const ref = React.useRef(null);

    const downloadPdf = event => {
        event.preventDefault();
        event.stopPropagation();
        ref.current.submit();
    }

    if (admission.haveDocuments) {
        return (
            <div className="documents-status done">
                <div className="doc pointer" onClick={downloadPdf}>
                    <i className="icon ico-document-with-conner"/>
                </div>
                {admission.isStatusPP_documentsMerged &&
                <div className="icon-check text-success pointer"  onClick={downloadPdf}>
                    <i className="icon ico-c-check"/>
                </div>
                }
                <div className="display-none">
                    <form action={`${process.env.REACT_APP_API_PATH}/admission/${admission.id}/pdf`} method="post"
                          ref={ref}>
                        <input type="hidden" name="token" value={idToken}/>
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="documents-status n-a"  data-toggle="tooltip" data-placement="top" title="Select patient document set first">
            <div className="doc">
                <i className="icon ico-document-with-conner"/>
            </div>
            <div className="icon-check info-tooltip">
                <i className="icon ico-c-info" />
            </div>

        </div>
    );
}

export default AdmissionList_Item_Pdf;