import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../../store/actions/actionTypes";
import {finishUploadPdf} from "../../../store/actions/customUI";
import Admission_ModalInfo from "../../Block/Admission_ModalInfo";
import FileUploader2 from "../../FileUploader2";
import Spinner from "../../Spinner";


const UploadPdf = () => {
    const [fileId, setFileId] = useState(null);
    const [approving, setApproving] = useState(false);
    const {show, admission, customUI} = useSelector(state => state.modal.customUI_uploadPdf);
    const ref = React.useRef(null);
    const dispatch = useDispatch();

    const hideModal = () => dispatch({type: actionTypes.MODAL_CUSTOM_UI_UPLOAD_PDF_HIDE});
    useOnClickOutside(ref, () => hideModal());

    const doApprove = () => {
        dispatch(finishUploadPdf(admission, customUI, fileId));
        hideModal();
    }

    const renderModal = () => {
        if (show) {
            return (
                <div className="modal-dialog modal-content-large" role="document" ref={ref} autoFocus={false}>
                    <div className="modal-content modal-content-fit">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <h3>{customUI.uploadTitle} - {admission.title} {admission.surname}, {admission.name}</h3>
                            <Admission_ModalInfo admission={admission} showMobileInfo={false} />
                            <form className="person-info-form">
                                {approving ? <Spinner /> : <FileUploader2 api={`${process.env.REACT_APP_API_PATH}/admission/${admission.id}/upload-pdf2`} onSetFile={setFileId}/>}
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <input type="button" value="Cancel" className="btn" onClick={hideModal}/>
                                            <input type="button" value="Approve & Upload" className="btn btn-primary" disabled={!fileId || approving} onClick={doApprove}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {renderModal()}
            </div>
        </>
    );
}

export default UploadPdf;