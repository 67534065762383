import React, {useRef} from "react";
import ReactToPrint from 'react-to-print';
import dotProp from 'dot-prop-immutable';

import OecClaim_DevInfo from "./OecClaim_DevInfo";
import Badge_YN from "../Badge/Badge_YN";

const Amount = ({intAmount}) => {
    if (intAmount.toString() === '0') {
        return '$0.00';
    }
    if (intAmount.toString() === '-') {
        return '-';
    }
    return <>
            ${intAmount.toString().substr(0, intAmount.toString().length-2)}.{intAmount.toString().substr( intAmount.toString().length-2, 2)}
        </>
}

const FundAssessmentCode = ({fundAssessmentCode}) => {
    switch (fundAssessmentCode) {
        case "A":
            return <span className="badge badge-success">Accepted</span>;
        case "R":
            return <span className="badge badge-danger">Rejected</span>;
        case "W":
            return <span className="badge badge-warning">Warning</span>;
    }
    return null;
}

const Service = ({service}) => {
    return (
        <table className="table table-hover table-sm table-oec">
            <tbody>
            <tr>
                <td>
                    Date Of Service:
                    <br/>Service Code Type/Code/Item Number:
                    <br />Fund Assessment Code:

                </td>
                <td>
                    {dotProp.get(service, 'dateOfService', '-')}
                    <br />{dotProp.get(service, 'serviceCodeTypeCode', '-')} / {dotProp.get(service, 'serviceCode', '-')} / {dotProp.get(service, 'itemNumber', '-')}
                    <br /><FundAssessmentCode
                    fundAssessmentCode={dotProp.get(service, 'fundAssessmentCode', '-')}/>
                </td>
                <td>
                    Charge Amount
                    <br />Benefit
                    <br />Schedule Fee
                </td>
                <td>
                    <Amount intAmount={dotProp.get(service, 'chargeAmount', '-')} />
                    <br /><Amount intAmount={dotProp.get(service, 'benefit', '-')} />
                    <br /><Amount intAmount={dotProp.get(service, 'scheduleFee', '-')} />
                </td>
            </tr>
            <tr>
                <td className="white-space-no-wrap">
                    Fund Explanation Code:
                    <br />Fund Explanation Text:
                </td>
                <td colSpan={3}>
                    {dotProp.get(service, 'explanation.0.code', '-')}
                    <br />{dotProp.get(service, 'explanation.0.text', '-')}
                </td>
            </tr>
            </tbody>
        </table>
    )
}

const renderHealthFundMedicalEvent = (claim) => {
    const services = dotProp.get(claim, 'responseBody.healthFundClaimEstimation.medicalEvent.0.service')
    if (!services) {
        return null;
    }
    return services.map(service => {
        return <>
                    <h2>HealthFund Estimation for MedicalEvent #01, Service #{service.id}</h2>
                    <Service service={service} />
                </>
        }
    );
}

const renderMedicareMedicalEvent = (claim) => {
    const services = dotProp.get(claim, 'responseBody.medicareClaimEstimation.medicalEvent.0.service')
    if (!services) {
        return null;
    }
    return services.map(service => {
            return <>
                <h2>Medicare Estimation for MedicalEvent #01, Service #{service.id}</h2>
                <Service service={service}/>
            </>
        }
    );
}

const renderMedicareStatusCodeWarning = (claim) => {
    const status = dotProp.get(claim, 'responseBody.medicareStatus.status.code', 0);
    if (status !== 0) {
        switch (status) {
            case 9633:
                return (
                    <div className="alert alert-danger">
                        {dotProp.get(claim, 'responseBody.medicareStatus.status.text', '-')}.
                        <br /><br />Current Member Number: {dotProp.get(claim, 'responseBody.medicareStatus.currentMembership.memberNumber', '-')}, Ref Number: {dotProp.get(claim, 'responseBody.medicareStatus.currentMembership.memberRefNumber', '-')}, Member: {dotProp.get(claim, 'responseBody.medicareStatus.currentMember.givenName', '-')}
                    </div>
                )
        }
    }
}
const renderHealthFundStatusCodeWarning = (claim) => {
    const status = dotProp.get(claim, 'responseBody.healthFundStatus.status.code', 0);
    if (status !== 0) {
        switch (status) {
            default:
                return (
                    <div className="alert alert-danger">
                        {dotProp.get(claim, 'responseBody.healthFundStatus.status.text', '-')}
                    </div>
                )
        }
    }
}

class OecData extends React.Component {
    render() {
        const {claim} = this.props;
        console.log('[OecData]', claim);
        return (
            <div className="row">
                <div className="col-12 oec-description">
                    <div className="bg-white rounded shadow-sm mb-4">
                        <h1>ONLINE ELIGIBILITY CHECK (OEC) REPORT</h1>
                        <h2>Quote only not for claiming</h2>
                        {renderMedicareStatusCodeWarning(claim)}
                        {renderHealthFundStatusCodeWarning(claim)}
                        <table className="table table-hover table-sm table-oec">
                            <tbody>
                            <tr>
                                <td>
                                    Location Id<br />
                                    Account Reference Id:<br />
                                </td>
                                <td>
                                    {claim.requestBody.claim.facilityId}<br />
                                    {claim.requestBody.claim.accountReferenceId}<br />
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Patient name<br />
                                    DOB<br />
                                    Gender<br/>
                                </td>
                                <td>
                                    {claim.requestBody.claim.patient.identity.familyName},&nbsp;
                                    {claim.requestBody.claim.patient.identity.givenName}<br />
                                    {claim.requestBody.claim.patient.identity.dateOfBirth}<br />
                                    {claim.genderStr}<br/>
                                </td>
                                <td>
                                    {/*UR no<br />*/}
                                    Health Fund<br />
                                    Member Number <br/>
                                    Member Ref Number
                                </td>
                                <td>
                                    {/*{admission.URNumber}<br />*/}
                                    {dotProp.get(claim, 'requestBody.claim.patient.healthFund.organisation', '-')}
                                    <br />{dotProp.get(claim, 'requestBody.claim.patient.healthFund.memberNumber', '-')}
                                    <br />{dotProp.get(claim, 'requestBody.claim.patient.healthFund.memberRefNumber')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Admission No<br />
                                    Admission Date<br />
                                    Planned LOS<br/>
                                </td>
                                <td>
                                    {claim.requestBody.claim.accountReferenceId}
                                    <br />{dotProp.get(claim, 'requestBody.claim.medicalEvent.0.admissionDate', '-')}
                                    <br />{dotProp.get(claim, 'requestBody.claim.lengthOfStay', '-')}
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                            </tr>
                            <tr>
                                <td className="white-space-no-wrap">
                                    Health Fund Table<br/>
                                    Table scale
                                </td>
                                <td colSpan={3}>
                                    {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.healthFundTable.tableName', '-')}<br />
                                    {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.healthFundTable.tableScale', '-')}
                                </td>
                            </tr>
                            <tr>
                                <td>Financial status
                                    <br />Fund ref ID
                                    {/*<br />Intended stay*/}
                                </td>
                                <td>
                                    <Badge_YN
                                        status={dotProp.get(claim, 'responseBody.healthFundClaimEstimation.financialStatus', '-')} />
                                    <br />{dotProp.get(claim, 'responseBody.healthFundClaimEstimation.fundReferenceId', '-')}
                                    {/*<br />TODO:*/}
                                </td>
                                <td>
                                    Pre Existing PEA indicator
                                    <br />Potential PEA indicator
                                </td>
                                <td>
                                    <Badge_YN
                                        status={dotProp.get(claim, 'requestBody.claim.peaRequestInd', '-')} />
                                    <br />
                                    <Badge_YN
                                        status={dotProp.get(claim, 'responseBody.healthFundClaimEstimation.peaPotentialInd', '-')} />
                                </td>
                            </tr>
                            <tr>
                                <td className="white-space-no-wrap">
                                    Presenting Illness Code
                                    <br />Presenting Illness Item Number
                                </td>
                                <td colSpan={3}>
                                    {dotProp.get(claim, 'requestBody.claim.presentingIllnessCode', '-')}
                                    <br />{dotProp.get(claim, 'requestBody.claim.presentingIllnessItemNumber', '-')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Request Date<br />
                                    Trans ID
                                </td>
                                <td>
                                    {claim.createdAt}
                                    <br/>{dotProp.get(claim, 'requestHeaders.dhs-correlationId', '-')}
                                </td>
                                <td>Assess Code</td>
                                <td>
                                    <FundAssessmentCode
                                        fundAssessmentCode={dotProp.get(claim, 'responseBody.healthFundClaimEstimation.fundAssessmentCode', '-')}/>
                                    <br />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    CoPayment Amount
                                    <br />CoPayment Days Remaining
                                </td>
                                <td>
                                    <Amount intAmount={dotProp.get(claim, 'responseBody.healthFundClaimEstimation.patientCoPayment.amount', '-')} />
                                    <br />
                                    {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.patientCoPayment.daysRemaining', '-')}
                                </td>
                                <td>
                                    Excess Amount
                                    <br />Excess Bonus Amount
                                </td>
                                <td>
                                    <Amount intAmount={dotProp.get(claim, 'responseBody.healthFundClaimEstimation.patientExcessPayment.amount', '-')} />
                                    <br />
                                    <Amount intAmount={dotProp.get(claim, 'responseBody.healthFundClaimEstimation.patientExcessPayment.bonusAmount', '-')} />
                                </td>
                            </tr>
                            <tr>
                                <td className="white-space-no-wrap">
                                    Fund Explanation Code:
                                    <br />Fund Explanation Text:
                                </td>
                                <td colSpan={3}>
                                    {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.explanation.0.code', '-')}
                                    <br />{dotProp.get(claim, 'responseBody.healthFundClaimEstimation.explanation.0.text', '-')}
                                </td>
                            </tr>

                            </tbody>
                        </table>
                        {renderHealthFundMedicalEvent(claim)}
                        {renderMedicareMedicalEvent(claim)}
                    </div>
                    <h6 className="h6">Table Description</h6>
                    <p>
                        {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.healthFundTable.tableDescription', '-')}
                    </p>
                    <h6 className="h6">Benefit Limitations</h6>
                    <p>
                        {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.benefitLimitations', '-')}
                    </p>
                    <h6 className="h6">Co-payment Description</h6>
                    <p>
                        {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.patientCoPayment.amountDescription', '-')}
                    </p>
                    <h6 className="h6">Excess Amount Description</h6>
                    <p>
                        {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.patientExcessPayment.amountDescription', '-')}
                    </p>
                    <h6 className="h6">Exclusion Description</h6>
                    <p>
                        {dotProp.get(claim, 'responseBody.healthFundClaimEstimation.exclusionDescription', '-')}
                    </p>
                    <h6 className="h6">Disclaimer and Privacy Notice</h6>
                    This Online Eligibility Check (OEC) information is provided under the following conditions.

                    <h6 className="h6">Disclaimer</h6>
                    <ul>
                    <li>This response is based on information supplied by you and on the
                    conditions applicable to you and your membership of your health fund at
                    the proposed admission date, as known at the date this OEC was
                        performed.</li>
                    <li>This response is not an undertaking by Services Australia or the health
                        fund to pay any claim.</li>
                    <li>Services Australia have the right to decline a future claim based on your
                        eligibility at the time that future claim is made.</li>
                    <li>The health fund may decline a future claim based on the conditions
                    applicable at the time that future claim is made, including: pre-existing
                    ailments; waiting periods not being served; product exclusions; accident
                    or where compensation and/or damages may be claimed from another
                        source; and cancelled, suspended or non-financial memberships.</li>
                    </ul>
                    <h6 className="h6">Privacy Notice</h6>
                    <p>Your personal information is protected by law, including the <i>Privacy Act 1988</i>, and is collected by Services Australia for the assessment and
                    administration of payments and services. This information is required to process
                    your application or claim.</p>

                    <p>Your information may be used by the agency or given to other parties for the
                    purposes of research, investigation or where you have agreed or it is required or
                        authorised by law.</p>
                    <p>You can get more information about the way in which Services Australia will
                    manage your personal information, including our privacy policy at <b>servicesaustralia.gov.au/privacy</b> or by requesting a copy from the agency.</p>
                    <p>The information provided or received will be used to determine your
                    Medicare eligibility and may be accessed by Services Australia, your
                    provider and health fund. Your information will not be provided to any other
                    third parties unless required or authorised by law. This information may be
                        used to facilitate patient Informed Financial Consent (IFC).</p>

                </div>
            </div>
        );
    }
}

const OecClaim = ({claim, admission}) => {
    const OecDataRef = useRef();

    const renderDone = () => {
        return (
            <div className="col-9">
                <div className="main-content">

                    <div className="row">
                        <div className="col-2">
                            <ReactToPrint
                                bodyClass="oec-print"
                                trigger={() => {
                                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                    // to the root node of the returned component as it will be overwritten.
                                    return <button className="btn btn-white btn-time-opener">
                                            <span className="icon-calendar"><svg width="1em" height="1em"
                                                                                 viewBox="0 0 16 16"
                                                                                 className="bi bi-printer"
                                                                                 fill="currentColor"
                                                                                 xmlns="http://www.w3.org/2000/svg">
  <path
      d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"/>
  <path fillRule="evenodd"
        d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"/>
  <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                            </svg></span>
                                        <span>Print</span>
                                    </button>;
                                }}
                                content={() => OecDataRef.current}
                            />

                        </div>
                        <div className="col-10">
                            <h2 className="h4">OEC Response Report</h2>
                        </div>

                    </div>
                    <OecData
                        // admission={admission}
                        claim={claim} ref={OecDataRef}/>

                    {/*{renderDevInfo()}*/}
                </div>
            </div>
        )
    }

    const renderDevInfo = () => <OecClaim_DevInfo claim={claim}/>

    const renderMessage = (msg) => {
        return (
            <div className="col-9">
                <div className="main-content">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="h4">OEC Response Report</h2>
                            <p>{msg}</p>
                        </div>
                    </div>
                    {renderDevInfo()}
                </div>
            </div>
        )
    }

    switch (claim.statusConst) {
        default:
        case "STATUS_NEW":
            return renderMessage('Please wait while claim will be processed');
        case "STATUS_PROCESSING":
            return renderMessage('Please wait while claim will be processed');
        case "STATUS_FAILED":
        case "STATUS_ERROR":
            return renderMessage(claim.error_description);
        case "STATUS_COMPLETED":
            return renderDone();
    }

}

export default OecClaim;