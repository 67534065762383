import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import {useToasts} from "react-toast-notifications";

import * as actionTypes from "../../store/actions/actionTypes";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import * as lifehouseActions from "../../store/actions/lifehouse";
import LifehouseInternalComments from "../Lifehouse/LifehouseInternalComments";

const ModalLifehouseCommentModal = () => {
    const {show, admission, commentsType} = useSelector(state => state.modal.lifehouse_comments);
    const [comment, setComment] = useState('');
    const [status, setStatus] = useState('0');
    const {addToast} = useToasts();
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_LIFEHOUSE_COMMENTS_HIDE});
    const sendStatus = () => dispatch(lifehouseActions.addComment(admission, comment, status, commentsType, addToast ));
    const updateComment = event => setComment(event.target.value);
    const updateStatus = event => setStatus(event.target.value);

    const canSubmitComment = () => (comment !== '') && (status !== '0');

    useEffect(() => {
    }, [])

    const showTitle = () => {
        switch (commentsType) {
            default:
                return <h3>Internal comments for {admission.title} {admission.surname}, {admission.name}</h3>
            case 3:
                return <h3>Internal comments 2 for {admission.title} {admission.surname}, {admission.name}</h3>
        }
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
                <div className="modal-content modal-height-500">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="modal-body lifehouse-comments">
                        {showTitle()}
                        <dl className="person-list mb-3">
                            <UrDt admission={admission}/>
                            <AdmDt admission={admission}/>
                        </dl>
                        <strong>Message</strong>
                        <textarea onChange={updateComment} value={comment}></textarea>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <select onChange={updateStatus}>
                                        <option value={0}>Select status</option>
                                        <option value={1}>Pending</option>
                                        <option value={2}>Closed</option>
                                    </select>
                                    <input className="btn btn-primary" type="button" value="Save comment" onClick={sendStatus} disabled={!canSubmitComment()}/>
                                </div>
                            </div>
                        </div>
                        <LifehouseInternalComments admission={admission} type={commentsType}/>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="documentStatusModal" tabIndex="-1">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalLifehouseCommentModal;