import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import * as actionTypes from "../../store/actions/actionTypes";
import * as modalActions from "../../store/actions/modal";
import {sendAdmissionDocument, sendMessage} from "../../store/actions/admission";
import {useToasts} from "react-toast-notifications";
import Admission_ModalInfo from "../Block/Admission_ModalInfo";
import Admission_TargetPhone from "../Block/Admission_TargetPhone";
import {MODAL_SEND_ADMISSION_DOCUMENT_HIDE} from "../../store/actions/actionTypes";

const ModalSendAdmissionDocument = () => {
    const {addToast} = useToasts();
    const [sendClicked, setSendClicked] = useState(false);
    const [selectedType, setSelectedType] = useState(0);
    const {show, admission, admissionDocumentTypes, parentModal} = useSelector(state => state.modal.sendAdmissionDocuments);
    const [smsNotification, setSmsNotification] = useState(false);
    const handleSmsNotification = (event) => setSmsNotification(event.target.checked);

    // TODO: handle parentModal
    console.log('[ModalSendAdmissionDocument] ', admission, admissionDocumentTypes, parentModal)
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_SEND_ADMISSION_DOCUMENT_HIDE});

    const doSend = (event) => {
        setSendClicked(true);
        dispatch(sendAdmissionDocument(admission, addToast, selectedType, smsNotification));
        // event.preventDefault();
        // dispatch({type: actionTypes.MODAL_SEND_ADMISSION_DOCUMENT_HIDE});
        // // if ModalSendMessage is showed as child modal - close parent too
        // dispatch({type: actionTypes.MODAL_ADMISSION_MESSAGES_HIDE})
    }

    const handleSelect = event => {
        console.log('[ModalSendAdmissionDocument] handleSelect', event.target.value)
        setSelectedType(event.target.value);
        event.preventDefault();
    }
    const renderPreview = () => {
        if (smsNotification) {
            return <div className="form-group mb-4">
                Sms preview <br />
                <textarea className="form-control" disabled={true} cols="30" rows="5">{admission.enablePatientPortalMessage}</textarea>
            </div>
        }
        return null;
    }

    const renderModal = () => {
        if (show) {
            return (
                <div className="modal-dialog modal-content-large" role="document" ref={ref}>
                    <div className="modal-content modal-content-fit">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={hideModal}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className="modal-body">
                            <h3>Send form to {admission.title} {admission.surname}, {admission.name}</h3>
                            <Admission_ModalInfo admission={admission} />
                            <form action="#" className="person-info-form">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-4">
                                            <select className="custom-select" id="inputGroupSelect01" onChange={handleSelect}>
                                                <option value="">Select Form</option>
                                                {admissionDocumentTypes.map((item, key) => <option key={item.id} value={item.id}>{item.title}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="custom-control custom-switch custom-switch-right-side">
                                                <input type="checkbox" className="custom-control-input"
                                                       id="smsNotificationSwitch" checked={smsNotification} onChange={handleSmsNotification}/>
                                                <label className="custom-control-label"
                                                       htmlFor="smsNotificationSwitch">Sms notification?</label>
                                            </div>
                                        </div>
                                        <div className="col-8 d-flex">
                                            <dd><Admission_TargetPhone admission={admission} /></dd>
                                        </div>
                                    </div>
                                </div>
                                {renderPreview()}
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            <input type="button" value="Cancel" className="btn" onClick={hideModal}/>
                                            <input type="submit" value="Send via Portal" className="btn btn-primary" onClick={doSend} disabled={!selectedType || sendClicked}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {renderModal()}
            </div>
        </>
    );
}

export default ModalSendAdmissionDocument;