import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as actionTypes from "../../store/actions/actionTypes";

const ModalSelectDocumentSet_RemovePaymentOption = () => {
    const dispatch = useDispatch();
    const manager_removePaymentOption_enable = useSelector(state => state.auth.selectedHospital.manager_removePaymentOption_enable);
    const removePaymentOption = useSelector(state => state.modal.selectDocumentSet.removePaymentOption);

    const toggleFlag = () => dispatch({type: actionTypes.MODAL_SELECT_DOCUMENT_SET_TOGGLE, removePaymentOption: !removePaymentOption})

    if (!manager_removePaymentOption_enable) {
        return null;
    }

    return (
        <div className="custom-control custom-checkbox remove-payment-option">
            <input type="checkbox" className="custom-control-input" name="checkbox1" id="removePaymentOption" checked={removePaymentOption} onChange={toggleFlag}/>
            <label className="custom-control-label" htmlFor="removePaymentOption">Payment completed out of portal</label>
        </div>
    )
}

export default ModalSelectDocumentSet_RemovePaymentOption;