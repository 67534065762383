import React from "react";
import {useSelector} from "react-redux";
import {Field, useFormState} from "react-final-form";

import useDoctorHospitalData from "../../../utils/doctorHospitalData";
import DynamicSelectField from "../Field/DynamicSelectField";

const RfaForm_DoctorHospital = () => {
    const user = useSelector(state => state.auth);
    const form = useFormState();
    const {hospitalOptions, doctorOptions, formatSelectOption, buildDoctorOptionById, buildHospitalOptionById} = useDoctorHospitalData(user);

    React.useEffect(() => {
        // auto select disabled
        // if (user.doctors.length === 1) {
        //     change('selectedDoctor', buildDoctorOptionById(user.doctors[0].id));
        // }
        // if (user.hospitals.length === 1) {
        //     change('selectedHospital', buildHospitalOptionById(user.hospitals[0].id));
        // }
    }, []);

    return (
        <div className="form-group">
            <div className="row">
                <div className="col-2 form-title2">
                    Doctor / Facility
                </div>
                <div className="col-3 zindex100">
                    <Field component={DynamicSelectField}
                           name="selectedHospital"
                           placeholder="Select Facility..."
                           options={hospitalOptions(form.values.selectedDoctor)}
                           formatSelectOption={formatSelectOption}
                    />
                </div>
                <div className="col-3">
                    <Field component={DynamicSelectField}
                           name="selectedDoctor"
                           placeholder="Select Doctor..."
                           options={doctorOptions(form.values.selectedHospital)}
                           formatSelectOption={formatSelectOption}
                    />
                </div>
            </div>
        </div>
    );
}

export default RfaForm_DoctorHospital;