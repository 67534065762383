import * as actionTypes from './actionTypes';
import axios from 'axios';
import {push} from "react-router-redux";
import {errorEvent} from "../../utils/axiosErrors";

export const load = (id) => {
    return (dispatch, getState) => {
        console.log('[actions/hospitalPatients] load', id);
        dispatch({type: actionTypes.LOADING_START});
        const idToken = getState().auth.idToken;

        axios.get(
            `/hospital-patient/${id}`,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/hospitalPatients] response', response.data);
                dispatch({type: actionTypes.HOSPITAL_PATIENT_SET, hospitalPatient: response.data.hospitalPatient});
                dispatch({type: actionTypes.LOADING_DONE});
                dispatch({type: actionTypes.AUTH_SELECT_HOSPITAL, ...response.data.hospitalPatient.hospital});
            })
            .catch((error) => {
                dispatch(errorEvent(error, `/hospital-patient/${id}`));
            })
        ;

    }
}

export const editHospitalPatient = (data, errorCallback) => {
    return (dispatch, getState) => {
        console.log('[action/hospitalPatients] editHospitalPatient', data);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.LOADING_START});

        axios.post(
            `/hospital-patient/${data.id}/edit`,
            data,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/hospitalPatients] editHospitalPatient response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    dispatch(push(`/hp/${data.id}`))
                } else {
                    errorCallback(response.data.customStatus);
                    dispatch({type: actionTypes.LOADING_DONE});
                }
            });
    }
}

export const newHospitalPatient = (data, errorCallback) => {
    return (dispatch, getState) => {
        console.log('[action/hospitalPatient] newHospitalPatient', data);
        const idToken = getState().auth.idToken;
        dispatch({type: actionTypes.LOADING_START});

        axios.post(
            `/hospital-patient/new`,
            data,
            {headers: {'Authorization': idToken}}
        )
            .then((response) => {
                console.log('[action/hospitalPatient] newHospitalPatient response', response.data);
                // dispatch({type: actionTypes.ADMISSION_SET, admission: response.data.admission});
                // dispatch({type: actionTypes.LOADING_DONE});
                if (response.data.done) {
                    dispatch(push(`/hp/${response.data.id}`))
                } else {
                    errorCallback(response.data.customStatus);
                    dispatch({type: actionTypes.LOADING_DONE});
                }
            });
    }
}