import React from 'react';
import {useSelector} from "react-redux";

const RfaForm_HospitalPatient = () => {
    const hospitalPatient = useSelector(state => state.hospitalPatient.hospitalPatient);

    return <>
        <div className="form-group">
            <div className="row">
                <div className="col-2 form-title2">
                    Patient Name
                </div>
                <div className="col-3">
                    {hospitalPatient.name}
                </div>
                <div className="col-3">
                    {hospitalPatient.surname}
                </div>
            </div>
        </div>
        <div className="form-group">
            <div className="row">
                <div className="col-2 form-title2">
                    Patient Mobile
                </div>
                <div className="col-5">
                    {hospitalPatient.mobilePhone}
                </div>
            </div>
        </div>
        <div className="form-group">
            <div className="row">
                <div className="col-2 form-title2">
                    DOB
                </div>
                <div className="col-5">
                    {hospitalPatient.DOB}
                </div>
            </div>
        </div>
        <div className="form-group">
            <div className="row">
                <div className="col-2 d-flex justify-content-end">
                    <a className="btn btn-sm btn-white-primary" href={`/hp/${hospitalPatient.id}/edit`}>
                        <span>Edit patient</span>
                    </a>
                    {/*<input type="button" value="Edit patient" className="btn btn-primary" onClick={editPatient}/>*/}
                </div>
            </div>
        </div>
        <hr />
    </>
}

export default RfaForm_HospitalPatient;