import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {unsetPdf} from "../../../../store/actions/customUI";

const ManagerCustomUI_PreAdmission = ({admission, managerCustomUi, onHide}) => {
    const dispatch = useDispatch();

    const handleUnset = () => {
        dispatch(unsetPdf(admission, managerCustomUi))
        onHide();
    }
    if (admission.manager_customUI[managerCustomUi.id] &&
        (admission.manager_customUI[managerCustomUi.id].statusUpload || admission.manager_customUI[managerCustomUi.id].idLink)) {
        return (
            <>
                <a className="dropdown-item pointer" onClick={handleUnset}>Unlink pre-admission PDF</a>
            </>
        );
    }
    return (
        <a className="dropdown-item disabled pointer">Unlink pre-admission PDF</a>
    );


}

export default ManagerCustomUI_PreAdmission;