import React from "react";
import {useFormState, useForm} from "react-final-form";
import {getProperty} from "dot-prop";

export const dataEnoughForHandling = (formData) =>
    formData && getProperty(formData, 'firstName')
    && getProperty(formData, 'lastName')
    && getProperty(formData, 'dob')
    && getProperty(formData, 'mobile') && (getProperty(formData, 'mobile', '').length === 10)
;

const HospitalPatientForm_Submit = ({title}) => {
    const formState = useFormState();
    const form = useForm();

    // const markDraftSubmit = () => form.change('sendToHospital', false);

    return (
        <div className="form-group mt-6">
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <input
                        type="submit"
                        value={title ? title : "Edit Patient"}
                        className="btn btn-primary"
                        disabled={!dataEnoughForHandling(formState.values)}
                        // onClick={markDraftSubmit}
                    />
                </div>
            </div>
        </div>
    )
}

export default HospitalPatientForm_Submit;