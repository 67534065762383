import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useToasts} from "react-toast-notifications";

import loadingGif from '../../../../assets/images/loader.gif';
import * as actionTypes from "../../../../store/actions/main";

const CloneToTestLink = ({admission}) => {
    const {addToast} = useToasts();
    const dispatch = useDispatch();
    const {cloneToDemoIds} = useSelector(state => state.main);
    const {test_hospital} = useSelector(state => state.auth);

    const doSync = () => dispatch(actionTypes.cloneToTest(admission, addToast));

    if (!test_hospital) {
        return null;
    }

    if(cloneToDemoIds.indexOf(admission.id) > -1) {
        return (
            <a className="dropdown-item disabled pointer"><img src={loadingGif} alt="loading"/>[Test] Clone to test hospital with personal data</a>
        );
    } else {
        return (
            <a className="dropdown-item pointer" onClick={doSync}>[Test] Clone to test hospital with personal data</a>
        );
    }
}

export default CloneToTestLink;