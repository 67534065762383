import React from 'react';
import {useForm, useFormState, Field} from "react-final-form";
import {useSelector} from "react-redux";

import SelectField from "../Field/SelectField";
import AdmissionMessageTemplate_TitleBody from "./AdmissionMessageTemplate_TitleBody";

const AdmissionMessageTemplate = () => {
    const form = useFormState();
    const user = useSelector(state => state.auth);

    if (form.values.selectedHospital || form.values.hospital) {
        const hospitalId = form.values.hospital ? form.values.hospital.id : form.values.selectedHospital.value;
        const templates = user.doctorHospitals.find(hospital => hospital.id === hospitalId).rfa_admissionMessageTemplates;
        // console.log('[RfaForm_AdmissionMessageTemplate] doctorHospital', templates);

        const amt_option = item => ({value: item.id, label: item.title});
        const amt_options = () => templates.map(amt_option);

        if (templates.length) {
            return (
                <>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-2 form-title2 margin0">
                                Patient Portal Message
                            </div>
                            <div className="col-5">
                                <Field component={SelectField}
                                       name="amt"
                                       placeholder="Select Admission Message Template..."
                                       options={amt_options()}
                                />
                            </div>
                        </div>
                    </div>
                    <AdmissionMessageTemplate_TitleBody />
                </>
            )
        }
    }

    return null;
}

export default AdmissionMessageTemplate;