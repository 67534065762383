import React from 'react';
import {useSelector} from "react-redux";
import {map} from 'lodash';

import {useCustomLogic} from "../../utils/customLogic";

const Admission_DevInfo_OechCheckData = () => {
    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);
    const admission = useSelector(state => state.admission.admission);
    const {isCustomLogicLifehouse} = useCustomLogic();

    // return 'asd';
    const renderItem = (data, key) => {

        return (
            <>
                 <dt>{key}</dt>
                 <dd>{data ? data : '-'}</dd>
             </>
        );
    }

    if (state_showDevUI) {
        return (
            <li style={{backgroundColor: '#ffeeee'}}>
                <strong className="title">OEC Check Data</strong>
                <dl className="person-list">
                    {map(admission.dev_oec_check_data, renderItem)}

                </dl>
            </li>
        )
    }
    return null;
}

export default Admission_DevInfo_OechCheckData;