import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import {archiveAdmissionDocuments2} from "../../store/actions/admission";
import {useToasts} from "react-toast-notifications";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import DevUiData from "../Dev/DevUiData";

// TODO: use ModalConfirmAdmissionAction instead
const ModalArchiveAdmissionDocuments2 = () => {
    const [skip, setSkip] = useState([]);
    const {addToast} = useToasts();
    const {show, admission, config} = useSelector(state => state.modal.archiveAdmissionDocuments2);

    useEffect(() => {
        setSkip(config.map(item => item.key));
    }, [show]);

    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_ARCHIVE_ADMISSION_DOCUMENTS2_HIDE});

    const canBeSubmitted = skip.length !== config.length;

    const doArchive = () => {
        dispatch(archiveAdmissionDocuments2(admission, addToast, skip));
    }

    const toggleSelection = (key) => {
        if (skip.includes(key)) {
            setSkip([...skip.filter(item => item !== key)]);
        } else {
            setSkip([...skip, key]);
        }
    }

    const renderOptions = (item) => {
        return <_Option item={item} onChange={toggleSelection} checked={!skip.includes(item.key)}/>;
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
                <div className="modal-content modal-content-fit">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                        <span aria-hidden="true">×</span>
                    </button>
                <div className="modal-body">
                    <h3>Archive admission documents for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                        <dt>Mobile</dt>
                        <_TargetPhone/>
                    </dl>
                    <form action="#" className="person-info-form">
                        <div className="form-group mb-4">
                            <div className="alert alert-danger">This change isn't reversible</div>
                            {/*<DevUiData data={{canBeSubmitted: canBeSubmitted, skip, config}} />*/}
                            {config.map(renderOptions)}
                        </div>
                        <div className="form-group">
                            <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                    <input type="button" value="Archive admission documents" className="btn btn-primary" onClick={doArchive} disabled={!canBeSubmitted}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalArchiveAdmissionDocuments2;

const _Option = ({item, onChange, checked}) => {
    const toggleSelection = () => onChange(item.key);

    return <div className="custom-control custom-checkbox remove-payment-option">
        <input type="checkbox" className="custom-control-input" name={`checkbox_${item.key}`}
               id={`checkbox_${item.key}`}
               checked={checked}
               onChange={toggleSelection}
        />
        <label className="custom-control-label" htmlFor={`checkbox_${item.key}`}>{item.title}</label>
    </div>
}

const _TargetPhone = () => {
    const {admission} = useSelector(state => state.modal.archiveAdmissionDocuments2);
    const {devSmsTarget_enable, devSmsTarget_phone} = useSelector(state => state.auth);

    if (devSmsTarget_enable) {
        return (
            <dd><strike>{admission.mobilePhone}</strike> <span className="badge badge-danger">DEV TARGET ENABLED = {devSmsTarget_phone}</span></dd>
        );
    } else {
        return (
            <dd>{admission.mobilePhone}</dd>
        );
    }
}