export const subfilterVariables = [
    {variable: 'reg', title: 'Reg', customLogic: ['CUSTOM_LOGIC_LIFEHOUSE', 'CUSTOM_LOGIC_DEMO']},
    {variable: 'hist', title: 'Hist', customLogic: ['CUSTOM_LOGIC_LIFEHOUSE', 'CUSTOM_LOGIC_DEMO']},
    {variable: 'book', title: 'Book', customLogic: ['CUSTOM_LOGIC_LIFEHOUSE']},
    {variable: 'pac', title: 'PAC', customLogic: ['CUSTOM_LOGIC_LIFEHOUSE', 'CUSTOM_LOGIC_DEMO']},
    {variable: 'preg', title: 'PReg', customLogic: ['CUSTOM_LOGIC_LIFEHOUSE']},
    {variable: 'adm', title: 'Adm', customLogic: ['CUSTOM_LOGIC_LIFEHOUSE', 'CUSTOM_LOGIC_DEMO']},
    {variable: 'payment', title: 'Paid', customLogic: ['CUSTOM_LOGIC_LIFEHOUSE', 'CUSTOM_LOGIC_DEMO'], noTitle: 'Unpaid'},
    // TODO: refactor - its not a binary filter actually.
    {variable: 'publicPrivate', title: 'Public', customLogic: ['CUSTOM_LOGIC_LIFEHOUSE', 'CUSTOM_LOGIC_DEMO'], noTitle: 'Private'},
];