import React from "react";

const ModalLifeHouseERFA_pastProcedures = ({admission}) => {

    const pacFlag = item => item.pacBooked ? "PAC Appointment" : "No PAC Appointment";

    // TODO: show Doctor name
    const renderItem = item => (
        <>
            {item.admissionDate} - Account number: {item.accountNumber} - Dr. {item.admittingProviderFirstName} {item.admittingProviderMiddleName} {item.admittingProviderLastName} - {pacFlag(item)}<br/>
        </>
    );
    if (!admission.previousAdmissions) {
        return null;
    }

    return (
        <div style={{paddingLeft: "20px"}}>
            <h5 className="modal-title h1">Past Procedures ({admission.previousAdmissions.length})</h5>
            {admission.previousAdmissions.map(renderItem)}
        </div>
    );
}

export default ModalLifeHouseERFA_pastProcedures;