import React from 'react';


// {"Private":["VDV","VOT","VPR","VTA","VWC","DVA","OTH","PRV","TAC","WOR"],"Uninsured":["UNI","VUN","DFT"],"Monash":["PPA"],"SWI":["SWI"]}
const JmphFundingType = ({type}) => {
    switch (type) {
        case "VDV":
        case "VOT":
        case "VPR":
        case "VTA":
        case "VWC":
        case "DVA":
        case "OTH":
        case "PRV":
        case "TAC":
        case "WOR":
            return <div className="lifehouse-color-code yellow" />
        case "UNI":
        case "VUN":
        case "DFT":
            return <div className="lifehouse-color-code pink" />
        case "MON":
        case "PPA":
            return <div className="lifehouse-color-code purple" />
        case "SWI":
            return <div className="lifehouse-color-code red" />
        default:
            return null;
    }
}

export default JmphFundingType;