import React from 'react';
import {useSelector} from "react-redux";

const AdmissionDocumentPdf = ({admissionId, type, width=670, height=800, subType = false}) => {
    const idToken = useSelector(state => state.auth.idToken);
    const action = subType ? `${process.env.REACT_APP_API_PATH}/admission/${admissionId}/pdf/${type}/${subType}` : `${process.env.REACT_APP_API_PATH}/admission/${admissionId}/pdf/${type}/-`;
    return (
        <iframe style={{border: "none"}} width={width} height={height} src={`data:text/html,<form id="pdf" method="POST" action="${action}">
                                    <input type="hidden" name="token" value="${idToken}" />
                                        </form>
                                        Loading...
                                        <script language="JavaScript">
                                            document.forms.pdf.submit();
                                        </script>`} />
    )
}

export default AdmissionDocumentPdf;