import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {useToasts} from "react-toast-notifications";

import loadingGif from '../../../../assets/images/loader.gif';
import * as actionTypes from "../../../../store/actions/main";

const CopyAdmissionData = ({admission}) => {
    const {addToast} = useToasts();

    const copyAdmissionData = () => {
        navigator.clipboard.writeText(JSON.stringify(admission));
        addToast(`Admission Data for ${admission.label} was copied to clipboard`, {
            appearance: 'success',
            autoDismiss: true
        });
    }

    return (
        <a className="dropdown-item pointer" onClick={copyAdmissionData}>[DEV] Copy to clipboard admission data</a>
    );
}

export default CopyAdmissionData;