import React from "react";
import {useSelector, useDispatch} from "react-redux";

import loadingGif from '../../assets/images/loader.gif';
import * as actionTypes from "../../store/actions/main";
import {useToasts} from "react-toast-notifications";
import {webPasTerm} from "../CustomLogic/Terms";

const WebPasSyncBtn = () => {
    const dispatch = useDispatch();
    const {admission} = useSelector(state => state.admission);
    const {webPasSyncIds} = useSelector(state => state.main);
    const selectedHospital = useSelector(state => state.auth.selectedHospital);
    const {addToast} = useToasts();

    const doSync = () => dispatch(actionTypes.webPasSync(admission, addToast));

    if(webPasSyncIds.indexOf(admission.id) > -1) {
        return (
            <button className="btn btn-sm btn-white-primary disabled" data-toggle="modal" disabled={true}
                    data-target="#personModal">
                <img src={loadingGif} alt="Loading"/>
                <span>{webPasTerm(selectedHospital)} Sync</span>
            </button>
        );
    } else {
        return (
            <button className="btn btn-sm btn-white-primary" data-toggle="modal"
                    onClick={doSync}
                    data-target="#personModal">
                <svg width="1em" height="12" viewBox="0 0 16 16" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                </svg>&nbsp;<span>{webPasTerm(selectedHospital)} Sync</span>
            </button>
        );
    }
}

export default WebPasSyncBtn;