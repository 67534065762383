import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import useOnClickOutside from "use-onclickoutside";

import * as actionTypes from "../../store/actions/actionTypes";
import * as modalActions from "../../store/actions/modal";
import ModalSelectDocumentSet_Actions from "./ModalSelectDocumentSet_Actions";
import UrDt from "../CustomLogic/UrDt";
import AdmDt from "../CustomLogic/AdmDt";
import ModalSelectDocumentSet_RemovePaymentOption from "./ModalSelectDocumentSet_RemovePaymentOption";

const ModalSelectDocumentSet = () => {
    const {show, admission, enableLoading, documentSets} = useSelector(state => state.modal.selectDocumentSet);
    const dispatch = useDispatch();
    const ref = React.useRef(null);
    useOnClickOutside(ref, () => hideModal());

    const hideModal = () => dispatch({type: actionTypes.MODAL_SELECT_DOCUMENT_SET_HIDE});

    const renderDocumentSets = (documentSet, key) => {

        return (
            <tr key={key}>
                <td>{documentSet.title}</td>
                <td>{documentSet.readyDocuments.join(', ')}</td>
                <td>{documentSet.awaitingDataDocuments.join(', ')}</td>
                <td><ModalSelectDocumentSet_Actions admission={admission} documentSet={documentSet} /></td>
            </tr>
        );
    }

    const renderModal = () => {
        return (
            <div className="modal-dialog" role="document" ref={ref}>
            <div className="modal-content">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
                    <span aria-hidden="true">×</span>
                </button>
                <div className="modal-body">
                    <h3>Select document set for {admission.title} {admission.surname}, {admission.name}</h3>
                    <dl className="person-list mb-3">
                        <UrDt admission={admission}/>
                        <AdmDt admission={admission}/>
                    </dl>
                    <ModalSelectDocumentSet_RemovePaymentOption />
                    <table className="table" cellSpacing="0" width="100%">
                        <thead>
                        <tr>
                            <th><strong className="title">Document set</strong></th>
                            <th><strong className="title">Ready</strong></th>
                            <th><strong className="title">Awaiting</strong></th>
                            <th><strong className="title">Actions</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {documentSets.map(renderDocumentSets)}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        );
    }

    return (
        <>
            <div className={`modal fade ${show ? 'show' : ''}`} id="personModal" tabIndex="-1" role="dialog"
                 aria-labelledby="personModalLabel" aria-modal="true">
                {show && renderModal()}
            </div>
        </>
    );
}

export default ModalSelectDocumentSet;