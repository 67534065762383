import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";

const Error404 = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: actionTypes.LOADING_DONE});
    }, [])

    return (
        <div className="row">
            <div className="col-4">
                <div className="alert alert-danger m-4">Error 404 - page not founded</div>
            </div>
        </div>
    )
}

export default Error404;